import { LocalDate } from "@js-joda/core";
import { CircularProgress, Modal } from "@material-ui/core";
import { Text } from 'components/Typography/text.styled';
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Salary } from "model/Salary";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgressWrapper, Row } from "../monthlyOverview.styled";
import { SalaryModalBody } from "./salaryModal.styled";
import SalaryTable from "./salaryTable.component";
import { getMonthRangeString, getFirstAndLastDatesOfMonth, getHospitalityBrokenMonthDates } from "./salaryUtils";

type Props = {
  setShowPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPaymentModal: boolean;
  workerId: number;
  currentDate: LocalDate;
};

export const SalaryModal: React.FC<Props> = ({
  setShowPaymentModal: setShowPaymentModal,
  showPaymentModal,
  workerId,
  currentDate,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [useHospitalityBrokenMonthSetting, setUseHospitalityBrokenMonthSetting] = useState(false);

  const [companyState] = useCompanyStore();
  const companyId = companyState.company?.id;

  const [salary, setSalary] = useState<Salary | null>(null)

  const { firstDate, lastDate } = useHospitalityBrokenMonthSetting ? getHospitalityBrokenMonthDates(currentDate) : getFirstAndLastDatesOfMonth(currentDate)
  const { firstDate: firstBrokenMonthDate, lastDate: lastBrokenMonthDate } = getHospitalityBrokenMonthDates(currentDate)
  const from = useHospitalityBrokenMonthSetting ? firstBrokenMonthDate : firstDate
  const to = useHospitalityBrokenMonthSetting ? lastBrokenMonthDate : lastDate

  const [hospitalityText, setHospitalityText] = useState(getMonthRangeString(useHospitalityBrokenMonthSetting, firstDate, lastDate, firstBrokenMonthDate, lastBrokenMonthDate))

  const getSalaryData = () => {
    setIsLoading(true);

    if (companyId) {
      Api()
        .company.timeReport.getSalaryData(companyId, workerId, from, to)
        .then((res) => {
          setIsLoading(false);
          setSalary(res.data)
        })
        .catch((err) => {
          console.log("Error fetching salary data", err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getSalaryData();
  }, [companyState.company?.id, useHospitalityBrokenMonthSetting]);

  return (
    <>
      <Modal
        open={showPaymentModal}
        onClose={() => {
          setShowPaymentModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SalaryModalBody>
          <Row onClick={() => {
            setHospitalityText(getMonthRangeString(!useHospitalityBrokenMonthSetting, firstDate, lastDate, firstBrokenMonthDate, lastBrokenMonthDate))
            setUseHospitalityBrokenMonthSetting(!useHospitalityBrokenMonthSetting)
          }
          }>
            {isLoading && (
              <CircularProgressWrapper>
                <CircularProgress size="12px" />
              </CircularProgressWrapper>
            )}
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.MidnightBlue}
              fontSize={FontSize.Small}
            >
              {`${t("Salary.Use")} ${hospitalityText}`}
            </Text>
          </Row>

          {salary &&
            <SalaryTable from={from} to={to} data={salary} />
          }

        </SalaryModalBody>
      </Modal>
    </>
  );
};
