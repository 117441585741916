import React from "react";
import { FontFamily } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { LinkWrapper, ModalBody } from "./workgroup.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowDeleteInvitationModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteInvitationModal: boolean;
  inviteId: number;
  workGroupId: number;
};

export const WorkGroupDeleteInvitationModal: React.FC<Props> = ({
  setShowDeleteInvitationModal,
  showDeleteInvitationModal,
  inviteId,
  workGroupId,
}) => {
  const { t } = useTranslation();
  const [companyState, ,] = useCompanyStore();
  const [, workgroupActions] = useWorkgroupStore();
  const [, , alertDispatch] = useAlertStore();

  const handleDeleteInvitation = () => {
    if (companyState.company && inviteId && workGroupId) {
      workgroupActions
        .deleteInvitation(companyState.company.id, inviteId, workGroupId)
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("WorkGroup.Withdrawn"),
              message: t("WorkGroup.InvitationHasBeenWithdrawn"),
            },
          });
          setShowDeleteInvitationModal(false);
        })
        .catch(() => {
          console.log("error when withdrawing invitation");
        });
    }
  };

  return (
    <>
      <Modal
        open={showDeleteInvitationModal}
        onClose={() => {
          setShowDeleteInvitationModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowDeleteInvitationModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ marginBottom: 10 }}
          >
            {t("WorkGroup.DoYouWantToWitdrawInvite")}
          </Text>
          <ButtonStyled
            style={{ marginTop: 20 }}
            isCancel
            backgroundColor={Color.LighterDestructive}
            onClick={() => {
              handleDeleteInvitation();
            }}
          >
            {t("WorkGroup.Withdraw")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
