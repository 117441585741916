import {
  Entity,
  WorkGroup,
  WorkGroupActions,
  WorkGroupWorker,
} from "model/Workgroup";

export type WorkgroupActions =
  | {
      type: WorkGroupActions.GetAllMembersInWorkgroup;
      payload: { workGroupId: number; members: WorkGroupWorker[] };
    }
  | {
      type: WorkGroupActions.GetAllWorkgroups;
      payload: WorkGroup[];
    }
  | {
      type: WorkGroupActions.CreateWorkgroup;
      payload: WorkGroup;
    }
  | {
      type: WorkGroupActions.GetAllInvitedInWorkgroup;
      payload: { workGroupId: number; invited: WorkGroupWorker[] };
    }
  | {
      type: WorkGroupActions.GetEntities;
      payload: Entity[];
    }
  | {
      type: WorkGroupActions.GetAllDeclinedInWorkgroup;
      payload: { workGroupId: number; declined: WorkGroupWorker[] };
    }
  | {
      type: WorkGroupActions.AddWorkerToWorkgroup;
      payload: { workGroupId: number; worker: WorkGroupWorker };
    }
  | {
      type: WorkGroupActions.DelteWorkerFromWorkgroup;
      payload: { workGroupId: number; workerId: number };
    }
  | {
      type: WorkGroupActions.DeleteWorkgroup;
      payload: number;
    }
  | {
      type: WorkGroupActions.UpdateWorkgroup;
      payload: WorkGroup;
    }
  | {
      type: WorkGroupActions.DeleteInvitation;
      payload: { workGroupId: number; inviteId: number };
    }
  | {
      type: WorkGroupActions.UpdateWorkerInWorkgroup;
      payload: { workGroupId: number; updatedWorker: WorkGroupWorker };
    }
  | {
      type: WorkGroupActions.InviteWorkerToWorkgroup;
      payload: { workGroupId: number; invited: WorkGroupWorker[] };
    }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: WorkgroupState["error"] };

export type WorkgroupState = {
  members: { [workgroupId: number]: WorkGroupWorker[] };
  invited: { [workgroupId: number]: WorkGroupWorker[] };
  declined: { [workgroupId: number]: WorkGroupWorker[] };
  workgroups: WorkGroup[];
  entities: Entity[];
  isLoading: boolean;
  error: { message: string; err: Error } | null;
};

export const workgroupInitState: WorkgroupState = {
  members: {},
  workgroups: [],
  invited: {},
  declined: {},
  entities: [],
  isLoading: false,
  error: null,
};

export const workgroupStateReducer = (
  state: WorkgroupState,
  action: WorkgroupActions
): WorkgroupState => {
  switch (action.type) {
    case WorkGroupActions.GetAllWorkgroups: {
      return {
        ...state,
        workgroups: action.payload,
      };
    }
    case WorkGroupActions.GetAllMembersInWorkgroup: {
      const { workGroupId, members } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          [workGroupId]: members,
        },
      };
    }
    case WorkGroupActions.GetAllInvitedInWorkgroup: {
      const { workGroupId, invited } = action.payload;
      return {
        ...state,
        invited: {
          ...state.invited,
          [workGroupId]: invited,
        },
      };
    }
    case WorkGroupActions.GetAllDeclinedInWorkgroup: {
      const { workGroupId, declined } = action.payload;
      return {
        ...state,
        declined: {
          ...state.declined,
          [workGroupId]: declined,
        },
      };
    }

    case WorkGroupActions.GetEntities: {
      return {
        ...state,
        entities: action.payload,
      };
    }

    case WorkGroupActions.AddWorkerToWorkgroup: {
      const { workGroupId, worker } = action.payload;
      const currentMembers = state.members[workGroupId] || [];

      const updatedMembers = [
        worker,
        ...currentMembers.filter(
          (member: WorkGroupWorker) => member.id !== worker.id
        ),
      ];

      return {
        ...state,
        members: {
          ...state.members,
          [workGroupId]: updatedMembers,
        },
      };
    }

    case WorkGroupActions.DelteWorkerFromWorkgroup: {
      const { workGroupId, workerId } = action.payload;

      const updatedMembers = (state.members[workGroupId] || []).filter(
        (member: WorkGroupWorker) => member.id !== workerId
      );

      return {
        ...state,
        members: {
          ...state.members,
          [workGroupId]: updatedMembers,
        },
      };
    }

    case WorkGroupActions.DeleteWorkgroup: {
      const workgroups = state.workgroups.filter(
        (workgroup: WorkGroup) => action.payload !== workgroup.id
      );
      return {
        ...state,
        workgroups: workgroups,
      };
    }

    case WorkGroupActions.DeleteInvitation: {
      const { workGroupId, inviteId } = action.payload;
      const updatedInvited = (state.invited[workGroupId] || []).filter(
        (invited: WorkGroupWorker) => invited.inviteId !== inviteId
      );

      return {
        ...state,
        invited: {
          ...state.members,
          [workGroupId]: updatedInvited,
        },
      };
    }

    case WorkGroupActions.UpdateWorkgroup: {
      const updatedWorkGroup = action.payload;
      const updatedWorkGroups = state.workgroups.some(
        (workGroup) => workGroup.id === updatedWorkGroup.id
      )
        ? state.workgroups.map((workGroup) =>
            workGroup.id === updatedWorkGroup.id ? updatedWorkGroup : workGroup
          )
        : [...state.workgroups, updatedWorkGroup];

      return {
        ...state,
        workgroups: updatedWorkGroups,
      };
    }

    case WorkGroupActions.UpdateWorkerInWorkgroup: {
      const { workGroupId, updatedWorker } = action.payload;

      const currentMembers = state.members[workGroupId] || [];

      const updatedMembers = currentMembers.map((worker: WorkGroupWorker) =>
        worker.id === updatedWorker.id ? updatedWorker : worker
      );

      return {
        ...state,
        members: {
          ...state.members,
          [workGroupId]: updatedMembers,
        },
      };
    }

    case WorkGroupActions.InviteWorkerToWorkgroup: {
      const { workGroupId, invited } = action.payload;
      return {
        ...state,
        invited: {
          ...state.invited,
          [workGroupId]: invited,
        },
      };
    }

    case WorkGroupActions.CreateWorkgroup: {
      const newWorkGroup = action.payload;
      const updatedWorkGroups = state.workgroups.filter(
        (workgroup: WorkGroup) => workgroup.id !== newWorkGroup.id
      );

      return {
        ...state,
        workgroups: [newWorkGroup, ...updatedWorkGroups],
      };
    }

    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUEST_ERROR": {
      return { ...state, error: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
