import styled, { css } from "styled-components";
import { Color } from "config/colors";
import { FontSize, FontFamily } from "config/font";

type ButtonProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  backgroundColor?: Color;
  color?: Color;
  icon?: boolean;
  fontSize?: FontSize;
  lessPadding?: boolean;
  isCancel? : boolean
  notCenter? : boolean
  isAction?: boolean
};

export const ButtonStyled = styled.button<ButtonProps>`
  display: ${(props) => (props.icon ? "flex" : "block")};
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: none;
  margin: ${(props) => props.notCenter ? undefined : '0 auto'}
  ${(props) => props.fullWidth && "width: 100%"};
  padding: ${(props) => (props.lessPadding ? "5px 10px" : "10px 20px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s;
  color: ${(props) =>
    props.disabled
      ? Color.SeaBlue500
      : props.color
      ? props.color
      : Color.White};
  background: ${(props) =>
    props.disabled
      ? Color.Disabled
      : props.backgroundColor
      ? props.backgroundColor
      : Color.BurntSienna};
  outline: none;
  transition: opacity 0.15s;
  cursor: pointer;
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props.isCancel ? Color.LightWarning : props.isAction ? Color.ActionColor : Color.Positive};
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        transform: translateY(-2px);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
      }
    `}
`;

export const Badge = styled.div`
  background-color: ${Color.Destructive};
  color: ${Color.White};
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: ${FontSize.Small};
  position: absolute;
  top: -3px;
  right: -3px;
  padding: 6px;
`;

export const InfoButton = styled.button`
  background-color: ${Color.BurntSienna};
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: ${Color.White};
  font-family: ${FontFamily.MontserratSemiBold};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
