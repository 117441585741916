import { Color } from "config/colors";
import styled from "styled-components";

export const ChooseWorkerTableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  overflow-x: auto;

  td {
    padding: 4px;
    border: none;
    border-bottom: 1px solid ${Color.SeaBlue200};
    }
`;

export const DividerLine = styled.div`
  height: 40px;
  margin-right: 10px;
  border-left: 1px solid ${Color.SeaBlue300};
`;
