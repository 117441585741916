import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getWorkHours } from "utils/utils";
import { CompanyGigDto } from "model/Gig";
import { getDateString } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { TitleStatus, GigInfoContainer, DateHours } from "./gigs.styled";
import { GigDetailsModal } from "../calendar/gigDetailsModal.component";

type Props = {
  gigData: CompanyGigDto;
};

export const GigInfo: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const startTime = gigData.shifts?.[0]?.startTime ?? "";
  const endTime = gigData.shifts?.[gigData.shifts.length - 1]?.endTime ?? "";
  const [gigModalOpen, setGigModalOpen] = useState(false);

  return (
    <>
      <GigInfoContainer>
        <TitleStatus>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
            onClick={() => {
              setGigModalOpen(true);
            }}
            cursor
          >
            {t(gigData.role || "", { ns: "roles" })}
          </Text>
        </TitleStatus>
        <DateHours>
          <Text
            fontSize={FontSize.Standard}
            color={Color.SeaBlue600}
            style={{
              display: "inline-block",
              margin: 0,
              whiteSpace: "nowrap",
            }}
          >
            {startTime && getDateString(startTime)}
            {getDateString(startTime) !== getDateString(endTime) &&
              ` - ${getDateString(endTime)}`}
          </Text>
          {Date.now() > Date.parse(startTime) &&
            Date.now() < Date.parse(endTime) && (
              <Text
                fontSize={FontSize.Small}
                color={Color.BurntSienna}
                backgroundColor={Color.Positive}
                padding
                style={{ margin: 0, lineHeight: FontSize.Small }}
              >
                {t("GigsCompany.Ongoing")}
              </Text>
            )}
        </DateHours>
        <Text fontSize={FontSize.Standard} color={Color.SeaBlue600}>
          {t("GigsCompany.TotalTime") +
            ": " +
            getWorkHours(t, ...gigData.shifts)}
        </Text>
      </GigInfoContainer>

      {gigModalOpen && (
        <GigDetailsModal
          gigId={gigData.id}
          setModalOpen={setGigModalOpen}
          modalOpen
        />
      )}
    </>
  );
};
