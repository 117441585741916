import { Color } from "config/colors";
import styled from "styled-components";
import { Text } from "components/Typography/text.styled";
import { Badge } from "components/buttons/buttons.styled";
import { FontFamily } from "config/font";

type ShiftProps = {
  column?: number;
  backgroundColor: Color;
  row?: number;
  disabled?: boolean;
  leftBorderColor?: Color;
};
type ShiftColumnProps = {
  column?: number;
  row?: number;
  onHover?: boolean;
};

type WeekdayProps = {
  today: boolean;
  future: boolean;
};

type CalendarViewTextProps = {
  active: boolean;
};

export const CalendarHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1.3fr repeat(7, 1fr);
  justify-content: center;
  width: 100%;
  background-color: white;
  margin-bottom: 12px;
  justify-items: center;
  grid-gap: 2px;
`;

export const previewCalendar = styled.div`

`;

export const WeekDay = styled.div<WeekdayProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 50px;
  p {
    margin: 4px;
  }

  /*  &:hover {
    cursor: ${(props) => (props.future ? "pointer" : "default")};
    opacity: ${(props) => (props.future ? 0.9 : 1)};
    p {
      display: ${(props) => (props.future ? "none" : "default")};
    }
  } */

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const GigHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;
  p {
    margin: 4px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${Color.White};
  width: 100%;
  align-items: center;
  margin-top: 60px;
  height: 50px;
  //margin-bottom: 16px;
  p {
    margin: 4px;
  }
   
`;

export const CalenderWeekAndDatesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  width: 200px;
  text-align: left;
`;

export const CalendarGridContainer = styled.div`
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  border-top: none;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf #f9f9f9;
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }

  transition: all 0.3s ease-in-out;


  & > * {
    width: 100%;
    text-align: center;
    overflow-x: hidden;
  }
`;
export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: 1.3fr repeat(7, 1fr);
  width: 100%;
  background-color: ${Color.SeaBlue300};
  grid-gap: 1px;
`;

type Props = {
  childGig?: boolean;
}

export const GigCell = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background-color: ${Color.LightGrey};
  width: 100%;
  grid-column: 1;
  //min-height: 25px;
  padding: 8px 6px;
  text-align: left;

  border-bottom: ${(props) => props.childGig ? 'none' : '2px solid white'};
  p {
    word-break: break-word;
  }
`;

export const ShiftCell = styled.div<ShiftProps>`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  align-items: left;
  grid-column: ${(props) => (props.column ? props.column + 1 : "auto")};
  grid-row: ${(props) => (props.row ? props.row + 1 : "auto")};
  z-index: 1;
  padding: 8px 6px;
  margin-bottom: 6px;
  //min-height: 25px;
  p {
    margin: 0px;
    color: ${(props) =>
      props.backgroundColor === Color.Positive
        ? Color.BurntSienna
        : Color.Charcoal};
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    opacity: ${(props) => (props.disabled ? "1" : "0.8")};
  }
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06);
`;

export const ButtonIconWrapper = styled.div`
  position: relative;
  margin-top: 8px;
`;
export const GigButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ShiftColumn = styled.div<ShiftColumnProps>`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.column ? props.column + 1 : "auto")};
  background-color: ${Color.White};
  z-index: 0;
  padding: 2px;
  height: auto;
  //min-height: 25px;
  grid-row: ${(props) =>
    props.row || props.row === 0 ? props.row + 1 : "auto"};

  #plusIcon {
    display: none;
  }

  ${(props) =>
    props.onHover &&
    `&:hover {
      cursor: pointer;
      justify-content: center;
      #plusIcon {
        display: block
      }
    }`};
`;
export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  background-color: ${Color.White};
  padding: 20px;
  outline: none;
  border-radius: 8px;
  width: 600px;
  overflow-y: auto;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);

  h3 {
    margin: 0;
  }
`;

export const OfferedGigModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  background-color: ${Color.White};
  padding: 20px;
  outline: none;
  border-radius: 8px;
  width: 400px;
  overflow-y: auto;
  text-align: center;
  align-content: center;

  h3 {
    margin: 0;
  }
`;

export const NotFilledGigModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  background-color: ${Color.White};
  padding: 20px;
  outline: none;
  border-radius: 8px;
  width: 400px;
  overflow-y: auto;
  text-align: center;
  align-content: center;

  h3 {
    margin: 0;
  }
`;

export const GigText = styled(Text)`
  margin: 0px 0 4px 0;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const ApplicationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const TextBadge = styled(Badge)`
  position: initial;
  top: initial;
  right: initial;
  margin-left: 4px;
  margin-bottom: 4px;
  font-family: ${FontFamily.MontserratSemiBold};
`;

export const CalendarViewText = styled(Text)<CalendarViewTextProps>`
  background-color: ${(props) =>
    props.active ? Color.MidnightBlue : Color.White};
  color: ${(props) => (props.active ? Color.White : Color.MidnightBlue)};
  display: block;
  margin: 0px 4px;
  height: 28px;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid ${Color.MidnightBlue};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
export const OptionsWrapper = styled.div`
  width: 100px;
  right: 20px;
  background-color: ${Color.White};
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  border-radius: 8px;
  position: absolute;
  left: 0;
`;

export const Option = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid ${Color.SeaBlue200};
  padding: 8px;

  p {
    margin: 0;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: bottom;
  margin-bottom: 60px;
  max-height: 48px;
`;

export const BigModalBody = styled(ModalBody)`
  width: 80vw;
  max-width: 900px;
  height: 80vh;
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  max-width: 230px;
`;

export const DayShiftRow = styled.div<ShiftProps>`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  padding: 8px;
  margin-bottom: 6px;
  background-color: ${(props) => props.backgroundColor};
  border-left: 3px solid
    ${(props) =>
      props.leftBorderColor ? props.leftBorderColor : Color.MidnightBlue};
  p {
    padding: 0;
    margin: 0;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const IconContainer = styled.div`
background-color: ${Color.White};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }`
