import React from "react";
import { useTranslation } from "react-i18next";
import { H4 } from "components/Typography/text.styled";
import { CheckboxText, CheckBoxContainer } from "./companyStampClock.styled";
import { Checkbox } from "@material-ui/core";
import { StampClockTemplateFormData } from "model/Company";

type Props = {
  autoGenerateShiftSettingActive: boolean;
  setFormData: React.Dispatch<
    React.SetStateAction<StampClockTemplateFormData>
  >;
};

export const AutoGenerateShiftSetting: React.FC<Props> = ({
  autoGenerateShiftSettingActive,
  setFormData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <H4>{t("ProfileCompany.AutogeneratedShift")}</H4>
      <CheckBoxContainer>
        <Checkbox
          checked={autoGenerateShiftSettingActive}
          name="autoGenerateShiftSettingActive"
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              autoGenerateShiftSettingActive: e.target.checked,
            }));
          }}
        />
        <CheckboxText>
          {t("ProfileCompany.AllowAutogeneratedShifts")}
        </CheckboxText>
      </CheckBoxContainer>
    </>
  );
};
