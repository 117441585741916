import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { H4 } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import {
  CheckboxText,
  CheckBoxContainer,
  LocalStampClockContainer,
} from "./companyStampClock.styled";
import { Checkbox } from "@material-ui/core";
import { InfoCircle } from "../../timeReport/timeReportModal.styled";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { StampClockTemplateFormData } from "model/Company";

type Props = {
  localStampClockSettingActive: boolean;
  setFormData: React.Dispatch<React.SetStateAction<StampClockTemplateFormData>>;
};

export const LocalStampClockSetting: React.FC<Props> = ({
  localStampClockSettingActive,
  setFormData,
}) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <LocalStampClockContainer>
        <H4>{t("ProfileCompany.LocalStampClock")}</H4>
        <InfoCircle
          onMouseEnter={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
        >
          <Text
            fontFamily={FontFamily.LeagueSpartanBold}
            color={Color.White}
            padding
            fontSize={FontSize.Small}
          >
            i
          </Text>
        </InfoCircle>
        {showInfo && (
          <Text
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("ProfileCompany.LocalStampClockHelpText")}
          </Text>
        )}
      </LocalStampClockContainer>
      <CheckBoxContainer>
        <Checkbox
          checked={localStampClockSettingActive}
          name="localStampClockSettingActive"
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              localStampClockSettingActive: e.target.checked,
            }));
          }}
        />
        <CheckboxText>
          {t("ProfileCompany.OnlyAllowLocalCheckInAndOut")}
        </CheckboxText>
      </CheckBoxContainer>
    </>
  );
};
