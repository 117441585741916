import { Color } from "config/colors";
import styled from "styled-components";

export const StructureModalBody = styled.div`
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 940px;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  background-color: ${Color.White};
  outline: none;
  padding: 20px;
  padding-bottom: 40px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;
