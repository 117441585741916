import { company } from "./company/company.service";
import { employee } from "./employee/employee.service";
export const Api = () => ({
  company,
  employee,
});

interface ApiCallParams {
  apiCall: () => Promise<any>;
  onResult?: (res: any) => void;
  onError?: (err: any) => void;
  onCompleted?: () => void;
}

export const handleApiCall = async ({
  apiCall,
  onResult = (res) => {},
  onError = (err) => {},
  onCompleted = () => {},
}: ApiCallParams) => {
  try {
    const res = await apiCall();
    onResult(res);
  } catch (err) {
    onError(err);
  } finally {
    onCompleted();
  }
};
