import { Text } from "components/Typography/text.styled";
import { FontFamily } from 'config/font';
import React from 'react';
import Checkbox from "../checkbox.component";
import { ChooseWorkerTableStyled, DividerLine } from "./companyStructureEditUnitChooseExisting.styled";

export interface ChooseWorkerConfig {
  id: number;
  firstName: string;
  lastName: string;
  personalIdentityNumber: string;
  email: string;
  phoneNumber: string;
  entityUnit: [number, number][];
}

const ChooseWorkerTable: React.FC<{ workers: ChooseWorkerConfig[]; onSelect: (workerId: number) => void; selectedWorkers: number[]; getEntityName: (entityId: number) => string; getUnitName: (unitId: number) => string; }> = ({ workers, onSelect, selectedWorkers, getEntityName, getUnitName }) => {
  return (
    <ChooseWorkerTableStyled>
      <tbody>
        {workers.map((worker) => (
          <tr key={worker.id}>
            <td>
              <Checkbox checked={selectedWorkers.includes(worker.id)} onChange={() => onSelect(worker.id)} />
            </td>
            <td>
              <Text fontFamily={FontFamily.MontserratSemiBold}>{`${worker.firstName} ${worker.lastName}`}</Text>
            </td>

            <td>
              <DividerLine />
            </td>
            
            <td>
              <Text fontFamily={FontFamily.MontserratRegular}>
                {worker.entityUnit.map(([entityId, unitId]) => (`${getUnitName(unitId)} (${getEntityName(entityId)})`)).join(', ')}
              </Text>
            </td>
          </tr>
        ))}
      </tbody>
    </ChooseWorkerTableStyled>
  );
};

export default ChooseWorkerTable;
