import React, { createContext, useReducer, useContext, useEffect } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "../companyStore/companyStore";
import {
  WorkgroupActions,
  workgroupInitState,
  WorkgroupState,
  workgroupStateReducer,
} from "./workgroupStore.reducer";
import { workgroupActions } from "./workgroupStore.actions";
import { WorkGroupActions } from "model/Workgroup";

export type WorkgroupDispatch = (action: WorkgroupActions) => void;

const WorkgroupStateContext = createContext<WorkgroupState | undefined>(
  undefined
);
const WorkgroupDispatchContext = createContext<WorkgroupDispatch | undefined>(
  undefined
);

export const WorkgroupStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    workgroupStateReducer,
    workgroupInitState
  );
  const [companyState] = useCompanyStore();

  useEffect(() => {
    if (companyState.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.workgroup.getWorkGroups(companyState.company.id)
        .then((res) => {
          dispatch({
            type: WorkGroupActions.GetAllWorkgroups,
            payload: res.data,
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company]);

  useEffect(() => {
    if (companyState.company) {
      Api()
        .company.workgroup.getEntities(companyState.company.id)
        .then((res) => {
          dispatch({
            type: WorkGroupActions.GetEntities,
            payload: res.data.entities,
          });
        })
        .catch(() => {
          console.log("Error fetching entities");
        });
    }
  }, [companyState.company]);

  return (
    <WorkgroupStateContext.Provider value={state}>
      <WorkgroupDispatchContext.Provider value={dispatch}>
        {children}
      </WorkgroupDispatchContext.Provider>
    </WorkgroupStateContext.Provider>
  );
};

export const useWorkgroupStore = () => {
  const state = useContext(WorkgroupStateContext);
  const dispatch = useContext(WorkgroupDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useWorkgroupStore must be used within a WorkgroupStoreProvider"
    );
  }

  const actions = workgroupActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
