import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  ApplicationRow,
  GigCell,
  GigText,
  ShiftColumn,
  TextBadge,
} from "./weeklyCalendar.styled";
import moment from "moment";
import { CompanyGigUserDto } from "model/Gig";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { TimeReportResolution } from "model/TimeReport";
import { useHistory } from "react-router-dom";
import { useAlertStore } from "stores/alertStore/alertStore";
import { GigDetailsModal } from "./gigDetailsModal.component";
import { GigApplicationsModal } from "./gigApplicationsModal.component";
import {
  CompanyCalendarApplication,
  CompanyCalendarGigDto,
  CompanyCalendarShift,
  CompanyCalendarTimereport,
} from "model/Calendar";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { FilledCalendarGig } from "./calendarGig/filledCalendarGig.component";
import { OfferedCalendarGig } from "./calendarGig/offeredCalendarGig.component";
import { NotFilledCalendarGig } from "./calendarGig/notFilledCalendarGig.component";
import { WithdrawnCalendarGig } from "./calendarGig/widthdrawnCalendarGig.component";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";

type CalendarGigProps = {
  gigData: CompanyCalendarGigDto;
  gigDataIndex: number;
  compactView: boolean;
  dashboard?: boolean;
};

type GigsWorkersList = {
  worker: CompanyGigUserDto | null;
  applications: ApplicationDto[] | null;
  shiftWithTimeReport: ShiftWithTimeReport[];
};

type ShiftWithTimeReport = {
  shift: CompanyCalendarShift;
  timeReport: CompanyCalendarTimereport | undefined;
};

export const CalendarGig: React.FC<CalendarGigProps> = ({
  gigData,
  gigDataIndex,
  compactView,
  dashboard,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [matchingUnhandledApplications, setMatchingUnhandledApplications] =
    useState<CompanyCalendarApplication[]>([]);

  const [gigsWorkersList, setGigsWorkersList] = useState<GigsWorkersList[]>([]);
  const [emptyDays, setEmptyDays] = useState<number[]>([]);
  const [gigShiftsByDay, setGigShiftsByDay] = useState<any>([]);
  const history = useHistory();
  const [gigModalOpen, setGigModalOpen] = useState(false);
  const [applicationsModalOpen, setApplicationsModalOpen] = useState(false);
  const [hired, setHired] = useState(0);
  const [companyState, companyActions] = useCompanyStore();
  const [error, setError] = useState("");
  const [, calendarActions] = useCalendarStore();

  useEffect(() => {
    getMatchingUnhandledApplications();

    const result = gigData.shifts.reduce(
      (acc: any, shift: CompanyCalendarShift) => {
        const key = moment(shift.startTime).format("YYYY-MM-DD");
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(shift);
        return acc;
      },
      {}
    );

    const sortedDates = Object.values(result);
    setGigShiftsByDay(sortedDates);
  }, [gigData]);

  useEffect(() => {
    getWorkersForGig();
    getAmountHiredWorkers();
  }, [gigData.applications]);

  const getMatchingUnhandledApplications = () => {
    let filteredApplications = gigData.applications.filter(
      (application) => application.status === ApplicationStatus.PENDING
    );

    setMatchingUnhandledApplications(filteredApplications);
  };

  const getAmountHiredWorkers = () => {
    setHired(
      gigData.applications.filter(
        (application) =>
          application.status === ApplicationStatus.CLOSED &&
          application.resolution === ApplicationResolution.ACCEPTED
      ).length
    );
  };

  const getWorkersForGig = () => {
    let filledWorkersList: any = [];
    let workersList: any = [];
    gigData.applications.forEach((application) => {
      const matching = filledWorkersList.findIndex(
        (worker: GigsWorkersList) => worker.worker?.id === application.worker.id
      );
      if (matching === -1) {
        let shiftWithTimeReport: ShiftWithTimeReport[] = [];
        gigData.shifts.forEach(
          (shift) =>
            (shiftWithTimeReport = [
              ...shiftWithTimeReport,
              {
                shift: shift,
                timeReport: shift.timeReports.find(
                  (timeReport) => timeReport.workerId === application.worker.id
                ),
              },
            ])
        );
        if (
          application.status === ApplicationStatus.OFFERED ||
          application.resolution === ApplicationResolution.ACCEPTED
        ) {
          filledWorkersList.push({
            worker: application.worker,
            applications:
              application.status === ApplicationStatus.OFFERED
                ? [application]
                : [],
            shiftWithTimeReport: shiftWithTimeReport,
          });
        }
      } else {
        application.status === ApplicationStatus.OFFERED &&
          filledWorkersList[matching].applications.push(application);
      }
    });
    Array.from(
      Array(
        gigData.amountOfWorkers >= filledWorkersList.length
          ? gigData.amountOfWorkers - filledWorkersList.length
          : 0
      )
    ).forEach(() => {
      workersList.push({
        worker: null,
        application: null,
        shiftWithTimeReport: [],
      });
    });
    workersList = [...filledWorkersList, ...workersList];

    setGigsWorkersList(workersList);
  };

  const getUnusedWeekDays = () => {
    let weekdays = [1, 2, 3, 4, 5, 6, 7];
    gigData.shifts.forEach((shift: CompanyCalendarShift) => {
      let shiftDay = moment(shift.startTime).day();
      if (shiftDay === 0) {
        shiftDay = 7;
      }

      weekdays = weekdays.filter((day) => day !== shiftDay);
    });

    setEmptyDays(weekdays);
  };

  useEffect(() => {
    getUnusedWeekDays();
  }, [gigData]);

  const deleteShift = (shiftId: number, gigId: string) => {
    if (companyState.company?.id) {
      companyActions
        .handleDeleteCalenderShift(companyState.company?.id, shiftId, gigId)
        .then(() => {
          calendarActions.updateWeeklyCalendarContext(
            t,
            companyState,
            "UPDATE_WEEKLY_CALENDAR"
          );

          if (dashboard) {
            calendarActions.updateWeeklyCalendarContext(
              t,
              companyState,
              "UPDATE_OVERVIEW_WEEK_CALENDAR"
            );
          }
        })
        .catch((err: any) => {
          if (
            err.errorMessage ==
            "Unable to delete GigShift due to linked time reports"
          ) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t(
                  "CompanyTimeReport.UnableToDeleteGigShiftDueToLinkedTimeReports"
                ),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("CompanyTimeReport.UnknownError"),
              },
            });
          }
        });
    }
  };

  return (
    <>
      <GigCell childGig={gigData.parentGigId !== null}>
        {!gigData.parentGigId && (
          <GigText
            data-tooltip-id={
              gigData.role.length > (dashboard ? 7 : 18) ? "gig-role" : ""
            }
            data-tooltip-content={gigData.role}
            fontSize={dashboard ? FontSize.Small : FontSize.Standard}
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
            onClick={() => {
              setGigModalOpen(true);
            }}
          >
            {gigData.role.length > (dashboard ? 7 : 18)
              ? gigData.role.slice(0, dashboard ? 7 : 19) + "..."
              : gigData.role}
          </GigText>
        )}
        {gigData.role.length > 18 && (
          <Tooltip
            place="top"
            id="gig-role"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 15px 0 rgba(185, 185, 185, 0.5)",
            }}
          />
        )}
        {!compactView && (
          <>
            <ApplicationRow
              onClick={() => {
                setApplicationsModalOpen(true);
                matchingUnhandledApplications.length > 0 &&
                  history.push("?status=Pending");
              }}
            >
              {!gigData.parentGigId && (
                <GigText
                  fontSize={FontSize.Small}
                  color={Color.MidnightBlue}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {`${hired} / ${gigData.amountOfWorkers} ${t(
                    "CalendarCompany.Hired"
                  )}`}
                </GigText>
              )}
              {matchingUnhandledApplications.length > 0 && (
                <TextBadge>{matchingUnhandledApplications.length}</TextBadge>
              )}
            </ApplicationRow>
          </>
        )}
      </GigCell>
      {emptyDays.map((day) => {
        return (
          <ShiftColumn
            key={day}
            column={day === 0 ? 7 : day}
            row={gigDataIndex}
          />
        );
      })}
      {gigShiftsByDay.map((shiftArray: CompanyCalendarShift[]) => (
        <ShiftColumn
          key={shiftArray[0].id}
          column={
            moment(shiftArray[0].startTime).day() > 0
              ? moment(shiftArray[0].startTime).day()
              : 7
          }
          row={gigDataIndex}
        >
          {shiftArray.map((shift: CompanyCalendarShift) => {
            return gigsWorkersList.map(
              (workerData: GigsWorkersList, index: number) => {
                const workerMatchingTimeReport =
                  workerData.shiftWithTimeReport.find(
                    (shiftWithTimeReport: ShiftWithTimeReport) =>
                      shiftWithTimeReport.shift.id === shift.id &&
                      shiftWithTimeReport.timeReport
                  );

                const keyId = shift.id + index;
                const workerName = workerData.worker?.firstName || "";

                if (workerMatchingTimeReport) {
                  if (
                    workerMatchingTimeReport.timeReport &&
                    [
                      TimeReportResolution.CANCELLED,
                      TimeReportResolution.WITHDRAWN,
                    ].includes(workerMatchingTimeReport.timeReport?.resolution)
                  ) {
                    return (
                      <WithdrawnCalendarGig
                        name={workerName}
                        shift={shift}
                        keyId={keyId}
                        timeReport={workerMatchingTimeReport.timeReport}
                        compactView={compactView}
                        dashboard={dashboard}
                        gigData={gigData}
                      />
                    );
                  } else {
                    return (
                      <FilledCalendarGig
                        name={workerName}
                        keyId={keyId}
                        timeReport={workerMatchingTimeReport.timeReport}
                        compactView={compactView}
                        dashboard={dashboard}
                        error={error}
                        gigDataId={gigData.id}
                      />
                    );
                  }
                } else if (
                  workerData.worker &&
                  workerData.applications &&
                  workerData.applications?.length > 0
                ) {
                  return (
                    <OfferedCalendarGig
                      name={workerName}
                      shift={shift}
                      keyId={keyId}
                      compactView={compactView}
                      dashboard={dashboard}
                      gigData={gigData}
                      deleteShift={deleteShift}
                      workerDataId={workerData.worker?.id}
                    />
                  );
                } else {
                  return (
                    <NotFilledCalendarGig
                      name={workerName}
                      shift={shift}
                      keyId={keyId}
                      compactView={compactView}
                      dashboard={dashboard}
                      gigData={gigData}
                      deleteShift={deleteShift}
                    />
                  );
                }
              }
            );
          })}
        </ShiftColumn>
      ))}

      {gigModalOpen && (
        <GigDetailsModal
          gigId={gigData.id}
          setModalOpen={setGigModalOpen}
          modalOpen
        />
      )}

      <GigApplicationsModal
        gigData={gigData}
        setModalOpen={setApplicationsModalOpen}
        modalOpen={applicationsModalOpen}
      />
    </>
  );
};
