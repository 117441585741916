import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { H3 } from "components/Typography/text.styled";
import { Options } from "./gigs.styled";
import { CloseGigModal } from "./closeGigModal.component";
import { Modal } from "@material-ui/core";
import { ModalBody } from "../calendar/weeklyCalendar.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { TimeReportStatus } from "model/TimeReport";
import { useAlertStore } from "stores/alertStore/alertStore";
import {
  IconCircularContainer,
  IconsContainer,
} from "../dashboard/dashboard.styled";
import { WorkerWithUrl } from "model/Company";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
  workerWithImageUrl?: WorkerWithUrl[];
};

export const GigOptions: React.FC<Props> = ({ gigData, onGigClosed }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions] = useCompanyStore();

  const handleCloseGig = () => {
    if (companyState.company?.id) {
      Api()
        .company.timeReport.getGigTimeReports(
          companyState.company?.id,
          gigData.id
        )
        .then((res) => {
          if (
            res.data.find(
              (timeReport) => timeReport.status !== TimeReportStatus.NEW
            )
          ) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: "",
                message: t("GigsCompany.ThisGigHasConnectedTimeReports"),
              },
            });
          } else {
            setModalOpen(true);
          }
        });
    }
  };

  return (
    <>
      <Options>
        <IconsContainer>
          <IconCircularContainer
            isAccept
            onClick={() => {
              setShowCreateGigModal(true);
            }}
          >
            <CustomIcon
              className="icon"
              name="clone"
              size="20px"
              padding="2px"
              color={Color.White}
            />
          </IconCircularContainer>
          <IconCircularContainer
            onClick={() => {
              handleCloseGig();
            }}
          >
            <CustomIcon
              className="icon"
              name="trashcan"
              size="20px"
              padding="2px"
              color={Color.White}
            />
          </IconCircularContainer>
        </IconsContainer>
      </Options>
      <CloseGigModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        gigData={gigData}
        onGigClosed={onGigClosed}
      />
      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>{t("GigsCompany.CloneGig")}</H3>
          <SaveGigForm
            setShowCreateGigModal={setShowCreateGigModal}
            isClone={true}
            gigData={gigData}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
