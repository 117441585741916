import { CircularProgress, Modal } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleApiCall } from "services/api/api.service";
import { ButtonContainer } from "../../applicantDetails/applicantDetails.styled";
import { TextInput } from "../../gig/gigs.styled";
import { CircularProgressWrapper } from "../../timeReport/monthlyOverview.styled";
import { StructureModalBody } from "./companyStructureEditEntity.styled";
import { CloseButtonContainer } from "./editUnit/companyStructureEditUnit.styled";
import { RemoveModal } from "./companyStructureRemoveModal.component";
import { addEntity, deleteEntity, updateEntity } from "./companyStructureEditEntityApiUtils";


export interface EditEntityConfig {
  id: number;
  name: string;
}

type Props = {
  onStructureRefresh: React.Dispatch<React.SetStateAction<void>>
  onClose: React.Dispatch<React.SetStateAction<void>>;
  companyId: number;
  entity: EditEntityConfig | null;
};

export const EditEntityModal: React.FC<Props> = ({
  onStructureRefresh,
  onClose,
  companyId,
  entity,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentEntityName, setEntityName] = useState(entity ? entity?.name : "");
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false)

  const executeApiCall = (apiCall: () => Promise<any>, onError?: (err: any) => void) => {
    setIsLoading(true);

    handleApiCall({
      apiCall: apiCall,
      onResult: () => onStructureRefresh(),
      onError: onError,
      onCompleted: () => setIsLoading(false)
    })
  }

  const onAddOrUpdate = () => {
    if (entity && currentEntityName !== "") {
      updateEntity(executeApiCall, companyId, entity?.id, currentEntityName)
    } else {
      addEntity(executeApiCall, companyId, currentEntityName)
    }

    onClose()
  }

  const onDelete = () => {
    if (entity) {
      deleteEntity(executeApiCall, companyId, entity?.id)
    }
  }

  return (
    <Modal
      open={true}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <StructureModalBody>
        {isLoading ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <>
            <CloseButtonContainer>
              <CustomIcon
                color={Color.LighterDestructive}
                onClick={() => {
                  onClose()
                }}
                name={"cross"}
              >
                {t("General.Close")}
              </CustomIcon>
            </CloseButtonContainer>

            <ButtonContainer>
              <TextInput style={{ margin: 10, marginBottom: 30 }}
                fontSize={FontSize.Large}
                value={currentEntityName}
                onChange={(e) => setEntityName(e.currentTarget.value)}
                placeholder={`${t("CompanyStructure.Entity")}`}
              />
            </ButtonContainer>

            <ButtonContainer>
              <ButtonStyled
                disabled={currentEntityName === "" || currentEntityName === entity?.name}
                onClick={onAddOrUpdate}
                backgroundColor={Color.ModernGreen}
              >
                {entity ? t("General.Save") : t("General.Add")}
              </ButtonStyled>

              <ButtonStyled
                isCancel
                disabled={entity === null}
                onClick={() => {
                  setRemoveModalOpen(true)
                }}
                backgroundColor={Color.LighterDestructive}
              >
                {t("General.Remove")}
              </ButtonStyled>
            </ButtonContainer>

          </>
        )}
        
        {removeModalOpen &&
          <RemoveModal
            message={`${currentEntityName} ${t("CompanyStructure.AreYouSureToRemoveEntity")}`}
            onCloseRemoveModal={() => setRemoveModalOpen(false)}
            onClick={() => {
              onDelete()
              onClose()
            }}
            t={t} />
        }
      </StructureModalBody>
    </Modal>
  )
}
