import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { H3 } from "components/Typography/text.styled";
import { Api } from "services/api/api.service";
import { TitleRow } from "../companyUsers/companyUsers.styled";
import {
  InputContainer,
  LabelStyled,
} from "components/form/inputs/inputs.styled";
import { FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { SubscriptionInfo } from "model/Company";
import { SimflexityPayCompany } from "web-apps/company/pages/payments/simflexityPay/simflexityPay";
import { DividerLine } from "../companyStampClock/companyStampClock.styled";
import { SubscriptionTerms } from "./subscriptionTerms.component";

export const CompanySubscription: React.FC = () => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>({
    companyId: companyState.company?.id,
    expirationDate: "",
    membershipDisplayName: "",
    membershipType: "",
    membershipTypeId: "",
    membershipTypekey: "",
  });

  useEffect(() => {
    if (companyState.company?.id) {
      Api()
        .company.getCompanySubscriptionInfo(companyState.company?.id)
        .then((res) => {
          setSubscriptionInfo(res.data);
        });
    }
  }, [companyState.company?.id]);

  return (
    <>
      <TitleRow>
        <H3>{t("ProfileCompany.Subscription")}</H3>
      </TitleRow>
      <InputContainer>
        <LabelStyled>{t("ProfileCompany.Subscription")}</LabelStyled>
        <Text fontSize={FontSize.Large}>
          {t(`${subscriptionInfo.membershipDisplayName}`)}
        </Text>
      </InputContainer>

      <DividerLine />

      {companyState.company && (
        <SimflexityPayCompany
          companyId={companyState.company.id.toString()}
        />
      )}

      <DividerLine />

      <SubscriptionTerms/>
    </>
  );
};
