import { useTranslation } from "react-i18next";
import { TermsWebLink } from "./termsWebLink.component";
import { Text } from "components/Typography/text.styled";
import { FontSize } from "config/font";
import { Color } from "config/colors";

export const TermsAndPrivacy = () => {
  const { t } = useTranslation();
  return <>
    <TermsWebLink
      selectedTerms={t("General.TermsAndConditionsLink")}
      linkName={t("RegisterCompany.TermsOfService")}
    />

    <Text
      fontSize={FontSize.Small}
      color={Color.SeaBlue600}
      style={{ justifyContent: "end", whiteSpace: "pre-wrap" }}
    >
      {` ${t("RegisterCompany.And")} `}
    </Text>

    <TermsWebLink
      selectedTerms={t("General.PersonalIntegrityLink")}
      linkName={t("RegisterCompany.PrivacyPolicy")}
    />
  </>
}
