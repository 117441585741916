import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  SkillsWrapper,
  SkillTag,
  RoleAndSkillsContainer,
  DropDownRow,
  Row,
} from "./gigDetail.styled";

type Props = {
  gigData: CompanyGigDto;
};

export const GigDetailRolesAndSkills: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [roleAndSkillsOpen, setRoleAndSkillsOpen] = useState(false);

  return (
    <>
      <DropDownRow
        onClick={() => {
          setRoleAndSkillsOpen(!roleAndSkillsOpen);
        }}
        style={{ marginTop: 20 }}
      >
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          {t("GigsCompany.RoleAndSkills")}
        </Text>
        <CustomIcon
          name={roleAndSkillsOpen ? "upward_arrow" : "downward_arrow"}
          size="24px"
          color={Color.BurntSienna}
          style={{ marginTop: 10 }}
        />
      </DropDownRow>
      {roleAndSkillsOpen && (
        <RoleAndSkillsContainer>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.RoleWanted")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {gigData.role && t(gigData.role, { ns: "roles" })}
            </Text>
          </Row>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.SkillsWanted")}
            </Text>
            <SkillsWrapper>
              {gigData.skills.map((skill, index) => (
                <SkillTag key={index}>
                  <Text
                    key={index}
                    color={Color.BurntSienna}
                    fontFamily={FontFamily.MontserratRegular}
                    fontSize={FontSize.Standard}
                    style={{ margin: 0 }}
                  >
                    {t(skill.skill, { ns: "skills" })}
                  </Text>
                </SkillTag>
              ))}
            </SkillsWrapper>
          </Row>
        </RoleAndSkillsContainer>
      )}
    </>
  );
};
