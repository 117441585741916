import { Color } from "config/colors";
import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  margin: 0 auto;
  align-items:center;
`;
