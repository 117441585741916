import React, { useState } from "react";
import {
  HourlyPayContainer,
  MonthlyPayContainer,
  Options,
  WorkerNameContainer,
  WorkGroupDetailsRowContainer,
} from "./workgroup.styled";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { DividerLine1, DividerLine2 } from "../gig/gigs.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { useTranslation } from "react-i18next";
import { CompanyWorkerDto } from "model/Company";
import { fetchWorker } from "web-apps/company/utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { WorkerDetail } from "../timeReport/workers/workerDetail.component";
import { WorkGroupDeleteWorker } from "./workgroupDeleteWorker.component";
import { WorkGroupEditWorkerModal } from "./workgroupEditWorkerModal.component";
import { Setting, WorkGroupWorker } from "model/Workgroup";
import { WorkGroupDeleteInvitationModal } from "./workgroupDeleteInvitationModal.component";

type Props = {
  w: WorkGroupWorker;
  status: string;
  hourlyPaySetting?: Setting;
  monthlyPaySetting?: Setting;
};

export const WorkGroupDetailsRow: React.FC<Props> = ({
  w,
  status,
  hourlyPaySetting,
  monthlyPaySetting,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [worker, setWorker] = useState<CompanyWorkerDto>();
  const [workGroupWorker, setWorkGroupWorker] = useState<WorkGroupWorker>();
  const [showDeleteWorkerModal, setShowDeleteWorkerModal] = useState(false);
  const [showEditWorkerModal, setShowEditWorkerModal] = useState(false);
  const [showDeleteInvitationModal, setShowDeleteInvitationModal] =
    useState(false);

  return (
    <>
      <WorkGroupDetailsRowContainer>
        <WorkerNameContainer
          onClick={() => {
            fetchWorker(w.id, companyState).then((worker) => {
              setWorker(worker.data);
              setWorkerModalOpen(true);
            });
          }}
        >
          <Text
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
            cursor
          >
            {`${w.firstName} ${w.lastName}`}
          </Text>
        </WorkerNameContainer>
        <DividerLine1 />
        <MonthlyPayContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
          >
            {t("WorkGroup.MonthlyRate")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >
            {monthlyPaySetting ? monthlyPaySetting.value : "-"}
          </Text>
        </MonthlyPayContainer>
        <DividerLine2 />
        <HourlyPayContainer>
          <Text
            style={{ margin: 0, padding: 0 }}
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
          >
            {t("WorkGroup.HourlyRate")}
          </Text>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratRegular}
          >
            {hourlyPaySetting ? hourlyPaySetting.value : "-"}
          </Text>
        </HourlyPayContainer>
        {status !== "declined" && (
          <Options>
            <CustomIcon
              className="icon"
              name="settings"
              size="24px"
              padding="2px"
              color={Color.ModernGreen}
              onClick={() => {
                setWorkGroupWorker(w);
                setShowEditWorkerModal(true);
              }}
              style={{ marginRight: 20 }}
            />
            <CustomIcon
              className="icon"
              name="trashcan"
              size="24px"
              padding="2px"
              color={Color.LighterDestructive}
              onClick={() => {
                setWorkGroupWorker(w);
                if (status == "members") {
                  setShowDeleteWorkerModal(true);
                } else {
                  setShowDeleteInvitationModal(true);
                }
              }}
            />
          </Options>
        )}
      </WorkGroupDetailsRowContainer>
      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
        />
      )}
      {showDeleteWorkerModal && workGroupWorker && (
        <WorkGroupDeleteWorker
          worker={workGroupWorker}
          setShowDeleteWorkerModal={setShowDeleteWorkerModal}
          showDeleteWorkerModal={showDeleteWorkerModal}
        />
      )}
      {showDeleteInvitationModal && workGroupWorker && (
        <WorkGroupDeleteInvitationModal
          inviteId={workGroupWorker.inviteId}
          workGroupId={workGroupWorker.workGroupId}
          setShowDeleteInvitationModal={setShowDeleteInvitationModal}
          showDeleteInvitationModal={showDeleteInvitationModal}
        />
      )}
      {showEditWorkerModal && workGroupWorker && (
        <WorkGroupEditWorkerModal
          worker={workGroupWorker}
          setShowEditWorkerModal={setShowEditWorkerModal}
          showEditWorkerModal={showEditWorkerModal}
        />
      )}
    </>
  );
};
