import { Color } from "config/colors";
import { HeadCellFooter } from "../monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { useTranslation } from "react-i18next";

export const TimeReportFooterHeader = () => {
  const { t } = useTranslation()
  
  return <>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.Status")}
      </Text>
    </HeadCellFooter>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.TotalHours")}
      </Text>
    </HeadCellFooter>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.GrossSalaryExcludingOB")}
      </Text>
    </HeadCellFooter>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.OB")}
      </Text>
    </HeadCellFooter>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.SocialFees")}
      </Text>
    </HeadCellFooter>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.SimflexityFee")}
      </Text>
    </HeadCellFooter>
    <HeadCellFooter>
      <Text
        color={Color.White}
        fontSize={FontSize.Standard}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CompanyTimeReport.TotalCost")}
      </Text>
    </HeadCellFooter>
  </>
}
