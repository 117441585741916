import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import {
  SkillAndEndorsements,
  TimeReportDto,
  WorkerWithTimeReports,
  WorkerWithTimeReportsForPersonnelCarrier,
} from "model/TimeReport";
import { RatingDto } from "model/Rating";
import { Salary } from "model/Salary";

const updateGigTimeReport = (
  companyId: number,
  gigId: number,
  timeReport: TimeReportDto
): Promise<AxiosResponse<TimeReportDto>> => {
  return axiosRequest.auth
    .put<TimeReportDto>(
      `${API_ROOT}/companies/${companyId}/gigs/${gigId}/timereports/${timeReport.id}`,
      timeReport
    )
    .then((res) => {
      return res;
    });
};

const rateWorker = async (data: RatingDto): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<RatingDto>(
    `${API_ROOT}/companies/${data.companyId}/rating`,
    data
  );
};

const getWorkerSkillsAndEndorsements = (
  companyId: number,
  timeReportId: number,
  workerId: number
): Promise<AxiosResponse<any>> => {
  return axiosRequest.auth.get<SkillAndEndorsements[]>(
    `${API_ROOT}/companies/${companyId}/timereports/${timeReportId}/WorkerInfo/${workerId}`
  );
};

const getTimeReport = (
  companyId: number,
  gigId: number,
  timeReportId: number
): Promise<AxiosResponse<TimeReportDto>> => {
  return axiosRequest.auth.get<TimeReportDto>(
    `${API_ROOT}/companies/${companyId}/gigs/${gigId}/timereports/${timeReportId}`
  );
};

const getGigTimeReports = (
  companyId: number,
  gigId: number
): Promise<AxiosResponse<TimeReportDto[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/gigs/${gigId}/timereports`
  );
};

const getAllTimeReports = (
  companyId: number,
  period: number,
): Promise<AxiosResponse<WorkerWithTimeReports[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports?period=${period}&state=all`
  );
};

const getUnhandledTimeReports = (
  companyId: number,
): Promise<AxiosResponse<WorkerWithTimeReports[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports?state=unhandled`
  );
};

const getAllWorkerPersonnelCarrier = (
  companyId: number,
  year: number,
  month: number,
  day: number,
): Promise<AxiosResponse<WorkerWithTimeReportsForPersonnelCarrier[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/personnelCarrier/${year}-${month}-${day}`
  );
};

const getUnhandledTimeReportCount = (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports/unhandled/count`
  );
};

const getSalaryData = (
  companyId: number,
  workerId: number,
  from: string,
  to: string,
): Promise<AxiosResponse<Salary>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/workers/${workerId}/salary/${from}-${to}`
  );
};

export const timeReport = {
  updateGigTimeReport,
  rateWorker,
  getWorkerSkillsAndEndorsements,
  getTimeReport,
  getGigTimeReports,
  getAllTimeReports,
  getUnhandledTimeReports,
  getUnhandledTimeReportCount,
  getAllWorkerPersonnelCarrier,
  getSalaryData
};
