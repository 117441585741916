import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { H3 } from "components/Typography/text.styled";
import { Prompt } from "react-router-dom";
import { DividerLine } from "./companyStampClock.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useAlertStore } from "stores/alertStore/alertStore";
import { StampClockTemplate } from "model/Company";
import moment from "moment";
import { LocalStampClockSetting } from "./localStampClockSetting.component";
import { AutoGenerateShiftSetting } from "./autoGenerateShiftSetting.component";
import { AutoGenerateShiftTemplateForm } from "./autoGenerateShiftTemplateForm.component";

export const CompanyStampClock: React.FC = () => {
  const { t } = useTranslation();
  const [companyState, companyActions] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [template, setTemplate] = useState<StampClockTemplate | null>(null);
  const [localStampClock, setLocalStampClock] = useState(false);
  const [autoGenerateShiftSetting, setAutoGenerateShiftSetting] =
    useState(false);
  const HHMMRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  const [formData, setFormData] = useState({
    role: "",
    startTime: "",
    endTime: "",
    hourlyRate: 0,
    breakTime: 0,
    paymentType: "None",
    localStampClockSettingActive: localStampClock,
    autoGenerateShiftSettingActive: autoGenerateShiftSetting,
  });

  useEffect(() => {
    const fetchData = async () => {
      const companyId = companyState.company?.id;
      if (companyId) {
        try {
          const templateData = await companyActions.getStampClockTemplate(
            companyId
          );
          setTemplate(templateData);
        } catch (error) {
          console.error("Failed to fetch template data", error);
        }

        try {
          const localStampClockSetting =
            await companyActions.getLocalStampClockOnlySetting(companyId);
          setLocalStampClock(localStampClockSetting);
        } catch (error) {
          console.error("Failed to fetch local stamp clock setting", error);
        }

        try {
          const autoGenerateShiftSetting =
            await companyActions.getAutogenerateShiftSetting(companyId);
          setAutoGenerateShiftSetting(autoGenerateShiftSetting);
        } catch (error) {
          console.error("Failed to fetch auto-generate shift setting", error);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (template) {
      setFormData({
        role: template.role,
        startTime: formatTime(template.startTime, false),
        endTime: formatTime(template.endTime, false),
        hourlyRate: template.hourlyRate,
        breakTime: template.breakTime,
        paymentType: template.paymentType,
        localStampClockSettingActive: localStampClock,
        autoGenerateShiftSettingActive: autoGenerateShiftSetting,
      });
    } else {
      setFormData({
        ...formData,
        localStampClockSettingActive: localStampClock,
        autoGenerateShiftSettingActive: autoGenerateShiftSetting,
      });
    }
  }, [template, autoGenerateShiftSetting, localStampClock]);

  const madeChanges = useMemo(() => {
    const checkboxesChanged =
      formData.localStampClockSettingActive !== localStampClock ||
      formData.autoGenerateShiftSettingActive !== autoGenerateShiftSetting;

    if (!template) {
      return (
        formData.startTime !== "" ||
        formData.endTime !== "" ||
        formData.hourlyRate !== 0 ||
        formData.breakTime !== 0 ||
        formData.paymentType !== "None" ||
        checkboxesChanged
      );
    }

    return (
      formData.startTime !== moment(template.startTime).format("HH:mm") ||
      formData.endTime !== moment(template.endTime).format("HH:mm") ||
      formData.hourlyRate !== template.hourlyRate ||
      formData.breakTime !== template.breakTime ||
      formData.paymentType !== template.paymentType ||
      checkboxesChanged
    );
  }, [formData, localStampClock, autoGenerateShiftSetting, template]);

  const validate = () => {
    const isValidTime =
      HHMMRegex.test(formData.startTime) &&
      HHMMRegex.test(formData.endTime) &&
      moment(formData.endTime, "HH:mm").isAfter(
        moment(formData.startTime, "HH:mm")
      );

    return (
      formData.startTime &&
      formData.endTime &&
      formData.hourlyRate > 0 &&
      formData.breakTime >= 0 &&
      isValidTime
    );
  };

  const showAlert = (error?: boolean) => {
    if (!error) {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "checkmark",
          title: t("Alerts.Updated"),
          message: t("ProfileCompany.StampClockSettingsHasBeenUpdated"),
        },
      });
    } else {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "alert",
          title: t("Alerts.OhNo"),
          message: t("ProfileCompany.StampClockSettingsCouldNotBeUpdated"),
        },
      });
    }
  };

  const formatTime = (time: string, convertToUTC: boolean) => {
    if (convertToUTC) {
      return moment.tz(time, "HH:mm", "Europe/Stockholm").utc().format("HH:mm");
    } else {
      if (moment(time, moment.ISO_8601, true).isValid()) {
        return moment.utc(time).tz("Europe/Stockholm").format("HH:mm");
      } else {
        return moment.utc(time, "HH:mm").tz("Europe/Stockholm").format("HH:mm");
      }
    }
  };

  const handleUpdate = async () => {
    const companyId = companyState.company?.id;
    const {
      localStampClockSettingActive,
      autoGenerateShiftSettingActive,
      ...templateData
    } = formData;
    const data = { companyId, ...templateData };

    if (companyId) {
      try {
        if (localStampClockSettingActive !== localStampClock) {
          await companyActions.updateStampClockLocalOnlySetting(
            companyId,
            localStampClockSettingActive
          );
        }
        if (autoGenerateShiftSettingActive !== autoGenerateShiftSetting) {
          await companyActions.updateAutogenerateShiftSetting(
            companyId,
            autoGenerateShiftSettingActive
          );
        }
        if (autoGenerateShiftSettingActive && validate()) {
          data.startTime = formatTime(data.startTime, true);
          data.endTime = formatTime(data.endTime, true);
          await companyActions.updateStampClockTemplate(data);
        }
        showAlert();
      } catch (error) {
        showAlert(true);
      }
    }
  };

  return (
    <>
      <Prompt
        when={madeChanges}
        message={t("ProfileCompany.YouHaveUnSavedChanges")}
      />
      <H3>{t("ProfileCompany.StampClock")}</H3>
      <LocalStampClockSetting
        localStampClockSettingActive={formData.localStampClockSettingActive}
        setFormData={setFormData}
      />
      <DividerLine />
      <AutoGenerateShiftSetting
        autoGenerateShiftSettingActive={formData.autoGenerateShiftSettingActive}
        setFormData={setFormData}
      />
      <AutoGenerateShiftTemplateForm
        formData={formData}
        setFormData={setFormData}
        HHMMRegex={HHMMRegex}
      />
      <ButtonStyled
        disabled={
          (template && !validate()) ||
          !madeChanges ||
          (formData.autoGenerateShiftSettingActive && !validate())
        }
        onClick={handleUpdate}
      >
        {t("General.Save")}
      </ButtonStyled>
    </>
  );
};
