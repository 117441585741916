import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { fetchWorker } from "web-apps/company/utils/utils";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { WorkerCountWrapper, ImageWrapper, SmallImage } from "./gigs.styled";
import { Modal } from "@material-ui/core";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { OfferModal } from "../gigDetail/offerModal.component";
import { OfferList } from "model/Calendar";
import { CompanyWorkerDto, WorkerWithUrl } from "model/Company";
import { WorkerDetail } from "../timeReport/workers/workerDetail.component";

type Props = {
  gigData: CompanyGigDto;
  workerWithImageUrl?: WorkerWithUrl[];
  isActive?: boolean;
};

export const GigWorkerCount: React.FC<Props> = ({
  gigData,
  workerWithImageUrl,
  isActive,
}) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<(string | null)[]>([]);
  const [, , alertDispatch] = useAlertStore();
  const maxImages = 5;
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerList, setOfferList] = useState<OfferList[]>([]);
  const [offerHourlyRate, setOfferHourlyRate] = useState(0);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);
  const [workerIds, setWorkerIds] = useState<(number | null)[]>([]);
  const [companyState, companyActions] = useCompanyStore();
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [worker, setWorker] = useState<CompanyWorkerDto>();

  useEffect(() => {
    if (!gigData.workerIds) return;

    const workerIds = gigData.workerIds;
    const workerImageUrls = Array(gigData.amountOfWorkers).fill(null);
    workerIds.forEach((id, index) => {
      if (workerWithImageUrl) {
        workerWithImageUrl.forEach((workerWithImage) => {
          if (id === workerWithImage.workerId) {
            workerImageUrls[index] = workerWithImage.imageUrl;
          }
        });
      }
    });
    setWorkerImages(workerImageUrls);
    setWorkerIds(workerIds);
  }, [gigData.workers, workerWithImageUrl]);

  useEffect(() => {
    setWorkerModalOpen(true);
  }, [worker]);

  return (
    <>
      <WorkerCountWrapper>
        {workerImages.map((url, index) => {
          if (index < maxImages) {
            return (
              <ImageWrapper
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const workerId = workerIds[index];
                  if (typeof workerId === "number") {
                    fetchWorker(workerId, companyState).then((worker) => {
                      setWorker(worker.data);
                    });
                  } else if (isActive) {
                    setOfferModalOpen(true);
                  }
                }}
              >
                {url ? (
                  <SmallImage src={url} />
                ) : (
                  <CustomIcon
                    name="person"
                    size="100%"
                    color={Color.SeaBlue500}
                    padding="4px"
                  />
                )}
              </ImageWrapper>
            );
          }
        })}
        <Text color={Color.SeaBlue400} style={{ margin: 0 }}>
          {gigData.workers?.length}/{gigData.amountOfWorkers}
        </Text>
      </WorkerCountWrapper>
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={gigData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
          clickedShift={gigData.id}
        />
      </Modal>
      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
        />
      )}
    </>
  );
};
