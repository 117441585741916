import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyCalendarGigDto, CompanyCalendarShift } from "model/Calendar";
import { Text } from "components/Typography/text.styled";
import { GigText, ApplicationRow } from "./previewCalendarGig.styled";
import { GigCell, ShiftCell, ShiftColumn } from "../weeklyCalendar.styled";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import moment from "moment";

type Props = {
  gigData: CompanyCalendarGigDto;
  gigDataIndex: number;
};

export const PreviewCalendarGig = ({ gigData, gigDataIndex }: Props) => {
  const { t } = useTranslation();
  const [gigShiftsByDay, setGigShiftsByDay] = useState<any>([]);
  const [emptyDays, setEmptyDays] = useState<number[]>([]);

  useEffect(() => {
    // Gig Shifts
    const result = gigData.shifts.reduce(
      (acc: any, shift: CompanyCalendarShift) => {
        const key = moment(shift.startTime).format("YYYY-MM-DD");
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(shift);
        return acc;
      },
      {}
    );

    setGigShiftsByDay(Object.values(result));

    // Empty Weekdays
    let weekdays = [1, 2, 3, 4, 5, 6, 7];
    gigData.shifts.forEach((shift: CompanyCalendarShift) => {
      let shiftDay = moment(shift.startTime).day();
      if (shiftDay === 0) {
        shiftDay = 7;
      }

      weekdays = weekdays.filter((day) => day !== shiftDay);
    });

    setEmptyDays(weekdays);
  }, [gigData]);

  const renderCell = (shift: CompanyCalendarShift, key: number) => {
    const { id, startTime, endTime } = shift;

    return (
      <ShiftCell
        key={id + key}
        leftBorderColor={Color.SeaBlue500}
        backgroundColor={Color.SeaBlue300}
        disabled
      >
        <Text color={Color.White}>
          {`${moment(startTime).format("HH:mm")} - ${moment(endTime).format(
            "HH:mm"
          )}`}
        </Text>
        <Text color={Color.White}>{t("CalendarCompany.NotFilled")}</Text>
      </ShiftCell>
    );
  };

  return (
    <>
      <GigCell>
        <GigText
          fontSize={FontSize.Standard}
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {gigData.role}
        </GigText>
        <>
          <ApplicationRow>
            <GigText
              fontSize={FontSize.Small}
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {`0 / ${gigData.amountOfWorkers} ${t("CalendarCompany.Hired")}`}
            </GigText>
          </ApplicationRow>
        </>
      </GigCell>
      {gigShiftsByDay.map((shiftArray: CompanyCalendarShift[]) => (
        <ShiftColumn
          key={shiftArray[0].id}
          column={
            moment(shiftArray[0].startTime).day() > 0
              ? moment(shiftArray[0].startTime).day()
              : 7
          }
          row={gigDataIndex}
        >
          {shiftArray.map((shift: CompanyCalendarShift) => {
            return [...Array(gigData.amountOfWorkers)].map((_, key) =>
              renderCell(shift, key)
            );
          })}
        </ShiftColumn>
      ))}
      {emptyDays.map((day) => {
        return (
          <ShiftColumn
            key={day}
            column={day === 0 ? 7 : day}
            row={gigDataIndex}
          />
        );
      })}
    </>
  );
};
