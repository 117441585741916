import { ChronoUnit, Instant, LocalDateTime, LocalTime } from '@js-joda/core';
import { TimeReportDto, TimeReportResolution } from 'model/TimeReport';
import moment from 'moment';
import { calculateBreakTime } from 'utils/utils';

export function calculateTimeReportDetails(timeReports: TimeReportDto[]) {
  let totalTimeMin = 0;
  let totalTimeMinApproved = 0;

  const details = timeReports.map((timeReport) => {
    const startTime = LocalDateTime.ofInstant(Instant.parse(timeReport.startTime));
    const endTime = LocalDateTime.ofInstant(Instant.parse(timeReport.endTime));
    let shiftTotalMinutes = startTime.until(endTime, ChronoUnit.MINUTES);

    if (timeReport.breakStartTime && timeReport.breakEndTime) {
      shiftTotalMinutes -= calculateBreakTime(
        LocalTime.ofInstant(Instant.parse(timeReport.breakStartTime.slice(0, 19) + "Z")).toString().slice(0, 5),
        LocalTime.ofInstant(Instant.parse(timeReport.breakEndTime.slice(0, 19) + "Z")).toString().slice(0, 5),
        moment(LocalDateTime.ofInstant(Instant.parse(timeReport.startTime.slice(0, 19) + "Z")).toString()),
        moment(LocalDateTime.ofInstant(Instant.parse(timeReport.endTime.slice(0, 19) + "Z")).toString())
      );
    }

    const shiftHours = Math.floor(shiftTotalMinutes / 60);
    const shiftMinutes = shiftTotalMinutes % 60;
    const shiftTimeString = `${shiftHours ? `${shiftHours}h ` : ''}${shiftMinutes ? `${shiftMinutes}min` : ''}`;

    totalTimeMin += shiftTotalMinutes;

    if ([TimeReportResolution.PAID, TimeReportResolution.TIME_REPORTED, TimeReportResolution.PAYONDEMAND].includes(timeReport.resolution)) {
      totalTimeMinApproved += shiftTotalMinutes;
    }

    const weekDayString = `WeekDays.${startTime.dayOfWeek().name()}SHORT`;

    return {
      startTime,
      endTime,
      shiftTimeString,
      weekDayString,
      shiftTotalMinutes,
    };
  });

  return {
    details,
    totalTimeMin,
    totalTimeMinApproved,
  };
}
