import React from 'react';
import { Cell } from '../monthlyOverview.styled';
import { Color } from 'config/colors';
import { FontSize } from 'config/font';
import { Text } from "components/Typography/text.styled";

interface TotalCostRowProps {
  rowTitle: string;
  timeTotalString: string;
  grossSalary: number;
  obAmount: number;
  socialFee: number;
  simflexityFee: number;
  totalCost: number;
}

const TotalCostFooterRow: React.FC<TotalCostRowProps> = ({
  rowTitle,
  timeTotalString,
  grossSalary,
  obAmount,
  socialFee,
  simflexityFee,
  totalCost,
}) => {
  return (
    <>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {rowTitle}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {timeTotalString}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {(grossSalary ? grossSalary : 0).toFixed(2) + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {obAmount + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {socialFee + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {(simflexityFee ? simflexityFee : 0).toFixed(2) + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCost + " kr"}
        </Text>
      </Cell>
    </>
  );
};

export default TotalCostFooterRow;
