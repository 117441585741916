import { CircularProgress, Modal } from '@material-ui/core';
import { CompanyStructureDto } from 'model/CompanyStructure';
import { Api, handleApiCall } from 'services/api/api.service';
import { ButtonContainer, CloseButtonContainer, EditUnitModalBody, LoadingContainer } from './companyStructureEditUnit.styled';
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from 'components/buttons/buttons.styled';
import { Color } from 'config/colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChooseWorkerTable from './companyStructureEditUnitChooseExistingTable.component';
import { excludeWorkersByUnitEntity, extractUniqueWorkers } from '../companyStructureUtils';
import { FontFamily, FontSize } from 'config/font';
import { CustomIcon } from 'components/icon/customIcon.component';

interface Props {
  companyId: number,
  entityId: number,
  unitId: number,
  onClose: () => void,
  onStructureRefresh: React.Dispatch<React.SetStateAction<void>>,
}

const ChooseExistingWorkersModal: React.FC<Props> = ({
  companyId,
  entityId,
  unitId,
  onClose,
  onStructureRefresh
}) => {
  const { t } = useTranslation()
  const [selectedWorkers, setSelectedWorkers] = useState<number[]>([]);
  const [company, setCompany] = useState<CompanyStructureDto | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCompanyStructureData = () => {
    setIsLoading(true);

    handleApiCall({
      apiCall: () => Api().company.fetchCompanyStructure(companyId),
      onResult: (res) => { setCompany(res.data); },
      onCompleted: () => { setIsLoading(false); },
    });
  };

  const addExistingWorkersToCurrentUnit = () => {
    setIsLoading(true)

    handleApiCall({
      apiCall: () => Api().company.addWorkers(companyId, entityId, unitId, selectedWorkers),
      onResult: (_res) => {
        onStructureRefresh()
        onClose()
      },
      onCompleted: () => { setIsLoading(false); },
    });
  };

  useEffect(() => {
    fetchCompanyStructureData();
  }, []);

  const handleSelectWorker = (workerId: number) => {
    setSelectedWorkers((prevSelected) =>
      prevSelected.includes(workerId)
        ? prevSelected.filter((id) => id !== workerId)
        : [...prevSelected, workerId]
    );
  };

  const getEntityName = (entityId: number): string => { const entity = company?.entities.find((entity) => entity.id === entityId); return entity ? entity.name : ''; };

  const getUnitName = (unitId: number): string => { const unit = company?.entities.flatMap((entity) => entity.units).find((unit) => unit.id === unitId); return unit ? unit.name : ''; };
  const allWorkerConfigs = company ? extractUniqueWorkers(company?.entities) : []

  const filteredWorkers = excludeWorkersByUnitEntity(allWorkerConfigs, unitId, entityId)

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <EditUnitModalBody>
        <CloseButtonContainer>
          <CustomIcon
            color={Color.LighterDestructive}
            onClick={() => {
              onClose()
            }}
            name={"cross"}
          >
            {t("General.Close")}
          </CustomIcon>
        </CloseButtonContainer>
        
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.MidnightBlue}
          fontSize={FontSize.Large}
        >
          {t("CompanyStructure.Workers")}
        </Text>
        
        {filteredWorkers && filteredWorkers.length > 0 ? (
        <ChooseWorkerTable workers={filteredWorkers} onSelect={handleSelectWorker} selectedWorkers={selectedWorkers} getEntityName={getEntityName} getUnitName={getUnitName} />
        ) : <Text fontFamily={FontFamily.MontserratRegular}>
          {t("CompanyStructure.NoOtherWorkersFound")}
        </Text>
      }
        <ButtonContainer style={{ marginTop: 20, alignSelf: "center" }}>
          <ButtonStyled
            disabled={selectedWorkers?.length === 0}
            onClick={() => {
              addExistingWorkersToCurrentUnit()
            }}
            backgroundColor={Color.ModernGreen}
          >
            {t("General.Add")}
          </ButtonStyled>

          <LoadingContainer>
            {isLoading && (
              <CircularProgress />
            )}
          </LoadingContainer>
        </ButtonContainer>
      </EditUnitModalBody>
    </Modal>
  );
};

export default ChooseExistingWorkersModal;
