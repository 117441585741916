import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { H3, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import moment from "moment";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import {
  CalendarGridContainer,
  CircularProgressWrapper,
  GigHeader,
  ModalBody,
  WeekDay,
} from "../../calendar/weeklyCalendar.styled";
import { CalendarGig } from "../../calendar/calendarGig.component";
import { CircularProgress, Modal } from "@material-ui/core";
import { SaveGigForm } from "../../saveGig/saveGigForm/saveGigForm.component";
import {
  CalendarGrid,
  CalendarHeaderGrid,
  CalenderDot,
  HeaderContainer,
} from "./dashboardWeek.styled";

type CurrentDate = {
  date: moment.Moment;
  weekDay: string;
};
export const DashboardWeek: React.FC = () => {
  const [companyState] = useCompanyStore();
  const [calendarState, calendarActions] = useCalendarStore();

  const { t } = useTranslation();
  const weekdayList = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];
  const today = moment();
  const [currentDates, setCurrentDates] = useState<CurrentDate[]>([]);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);

  useEffect(() => {
    calendarActions.updateWeeklyCalendarContext(
      t,
      companyState,
      "UPDATE_OVERVIEW_WEEK_CALENDAR"
    );
  }, [companyState.company]);

  useEffect(() => {
    let firstDayOfWeek = moment().startOf("isoWeek");
    let dates: CurrentDate[] = [];
    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(firstDayOfWeek).add(x, "days"),
        weekDay: weekdayList[x],
      });
    }
    setCurrentDates(dates);
  }, []);

  return (
    <>
      <>
        <HeaderContainer>
          <CalendarHeaderGrid>
            <GigHeader />
            {currentDates.map((date: CurrentDate) => (
              <WeekDay
                key={date.weekDay}
                future={
                  date.date >= moment().set({ hour: 0, minutes: 0, seconds: 0 })
                    ? true
                    : false
                }
                today={
                  date.date.format("DD-MM-YYYY") === today.format("DD-MM-YYYY")
                    ? true
                    : false
                }
              >
                <Text
                  fontSize={FontSize.Small}
                  color={Color.MidnightBlue}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {date.weekDay}
                </Text>
                <Text fontSize={FontSize.Small} color={Color.MidnightBlue}>
                  {moment(date.date).format("D/M")}
                </Text>
                {date.date.format("DD-MM-YYYY") ===
                  today.format("DD-MM-YYYY") && <CalenderDot />}
              </WeekDay>
            ))}
          </CalendarHeaderGrid>
        </HeaderContainer>

        {!calendarState.isLoading ? (
          <CalendarGridContainer>
            <CalendarGrid>
              {calendarState.overviewWeek.map(
                (gigData, gigDataIndex: number) => {
                  return (
                    <CalendarGig
                      key={gigData.id}
                      gigData={gigData}
                      gigDataIndex={gigDataIndex}
                      compactView
                      dashboard={true}
                    />
                  );
                }
              )}
            </CalendarGrid>
            {calendarState.overviewWeek.length === 0 && (
              <Text
                style={{ textAlign: "center" }}
                fontSize={FontSize.Standard}
                color={Color.SeaBlue600}
                fontFamily={FontFamily.MontserratSemiBold}
              >
                {t("CalendarCompany.NoShiftsThisWeek")}
              </Text>
            )}
          </CalendarGridContainer>
        ) : (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        )}

        <Modal
          open={showCreateGigModal}
          onClose={() => {
            setShowCreateGigModal(false);
          }}
        >
          <ModalBody>
            <H3>{t("CalendarCompany.CreateGig")}</H3>
            <SaveGigForm setShowCreateGigModal={setShowCreateGigModal} />
          </ModalBody>
        </Modal>
      </>
      {calendarState.isLoading && <LoaderProgress />}
    </>
  );
};
