import React from "react";
import { useTranslation } from "react-i18next";
import { H4 } from "components/Typography/text.styled";
import {
  InputContainer,
  LabelStyled,
  TextInput,
} from "components/form/inputs/inputs.styled";
import { FontFamily, FontSize } from "config/font";
import {
  CheckboxText,
  FormStyled,
  CheckBoxContainer,
  SettingsStampClockGrid,
  TimeInputContainer,
} from "./companyStampClock.styled";
import { Checkbox } from "@material-ui/core";
import { TimeInput, ErrorMessage } from "../../saveGig/shift/shiftForm.styled";
import moment from "moment";
import { StampClockTemplateFormData } from "model/Company";

type Props = {
  formData: StampClockTemplateFormData;
  setFormData: React.Dispatch<React.SetStateAction<StampClockTemplateFormData>>;
  HHMMRegex: any;
};

export const AutoGenerateShiftTemplateForm: React.FC<Props> = ({
  formData,
  setFormData,
  HHMMRegex
}) => {
  const { t } = useTranslation();

  return (
    <>
      {formData.autoGenerateShiftSettingActive && (
        <>
          <H4>{t("ProfileCompany.TemplateSettings")}</H4>
          <SettingsStampClockGrid>
            <FormStyled>
              <InputContainer>
                <LabelStyled>{`${t("ProfileCompany.Role")}*`}</LabelStyled>
                <TextInput
                  required
                  disabled
                  fontSize={FontSize.Large}
                  type="text"
                  placeholder={t("ProfileCompany.AutoGenerated")}
                  value={formData.role}
                />
              </InputContainer>
              <TimeInputContainer>
                <LabelStyled>{`${t("ProfileCompany.EarliestStartTime")}*`}</LabelStyled>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="startTime"
                  type="time"
                  value={formData.startTime}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      startTime: e.target.value,
                    }))
                  }
                />
                {!HHMMRegex.test(formData.startTime) && (
                  <ErrorMessage>
                    {t("GigsCompany.EnterAValidTime")}
                  </ErrorMessage>
                )}
              </TimeInputContainer>
              <TimeInputContainer>
                <LabelStyled>{`${t("ProfileCompany.CommonEndTime")}*`}</LabelStyled>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="endTime"
                  type="time"
                  value={formData.endTime}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      endTime: e.target.value,
                    }))
                  }
                />
                {(!HHMMRegex.test(formData.endTime) ||
                  !moment(formData.endTime, "HH:mm").isAfter(
                    moment(formData.startTime, "HH:mm")
                  )) && (
                  <ErrorMessage>
                    {t("GigsCompany.EnterAValidTime")}
                  </ErrorMessage>
                )}
              </TimeInputContainer>
              <InputContainer>
                <LabelStyled>{`${t("ProfileCompany.BreakTime")}*`}</LabelStyled>
                <TextInput
                  required
                  fontSize={FontSize.Large}
                  type="number"
                  name="breakTime"
                  placeholder="60"
                  value={formData.breakTime}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      breakTime: Number(e.target.value),
                    }))
                  }
                />
              </InputContainer>
              <InputContainer>
                <LabelStyled>{`${t(
                  "ProfileCompany.HourlyRate"
                )}*`}</LabelStyled>
                <TextInput
                  required
                  fontSize={FontSize.Large}
                  type="number"
                  placeholder="170"
                  value={formData.hourlyRate}
                  name="hourlyRate"
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      hourlyRate: Number(e.target.value),
                    }))
                  }
                />
              </InputContainer>
              <InputContainer>
                <LabelStyled>{t("ProfileCompany.PaymentSolution")}</LabelStyled>

                <CheckBoxContainer>
                  <Checkbox
                    checked={formData.paymentType === "SimflexityPay"}
                    onChange={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        paymentType: "SimflexityPay",
                      }))
                    }
                  />
                  <CheckboxText>
                    {t("ProfileCompany.SimflexityPay")}
                  </CheckboxText>
                </CheckBoxContainer>

                <CheckBoxContainer>
                  <Checkbox
                    checked={formData.paymentType === "None"}
                    onChange={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        paymentType: "None",
                      }))
                    }
                  />
                  <CheckboxText>
                    {t("ProfileCompany.OwnSalaryManagement")}
                  </CheckboxText>
                </CheckBoxContainer>
              </InputContainer>
            </FormStyled>
          </SettingsStampClockGrid>
        </>
      )}
    </>
  );
};
