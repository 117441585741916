import React, { useEffect, useState } from "react";
import { LocalDate, Month } from "@js-joda/core";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import {
  MonthSelectContainer,
  ArrowSign,
  WorkerTimeReportContainer,
  PersonnelCarrierWrapper,
  HeadCell,
  PersonnelCarrierGrid,
  PersonnelCarrierGridBody,
  Cell,
  IconContainer,
  TextRowPersonnelCarrier,
} from "./monthlyOverview.styled";
import { Text, H4 } from "components/Typography/text.styled";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { CustomIcon } from "components/icon/customIcon.component";
import { PersonnelCarrierModal } from "./personnelCarrierModal";
import { TimeReportResolution, TimeReportStatus } from "model/TimeReport";
import { isWithinXMinutes } from "utils/utils";
import { CompanyWorkerDto } from "model/Company";
import { fetchWorker } from "web-apps/company/utils/utils";
import { WorkerDetail } from "./workers/workerDetail.component";

export const PersonnelCarrierOverview: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState<LocalDate>(LocalDate.now());
  const year = currentDate.year();
  const [chosenMonth, setChosenMonth] = useState<Month>(
    LocalDate.now().month()
  );
  const [timeReportState, , timeReportDispatch] = useTimeReportStore();
  const [companyState] = useCompanyStore();
  const [showOnlyDaysWithGigs, setShowOnlyDaysWithGigs] = useState(false);
  const [showTodaysGigs, setShowTodaysGigs] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showTodaysGigsMarked, setShowTodaysGigsMarked] = useState(false);
  const [showOnlyDaysWithGigsMarked, setShowOnlyDaysWithGigsMarked] =
    useState(false);
  const [noTimeReports, setNoTimeReports] = useState(false);

  const getDatesInMonth = (chosenMonth: Month, year: number) => {
    let dates = [];

    const leapYear = new Date(year, 1, 29).getMonth() === 1;
    const daysInMonth = Month.of(new Date().getMonth() + 1).equals(chosenMonth)
      ? new Date().getDate()
      : chosenMonth.length(leapYear);

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, chosenMonth.value() - 1, i);
      dates.push(date.toISOString().split("T")[0]);
    }

    return dates;
  };
  const [monthsDates, setMonthsDates] = useState<string[]>([]);

  useEffect(() => {
    GetMonthlyTimeReports();
  }, [monthsDates]);

  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [worker, setWorker] = useState<CompanyWorkerDto>();

  useEffect(() => {
    setWorkerModalOpen(true);
  }, [worker]);

  const GetMonthlyTimeReports = async () => {
    setIsLoading(true);
    const companyId = companyState.company?.id;
    if (companyId) {
      let monthlyList: any[] = [];
      let day;

      if (Month.of(new Date().getMonth() + 1).equals(chosenMonth)) {
        day = new Date().getDate();
      } else {
        const leapYear = new Date(year, 1, 29).getMonth() === 1;
        day = chosenMonth.length(leapYear);
      }

      try {
        const res = await Api().company.timeReport.getAllWorkerPersonnelCarrier(
          companyId,
          currentDate.year(),
          currentDate.monthValue(),
          day
        );

        monthlyList = res.data;
        setIsLoading(false);
        timeReportDispatch({
          type: "UPDATE_MONTHLY_CARRIER_TIME_REPORTS",
          payload: monthlyList,
        });
      } catch (err) {
        console.log(err, "ERROR FETCHING MONTHLY TIME REPORTS", err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const reportExists = checkForFilter(currentDate.toString());
    !reportExists && showTodaysGigs
      ? setNoTimeReports(true)
      : setNoTimeReports(false);
  }, [showTodaysGigs, timeReportState]);

  useEffect(() => {
    const reportExists =
      timeReportState.personnelCarrierTimeReports.length !== 0;

    !reportExists && showOnlyDaysWithGigs
      ? setNoTimeReports(true)
      : setNoTimeReports(false);
  }, [showOnlyDaysWithGigs]);

  const checkForFilter = (date: string | number | Date) => {
    return timeReportState.personnelCarrierTimeReports.some(
      (workerWithReports) => {
        return workerWithReports.stamps.some((report) => {
          const reportDate = new Date(report.startTime)
            .toISOString()
            .split("T")[0];
          const targetDate = new Date(date).toISOString().split("T")[0];
          return (
            reportDate === targetDate &&
            report.resolution !== TimeReportResolution.SICKLEAVE &&
            report.resolution !== TimeReportResolution.SICKLEAVECHILD &&
            report.resolution !== TimeReportResolution.VACATION &&
            report.resolution !== TimeReportResolution.PARENTALLEAVE &&
            report.resolution !== TimeReportResolution.LEAVEOFABSENCE &&
            report.resolution !== TimeReportResolution.OVERTIMECOMPENSATION &&
            report.resolution !== TimeReportResolution.FUNERALLEAVE &&
            report.resolution !== TimeReportResolution.MILITARYSERVICE &&
            report.resolution !== TimeReportResolution.WITHDRAWN &&
            report.resolution !== TimeReportResolution.CANCELLED
          );
        });
      }
    );
  };

  useEffect(() => {
    setMonthsDates(getDatesInMonth(currentDate.month(), currentDate.year()));
  }, [chosenMonth]);

  useEffect(() => {
    setChosenMonth(currentDate.month());
  }, [currentDate]);

  useEffect(() => {
    if (showTodaysGigs) {
      setCurrentDate(currentDate.withMonth(LocalDate.now().month()));
    }
  }, [showTodaysGigs]);

  const checkIfThereAreRejectedOrClosedTimeReports = (status: string) => {
    return timeReportState.personnelCarrierTimeReports.some(
      (workerWithReports) => {
        return workerWithReports.stamps.some((report) => {
          return report.status == status;
        });
      }
    );
  };

  return (
    <>
      <PersonnelCarrierWrapper>
        <Text color={Color.White}>
          {t("CompanyTimeReport.CompanyName")}:{" "}
          {companyState.company?.companyName}
        </Text>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {t("CompanyTimeReport.OrganizationNumber")}:{" "}
          {companyState.company?.registrationNumber}
        </Text>
      </PersonnelCarrierWrapper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MonthSelectContainer>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: any) => prevState.minusMonths(1));
            }}
          >
            &#60;
          </ArrowSign>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
          >
            {t(`CompanyTimeReport.${chosenMonth}`) + " " + currentDate.year()}
          </Text>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: any) => prevState.plusMonths(1));
            }}
          >
            &#62;
          </ArrowSign>
        </MonthSelectContainer>
      </div>
      <IconContainer>
        <CustomIcon
          name="filter"
          size="28px"
          color={Color.MidnightBlue}
          onClick={() => setShowFilterModal(true)}
        />
      </IconContainer>
      {noTimeReports && (
        <Text
          fontSize={FontSize.Large}
          fontFamily={FontFamily.MontserratSemiBold}
          align="center"
        >
          {t("CompanyTimeReport.NoTimeReportsToDisplay")}
        </Text>
      )}
      {monthsDates.map((date) => {
        const allFilters =
          showTodaysGigs &&
          showOnlyDaysWithGigs &&
          date == currentDate.toString() &&
          checkForFilter(currentDate.toString());

        const noFilters = !showOnlyDaysWithGigs && !showTodaysGigs;

        const showTodaysGigsFilter =
          showTodaysGigs &&
          date == currentDate.toString() &&
          !showOnlyDaysWithGigs &&
          !noTimeReports;

        const showOnlyDaysWithGigsFilter =
          showOnlyDaysWithGigs && checkForFilter(date) && !showTodaysGigs;

        return noFilters ||
          showTodaysGigsFilter ||
          showOnlyDaysWithGigsFilter ||
          allFilters ? (
          <WorkerTimeReportContainer>
            <H4>{date}</H4>
            <PersonnelCarrierGrid>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.WorkerName")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.SocialSecurityNumber")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.StartTime")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.EndTime")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.Break")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.CheckIn")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.CheckOut")}
                </Text>
              </HeadCell>
              <HeadCell>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("CompanyTimeReport.GigName")}
                </Text>
              </HeadCell>
              <HeadCell></HeadCell>
            </PersonnelCarrierGrid>
            <PersonnelCarrierGridBody>
              {!isLoading &&
                timeReportState.personnelCarrierTimeReports.map(
                  (workerWithReports) => {
                    return workerWithReports.stamps.map((report) => {
                      if (
                        new Date(report.startTime)
                          .toISOString()
                          .split("T")[0] ===
                          new Date(date).toISOString().split("T")[0] &&
                        report.resolution !== TimeReportResolution.SICKLEAVE &&
                        report.resolution !==
                          TimeReportResolution.SICKLEAVECHILD &&
                        report.resolution !== TimeReportResolution.VACATION &&
                        report.resolution !==
                          TimeReportResolution.PARENTALLEAVE &&
                        report.resolution !==
                          TimeReportResolution.LEAVEOFABSENCE &&
                        report.resolution !==
                          TimeReportResolution.OVERTIMECOMPENSATION &&
                        report.resolution !==
                          TimeReportResolution.FUNERALLEAVE &&
                        report.resolution !==
                          TimeReportResolution.MILITARYSERVICE &&
                        report.resolution !== TimeReportResolution.WITHDRAWN &&
                        report.resolution !== TimeReportResolution.CANCELLED &&
                        report.resolution !== TimeReportResolution.OTHER &&
                        report.resolution !== TimeReportResolution.STUDYLEAVE &&
                        report.resolution !==
                          TimeReportResolution.LEAVEUNIONASSIGNEMENTS
                      ) {
                        return (
                          <React.Fragment key={report.timeReportId}>
                            <Cell>
                              <Text
                                fontSize={FontSize.Large}
                                onClick={() => {
                                  fetchWorker(
                                    workerWithReports.workerId,
                                    companyState
                                  )?.then((worker) => setWorker(worker.data));
                                }}
                              >
                                {workerWithReports.firstName +
                                  " " +
                                  workerWithReports.lastName}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text fontSize={FontSize.Large}>
                                {workerWithReports.ssn}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text fontSize={FontSize.Large}>
                                {new Date(report.startTime).toLocaleTimeString(
                                  "sv-SE",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text fontSize={FontSize.Large}>
                                {new Date(report.endTime).toLocaleTimeString(
                                  "sv-SE",
                                  { hour: "2-digit", minute: "2-digit" }
                                )}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text fontSize={FontSize.Large}>
                                {report.breakTimeMinutes + " min"}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text
                                fontSize={FontSize.Large}
                                color={
                                  report.checkInTime == null ||
                                  isWithinXMinutes(
                                    new Date(report.startTime),
                                    new Date(report.checkInTime),
                                    15
                                  )
                                    ? undefined
                                    : Color.Destructive
                                }
                              >
                                {report.checkInTime
                                  ? new Date(
                                      report.checkInTime
                                    ).toLocaleTimeString("sv-SE", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  : "-"}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text
                                fontSize={FontSize.Large}
                                color={
                                  report.checkOutTime == null ||
                                  isWithinXMinutes(
                                    new Date(report.endTime),
                                    new Date(report.checkOutTime),
                                    15
                                  )
                                    ? undefined
                                    : Color.Destructive
                                }
                              >
                                {report.checkOutTime
                                  ? new Date(
                                      report.checkOutTime
                                    ).toLocaleTimeString("sv-SE", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  : "-"}
                              </Text>
                            </Cell>
                            <Cell>
                              <Text fontSize={FontSize.Large}>
                                {report.gigRole}
                              </Text>
                            </Cell>
                            <Cell>
                              {report.status == TimeReportStatus.CLOSED && (
                                <CustomIcon
                                  name={"checkmark"}
                                  color={Color.BurntSienna}
                                  padding="5px"
                                />
                              )}
                              {report.status == TimeReportStatus.REJECTED && (
                                <CustomIcon
                                  name={"cross"}
                                  color={Color.Destructive}
                                  padding="5px"
                                />
                              )}
                            </Cell>
                          </React.Fragment>
                        );
                      }
                    });
                  }
                )}
            </PersonnelCarrierGridBody>
          </WorkerTimeReportContainer>
        ) : (
          <></>
        );
      })}
      {checkIfThereAreRejectedOrClosedTimeReports(TimeReportStatus.CLOSED) && (
        <TextRowPersonnelCarrier>
          <CustomIcon
            name={"checkmark"}
            color={Color.BurntSienna}
            padding="5px"
            marginTop="8px"
          />
          <Text>{"Tidrapporten är stängd och godkänd"}</Text>
        </TextRowPersonnelCarrier>
      )}
      {checkIfThereAreRejectedOrClosedTimeReports(
        TimeReportStatus.REJECTED
      ) && (
        <TextRowPersonnelCarrier>
          <CustomIcon
            name={"cross"}
            color={Color.Destructive}
            padding="5px"
            marginTop="8px"
          />
          <Text>{"Tidrapporten är avvisad"}</Text>
        </TextRowPersonnelCarrier>
      )}
      <PersonnelCarrierModal
        showFilterModal={showFilterModal}
        setShowFilterModal={setShowFilterModal}
        setShowOnlyDaysWithGigs={setShowOnlyDaysWithGigs}
        setShowTodaysGigs={setShowTodaysGigs}
        setShowTodaysGigsMarked={setShowTodaysGigsMarked}
        showTodaysGigsMarked={showTodaysGigsMarked}
        showOnlyDaysWithGigsMarked={showOnlyDaysWithGigsMarked}
        setShowOnlyDaysWithGigsMarked={setShowOnlyDaysWithGigsMarked}
      />

      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
        />
      )}
    </>
  );
};
