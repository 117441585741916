import { useTranslation } from 'react-i18next';
import { HeadCell, TimeReportsGrid } from '../monthlyOverview.styled';
import { FontFamily, FontSize } from 'config/font';
import { Text } from "components/Typography/text.styled";

const TimeReportsHeader = () => {
  const { t } = useTranslation()

  return (
    <TimeReportsGrid>
      <HeadCell>
        <Text fontSize={FontSize.Large} fontFamily={FontFamily.MontserratSemiBold}>
          {t("CompanyTimeReport.Date")}
        </Text>
      </HeadCell>
      <HeadCell>
        <Text fontSize={FontSize.Large} fontFamily={FontFamily.MontserratSemiBold}>
          {t("CompanyTimeReport.Shift")}
        </Text>
      </HeadCell>
      <HeadCell>
        <Text
          fontSize={FontSize.Large}
          fontFamily={FontFamily.MontserratSemiBold}
          style={{ marginLeft: "8px" }}
        >
          {t("CompanyTimeReport.Role")}
        </Text>
      </HeadCell>
      <HeadCell>
        <Text fontSize={FontSize.Large} fontFamily={FontFamily.MontserratSemiBold}>
          {t("CompanyTimeReport.Break")}
        </Text>
      </HeadCell>
      <HeadCell>
        <Text fontSize={FontSize.Large} fontFamily={FontFamily.MontserratSemiBold}>
          {t("CompanyTimeReport.Time")}
        </Text>
      </HeadCell>
      <HeadCell>
        <Text fontSize={FontSize.Large} fontFamily={FontFamily.MontserratSemiBold}>
          {t("CompanyTimeReport.Status")}
        </Text>
      </HeadCell>
      <HeadCell>
        <Text fontSize={FontSize.Large} fontFamily={FontFamily.MontserratSemiBold}>
          {t("CompanyTimeReport.Resolution")}
        </Text>
      </HeadCell>
    </TimeReportsGrid>
  );
};

export default TimeReportsHeader;
