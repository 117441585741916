import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "../../../stores/companyStore/companyStore";
import { CompanyFavoriteDto } from "../../../../../model/Favorite";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { FavoriteListItem } from "./favoriteListItem.component";
import se from "services/translation/lang/swedishCountries.json";
import {
  CustomLink,
  Text,
} from "../../../../../components/Typography/text.styled";
import { NoFavoritesContainer, TopLinksContainer } from "./favoriteList.styled";
import { useHistory } from "react-router-dom";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import {
  PhoneInputWrapper,
  SettingsModalBody,
} from "../../workgroup/workgroup.styled";
import PhoneInput from "react-phone-number-input";
import {
  HourlyRateContainer,
  NumberInputContainer,
} from "../../saveGig/hourlyRate/hourlyRate.styled";
import { NumberInput } from "components/form/inputs/inputs.styled";
import { Api } from "services/api/api.service";
import { useAlertStore } from "stores/alertStore/alertStore";

export const FavoriteList = () => {
  const [companyState, , companyDispatch] = useCompanyStore();
  const { t } = useTranslation();
  const history = useHistory();
  const [addFavoriteModal, setAddFavoriteModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [hourlyRate, setHourlyRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [, , alertDispatch] = useAlertStore();

  const addFavorite = () => {
    if (companyState.company?.id) {
      setIsLoading(true);
      const data = {
        phoneNumber: phoneNumber.substring(1),
        ...(hourlyRate && {
          hourlyRate: hourlyRate,
        }),
      };
      Api()
        .company.addCompanyFavoritesByNumber(companyState.company?.id, data)
        .then((res) => {
          companyDispatch({ type: "ADD_COMPANY_FAVORITE", payload: res.data });
          setIsLoading(false);
          setAddFavoriteModal(false);
          setPhoneNumber("");
          setHourlyRate(0);
        })
        .catch((err) => {
          setIsLoading(false);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message:
                err.response.data == "Worker Not found"
                  ? t("CompanyFavorite.PersonCouldNotBeFound")
                  : t("CompanyFavorite.WorkerHasNoSsn"),
            },
          });
        });
    }
  };

  return (
    <div>
      <TopLinksContainer>
        <CustomLink
          onClick={() => history.goBack()}
          color={Color.Destructive}
          text-align="right"
        >
          {t("General.Back")}
        </CustomLink>
        <ButtonStyled onClick={() => setAddFavoriteModal(true)}>
          {t("CompanyFavorite.AddFavorite")}
        </ButtonStyled>
      </TopLinksContainer>
      {companyState.favorites.map((favorite: CompanyFavoriteDto, index) => (
        <FavoriteListItem favorite={favorite} key={index} />
      ))}
      {!companyState.favorites.length && (
        <NoFavoritesContainer>
          <CustomIcon
            name="favorites-inactive"
            size="48px"
            color={Color.BurntSienna}
            padding="10px"
          />
          <Text fontSize={FontSize.H4} color={Color.SeaBlue600} align="center">
            {t("CompanyFavorite.NoFavorites")}
          </Text>
        </NoFavoritesContainer>
      )}
      <Modal
        open={addFavoriteModal}
        onClose={() => {
          setAddFavoriteModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SettingsModalBody>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyFavorite.AddGiggerToFavorites")}
          </Text>
          <Text
            align="center"
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratRegular}
          >
            {t("CompanyFavorite.FavortieAGiggerByEnteringTheirPhoneNumber")}
          </Text>
          <PhoneInputWrapper>
            <PhoneInput
              withCountryCallingCode
              placeholder="Ex. 076-123 45 67"
              defaultCountry="SE"
              value={phoneNumber}
              onChange={setPhoneNumber}
              labels={se}
              style={{ border: "none" }}
            />
          </PhoneInputWrapper>
          <>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              color={Color.SeaBlue600}
              fontSize={FontSize.Small}
              style={{ marginTop: 0, textAlign: "center" }}
            >
              {`(${t("CompanyGroup.OnlyYourCompanyCanSeeThisInfo")})`}
            </Text>
            <HourlyRateContainer>
              <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
                {t("GigsCompany.HourlyRate")}
              </Text>
              <NumberInputContainer>
                <NumberInput
                  type="number"
                  name="minRate"
                  color={
                    hourlyRate === 0 ? Color.SeaBlue200 : Color.MidnightBlue
                  }
                  value={hourlyRate || ""}
                  onChange={(e) => {
                    setHourlyRate(parseInt(e.currentTarget.value) || 0);
                  }}
                />
              </NumberInputContainer>
            </HourlyRateContainer>
            {hourlyRate !== 0 ? (
              <div style={{ marginLeft: "15px" }}>
                <CustomLink
                  fontSize={FontSize.Small}
                  color={Color.Destructive}
                  onClick={() => setHourlyRate(0)}
                >
                  {t("CompanyGroup.RemoveDefaultRate")}
                </CustomLink>
              </div>
            ) : (
              <Text
                fontSize={FontSize.Small}
                color={Color.SeaBlue500}
                style={{ marginLeft: "15px" }}
              >
                {t("CompanyGroup.NoDefaultRate")}
              </Text>
            )}
          </>
          <ButtonStyled
            onClick={() => addFavorite()}
            style={{ marginBottom: "10px" }}
            disabled={isLoading || !phoneNumber}
            backgroundColor={Color.BurntSienna}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              t("General.Add")
            )}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.SeaBlue400}
            onClick={() => {
              setAddFavoriteModal(false);
            }}
          >
            {t("CompanyGroup.Close")}
          </ButtonStyled>
        </SettingsModalBody>
      </Modal>
    </div>
  );
};
