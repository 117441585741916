import { Color } from "config/colors";
import { GigCosts } from "model/Gig";
import {
  TimeReportDto,
  TimeReportResolution,
  WorkerWithTimeReports,
} from "model/TimeReport";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addLeadingZero } from "utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { getCostsTimeReports } from "web-apps/company/utils/utils";
import {
  TimeReportsGridFooter
} from "./monthlyOverview.styled";
import { TimeReportFooterHeader } from "./workerGig/workerGigTimeReportFooterHeader.component";
import TotalCostFooterRow from "./workerGig/workerGigTimeReportFooterTotalCostRow.component";
import TimeReportsHeader from "./workerGig/workerGigTimeReportHeader.component";
import TimeReportRow from "./workerGig/workerGigTimeReportRow.compontent";
import { calculateTimeReportDetails } from "./workerGig/workerGigUtils";

type Props = {
  workerWithTimeReports: WorkerWithTimeReports;
  setChosenTimeReportWithWorker: React.Dispatch<
    React.SetStateAction<TimeReportDto | null>
  >;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const WorkerGigTimeReports: React.FC<Props> = ({
  workerWithTimeReports,
  setChosenTimeReportWithWorker,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [totalCost, setTotalCost] = useState<GigCosts>(emptyCosts);
  const [totalCostApproved, setTotalCostApproved] =
    useState<GigCosts>(emptyCosts);

  const reportDetails = calculateTimeReportDetails(workerWithTimeReports.timeReports)

  const timeTotalString = Math.floor(reportDetails.totalTimeMin / 60) + "h " + (reportDetails.totalTimeMin % 60) + "min";

  const timeTotalApprovedString = Math.floor(reportDetails.totalTimeMinApproved / 60) +
    "h " +
    (reportDetails.totalTimeMinApproved % 60) +
    "min";

  useEffect(() => {
    if (companyState.company?.id) {
      const approved = workerWithTimeReports.timeReports.filter(
        (timeReport) =>
          timeReport.resolution === TimeReportResolution.PAID ||
          timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
          timeReport.resolution === TimeReportResolution.PAYONDEMAND
      );

      getCostsTimeReports(
        workerWithTimeReports.timeReports,
        companyState.company.id
      ).then((costs) => setTotalCost(costs));
      getCostsTimeReports(approved, companyState.company.id).then((costs) =>
        setTotalCostApproved(costs)
      );
    }
  }, [workerWithTimeReports, companyState.company?.id]);

  return (
    <>
      <TimeReportsHeader />

      {reportDetails.details.map((calculatedDetails, timeReportIndex) => {
        
        const timeReport = workerWithTimeReports.timeReports[timeReportIndex];

        return (
          <TimeReportRow key={timeReportIndex} timeReport={timeReport} calculatedDetails={calculatedDetails} setChosenTimeReportWithWorker={setChosenTimeReportWithWorker} setModalOpen={setModalOpen} addLeadingZero={addLeadingZero} />
        );
      })}

      <TimeReportsGridFooter style={{ backgroundColor: Color.MidnightBlue }}>
        <TimeReportFooterHeader />

        <TotalCostFooterRow rowTitle={`${t("CompanyTimeReport.Scheduled")} ${t("CompanyTimeReport.Time")}`} timeTotalString={timeTotalString} grossSalary={totalCost.grossSalary} obAmount={totalCost.obAmount} socialFee={totalCost.socialFee} simflexityFee={totalCost.simflexityFee} totalCost={totalCost.totalCost} />

        <TotalCostFooterRow rowTitle={`${t("CompanyTimeReport.Approved")} & ${t("CompanyTimeReport.Closed")}`} timeTotalString={timeTotalApprovedString} grossSalary={totalCostApproved.grossSalary} obAmount={totalCostApproved.obAmount} socialFee={totalCostApproved.socialFee} simflexityFee={totalCostApproved.simflexityFee} totalCost={totalCostApproved.totalCost} />
      </TimeReportsGridFooter>
    </>
  );
};
