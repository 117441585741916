import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Instant, LocalDateTime } from "@js-joda/core";
import { calculateBreakTime, getTimeString, getWorkHours } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { ShiftDto } from "model/Shift";
import { Text } from "components/Typography/text.styled";
import { ShiftList, SpaceBetween } from "./gigs.styled";
import moment from "moment";
import { CompanyGigDto } from "model/Gig";

type Props = {
  gigData: CompanyGigDto;
};

export const GigShiftList: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const endTime = gigData.shifts?.[gigData.shifts.length - 1]?.endTime ?? "";
  const [visibleShift, setVisibleShift] = useState<ShiftDto | null>();

  useEffect(() => {
    if (Date.parse(endTime) > Date.now()) {
      const nextShift = gigData.shifts.find(
        (shift) => Date.parse(shift.endTime) > Date.now()
      );
      setVisibleShift(nextShift || null);
    }
  }, [gigData]);

  return (
    <>
      <ShiftList>
        {visibleShift && (
          <>
            <SpaceBetween>
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.MidnightBlue}
              >
                {t(
                  `WeekDays.${LocalDateTime.ofInstant(
                    Instant.parse(visibleShift.startTime)
                  )
                    .dayOfWeek()
                    .name()}SHORT`
                )}
              </Text>
              <Text
                fontSize={FontSize.Standard}
                fontFamily={FontFamily.MontserratSemiBold}
              >{`${getTimeString(visibleShift.startTime)} - ${getTimeString(
                visibleShift.endTime
              )}`}</Text>
            </SpaceBetween>
            <SpaceBetween>
              <Text
                color={Color.ModernGreen}
                fontSize={FontSize.Small}
                fontFamily={FontFamily.MontserratSemiBold}
                style={{ margin: 0 }}
              >
                {Date.now() > Date.parse(visibleShift.startTime) &&
                Date.now() < Date.parse(visibleShift.endTime)
                  ? t("GigsCompany.Ongoing")
                  : t("GigsCompany.ComingUp")}
              </Text>
              <Text
                fontSize={FontSize.Small}
                fontFamily={FontFamily.MontserratRegular}
                color={Color.SeaBlue600}
                style={{ margin: 0 }}
              >
                {getWorkHours(t, visibleShift)}
              </Text>
            </SpaceBetween>
            <Text
              fontSize={FontSize.Small}
              fontFamily={FontFamily.MontserratRegular}
              color={Color.SeaBlue600}
              style={{ margin: 0, textAlign: "right" }}
            >
              {(visibleShift.breakStartTime && visibleShift.breakEndTime
                ? calculateBreakTime(
                    visibleShift.breakStartTime,
                    visibleShift.breakEndTime,
                    moment(
                      LocalDateTime.ofInstant(
                        Instant.parse(visibleShift.startTime)
                      ).toString()
                    ),
                    moment(
                      LocalDateTime.ofInstant(
                        Instant.parse(visibleShift.endTime)
                      ).toString()
                    )
                  )
                : 0) + t("GigsWorker.MinutesUnpaidBreak")}
            </Text>
          </>
        )}
      </ShiftList>
    </>
  );
};
