import { Color } from "config/colors";
import { FontFamily } from "config/font";
import styled from "styled-components";

export const SettingsStampClockGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const FormStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 10px;
  }

  h4 {
    align-self: flex-start;
  }

  a {
    margin: 0 0 20px 0;
  }

  textarea {
    margin: 0;
    max-height: 300px;
    resize: none;
  }

  @media (min-width: 768px) {
    grid-column: 3/4;
  }
`;

export const CheckboxText = styled.p`
  font-size: 14px;
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratSemiBold};
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  p {
    &:hover {
      cursor: pointer;
      color: ${Color.SeaBlue400};
    }
  }
  checkbox .checked {
    color: ${Color.SeaBlue400};
  }
`;

export const TimeInputContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  span {
    margin-bottom: 10px;
  }
`;

export const DividerLine = styled.hr`
  border: none;
  border-top: 2px solid ${Color.Disabled};
  margin: 25px 0;
  align-self: center;
  width: 100%;
`;

export const LocalStampClockContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px;
`;
