import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomLink, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgress } from "@material-ui/core";
import {
  DashBoardCell,
  DashboardCellContent,
  GiggerContainer,
  TitleContainer,
  VerticalDivider,
  IconsContainer,
  IconCircularContainer,
  RatingCell,
  GigInfoContainer,
  RatingContainer,
  RatingRow,
} from "../dashboard.styled";
import { Rating } from "model/Company";
import { CustomIcon } from "components/icon/customIcon.component";
import moment from "moment";

export const DashboardLatestRatings: React.FC = () => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [latestRatings, setLatestRatings] = useState<Rating[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState<number | undefined>();

  const getLatestRatings = () => {
    const companyId = companyState.company?.id;
    setIsLoading(true);
    if (companyId) {
      Api()
        .company.getLatestGigRatings(companyId)
        .then((res) => {
          setLatestRatings(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Error fetching latest ratings", err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getLatestRatings();
  }, [companyState.company?.id]);

  const isSameDate = (rating: any) => {
    return (
      moment(rating.gigEndTime).format("DD/MM") ==
      moment(rating.gigStartTime).format("DD/MM")
    );
  };

  return (
    <>
      <DashBoardCell>
        <TitleContainer>
          <CustomLink
            color={Color.MidnightBlue}
            fontSize={FontSize.H3}
            fontFamily={FontFamily.MontserratRegular}
          >
            {t("General.StaffSatisfaction")}
          </CustomLink>
        </TitleContainer>
        <DashboardCellContent>
          {!isLoading &&
            latestRatings &&
            latestRatings.map((rating, index) => {
              return (
                <>
                  <RatingCell>
                    <GigInfoContainer>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratSemiBold}
                        fontSize={FontSize.H4}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {`${rating.firstName} ${rating.lastName}`}
                      </Text>
                      <Text color={Color.SeaBlue600}>{rating.gigRole}</Text>
                    </GigInfoContainer>
                    <VerticalDivider />
                    <GiggerContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("General.Gig")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${moment(rating.gigStartTime).format("DD/MM")} ${
                        !isSameDate(rating)
                          ? " - " + moment(rating.gigEndTime).format("DD/MM")
                          : ""
                      }`}</Text>
                    </GiggerContainer>
                    <RatingContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >{`${t("General.StaffSatisfaction")}`}</Text>
                      <RatingRow>
                        <Text>{rating.rating + '/5'}</Text>
                        <CustomIcon
                          style={{ zIndex: 1000 }}
                          name="star-filled"
                          size="24px"
                          padding="4px"
                          color={Color.LighterYellow}
                          marginTop="10px"
                          onMouseEnter={() => {
                            setShowMessage(true);
                            setHoveredMessage(index);
                          }}
                          onMouseLeave={() => {
                            setShowMessage(false);
                            setHoveredMessage(undefined);
                          }}
                        />
                      </RatingRow>
                    </RatingContainer>
                    <IconsContainer>
                      <IconCircularContainer
                        isAccept
                        isDisabled={rating.message == ""}
                      >
                        <CustomIcon
                          style={{ zIndex: 1000 }}
                          name="message"
                          size="24px"
                          padding="2px"
                          color={Color.White}
                          onMouseEnter={() => {
                            setShowMessage(true);
                            setHoveredMessage(index);
                          }}
                          onMouseLeave={() => {
                            setShowMessage(false);
                            setHoveredMessage(undefined);
                          }}
                        />
                      </IconCircularContainer>
                    </IconsContainer>
                  </RatingCell>
                  {showMessage && index == hoveredMessage && rating.message && (
                    <RatingCell>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("General.Message")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >
                        {rating.message}
                      </Text>
                    </RatingCell>
                  )}
                </>
              );
            })}
          {!isLoading &&
            latestRatings !== null &&
            latestRatings.length === 0 && (
              <Text color={Color.SeaBlue400}>{t("General.NoRatingsYet")}</Text>
            )}
          {(isLoading || latestRatings == null) && <CircularProgress />}
        </DashboardCellContent>
      </DashBoardCell>
    </>
  );
};
