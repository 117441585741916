import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getWorkHours } from "utils/utils";
import { CompanyGigDto } from "model/Gig";
import { getDateString } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import {
  SimpleCell,
  SimpleDateHours,
  DividerLine1,
  SimpleTitleStatus,
  DividerLine2,
} from "./gigs.styled";
import { WorkerWithUrl } from "model/Company";
import { GigDetailsModal } from "../calendar/gigDetailsModal.component";
import { GigWorkerCount } from "./gigWorkerCount.component";

type Props = {
  gigData: CompanyGigDto;
  workerWithImageUrl?: WorkerWithUrl[];
};

export const PassedOrClosedGig: React.FC<Props> = ({
  gigData,
  workerWithImageUrl,
}) => {
  const { t } = useTranslation();
  const startTime = gigData.shifts?.[0]?.startTime ?? "";
  const endTime = gigData.shifts?.[gigData.shifts.length - 1]?.endTime ?? "";
  const [gigModalOpen, setGigModalOpen] = useState(false);


  return (
    <>
      <SimpleCell>
        <SimpleTitleStatus>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0, cursor: "pointer" }}
            onClick={() => {
              setGigModalOpen(true);
            }}
          >
            {t(gigData.role || "", { ns: "roles" })}
          </Text>
        </SimpleTitleStatus>
        <DividerLine1 />
        <SimpleDateHours>
          <Text fontSize={FontSize.Large} color={Color.MidnightBlue}>
            {startTime && getDateString(startTime)}
            {getDateString(startTime) !== getDateString(endTime) &&
              ` - ${getDateString(endTime)}`}
          </Text>
          <Text
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
            padding
            style={{ margin: 0, lineHeight: FontSize.Small }}
          >
            {t("GigsCompany.TotalTime") +
              ": " +
              getWorkHours(t, ...gigData.shifts)}
          </Text>
        </SimpleDateHours>
        <DividerLine2 />
        <GigWorkerCount
          workerWithImageUrl={workerWithImageUrl}
          gigData={gigData}
        />
      </SimpleCell>
      {gigModalOpen && (
        <GigDetailsModal
          gigId={gigData.id}
          setModalOpen={setGigModalOpen}
          modalOpen
        />
      )}
    </>
  );
};
