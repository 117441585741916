import React from "react";
import { FontFamily } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { LinkWrapper, ModalBody } from "./workgroup.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { WorkGroupWorker } from "model/Workgroup";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowDeleteWorkerModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteWorkerModal: boolean;
  worker: WorkGroupWorker;
};

export const WorkGroupDeleteWorker: React.FC<Props> = ({
  setShowDeleteWorkerModal,
  showDeleteWorkerModal,
  worker,
}) => {
  const { t } = useTranslation();
  const [companyState, ,] = useCompanyStore();
  const [, workgroupActions] = useWorkgroupStore();
  const [, , alertDispatch] = useAlertStore();

  const handleDeleteWorker = () => {
    if (companyState.company && worker.workGroupId && worker.id) {
      workgroupActions
        .deleteWorkerFromWorkGroup(
          companyState.company.id,
          worker.workGroupId,
          worker.id
        )
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("WorkGroup.Deleted"),
              message: t("WorkGroup.WorkerHasBeenDeleted"),
            },
          });
          setShowDeleteWorkerModal(false);
        })
        .catch(() => {
          console.log("error when deleting worker");
        });
    }
  };

  return (
    <>
      <Modal
        open={showDeleteWorkerModal}
        onClose={() => {
          setShowDeleteWorkerModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowDeleteWorkerModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ textAlign: "center" }}
          >
            {t("WorkGroup.DoYouWantToDeleteWorker")}
          </Text>
          <ButtonStyled
            isCancel
            backgroundColor={Color.LighterDestructive}
            onClick={() => {
              handleDeleteWorker();
            }}
          >
            {t("WorkGroup.Delete")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
