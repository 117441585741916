import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import {
  CompanyGigDto,
  CompanyGigRequestDto,
  GigCosts,
  GigRating,
  OfferGigRequest,
  OfferGigResponse,
} from "model/Gig";
import { GigContract } from "model/GigContract";

const getGigs = async (
  companyId: number,
  page?: number,
  amount?: number,
  status?: string,
  ascending?: boolean
): Promise<AxiosResponse<CompanyGigDto[]>> => {
  return await axiosRequest.auth
    .get<CompanyGigDto[]>(`${API_ROOT}/companies/${companyId}/gigs`, {
      params: {
        page,
        amount,
        status,
        ascending,
      },
    })
    .then((res) => res);
};

const getGigsWithState = async (
  companyId: number,
  state: string,
  sortAscending: boolean,
  page: number,
  amount: number,
  search: string | null
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth
    .get<any>(`${API_ROOT}/companies/${companyId}/gigs/v2`, {
      params: {
        state,
        sortAscending,
        page,
        amount,
        search
      },
    })
    .then((res) => res);
};


const getActiveGigsCount = async (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth
    .get<any>(`${API_ROOT}/companies/${companyId}/activegigs`)
    .then((res) => res);
};

const getGig = async (
  companyId: number,
  gigId: number
): Promise<AxiosResponse<CompanyGigDto>> => {
  return await axiosRequest.auth
    .get<CompanyGigDto>(`${API_ROOT}/companies/${companyId}/gigs/${gigId}`)
    .then((res) => res);
};

const saveGig = async (
  companyId: number,
  newGig: CompanyGigRequestDto,
  gigId?: number
): Promise<AxiosResponse<CompanyGigDto>> => {
  if (gigId) {
    return await axiosRequest.auth
      .put<CompanyGigDto>(
        `${API_ROOT}/companies/${companyId}/gigs/${gigId}`,
        newGig
      )
      .then((res) => res);
  } else {
    return await axiosRequest.auth.post<CompanyGigDto>(
      `${API_ROOT}/companies/${companyId}/gigs`,
      newGig
    );
  }
};

const offerGigToWorker = async (
  offerGigRequest: OfferGigRequest
): Promise<AxiosResponse<OfferGigResponse>> => {
  return await axiosRequest.auth.post(
    `${API_ROOT}/companies/offergig`,
    offerGigRequest
  );
};

const deleteGig = async (
  companyId: number,
  gigId: number
): Promise<AxiosResponse<void>> => {
  return await axiosRequest.auth.delete<void>(
    `${API_ROOT}/companies/${companyId}/gigs/${gigId}`
  );
};

const getGigContract = (
  companyId: number,
  applicationId: number
): Promise<AxiosResponse<GigContract>> => {
  return axiosRequest.auth
    .get<GigContract>(
      `${API_ROOT}/companies/${companyId}/applications/${applicationId}/contract`
    )
    .then((res) => res);
};

const getGigRating = (
  companyId: number,
  gigId: number
): Promise<AxiosResponse<GigRating[]>> => {
  return axiosRequest.auth
    .get<GigRating[]>(`${API_ROOT}/companies/${companyId}/gigrating/${gigId}`)
    .then((res) => res);
};

const getGigCost = (
  companyId: number,
  data: any
): Promise<AxiosResponse<GigCosts>> => {
  return axiosRequest.auth
    .post<any>(`${API_ROOT}/companies/${companyId}/gigs/costs`, data)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const gig = {
  getGigs,
  getGigsWithState,
  getGig,
  saveGig,
  deleteGig,
  getGigContract,
  offerGigToWorker,
  getActiveGigsCount,
  getGigRating,
  getGigCost,
};
