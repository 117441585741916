import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TimeTotal, TotalCostMonthly } from "model/TimeReport";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { TotalTimeWrapper } from "./monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import moment from "moment";
import { Month } from "@js-joda/core";
import { formatDuration } from "utils/utils";

type Props = {
  month: number;
  chosenMonth: Month;
  year: number;
  setTotalCostMonthly: React.Dispatch<React.SetStateAction<TotalCostMonthly | null>>
};

export const TotalTimeDetails: React.FC<Props> = ({
  month,
  chosenMonth,
  year,
  setTotalCostMonthly
}) => {
  const { t } = useTranslation();
  const [timeData, setTimeData] = useState({
    totalTime: "0h 0min",
    totalTimeApproved: "0h 0min",
    totalTimeSubmitted: "0h 0min",
    totalTimeLeft: "0h 0min",
    totalTimeAbsence: "0h 0min",
    totalTimeLeaveOfAbsence: "0h 0min",
    totalTimeVacation: "0h 0min",
    totalTimeSickLeave: "0h 0min",
    totalTimeSickLeaveChild: "0h 0min",
    totalTimeOther: "0h 0min",
    totalTimeParentalLeave: "0h 0min",
  });
  const [companyState] = useCompanyStore();
  const [currentTimeTotal, setCurrentTimeTotal] = useState<TimeTotal>();
  const [showAbsenceDetails, setShowAbsenceDetails] = useState(false);

  useEffect(() => {
    if (currentTimeTotal) {
      setTimeData({
        totalTime: formatDuration(currentTimeTotal.plannedTime),
        totalTimeApproved: formatDuration(currentTimeTotal.approvedTime),
        totalTimeSubmitted: formatDuration(currentTimeTotal.submittedTime),
        totalTimeLeft: formatDuration(currentTimeTotal.remainingTime),
        totalTimeAbsence: formatDuration(currentTimeTotal.absenceTime),
        totalTimeLeaveOfAbsence: formatDuration(
          currentTimeTotal.absenceDetails.leaveOfAbsence
        ),
        totalTimeVacation: formatDuration(
          currentTimeTotal.absenceDetails.vacation
        ),
        totalTimeSickLeave: formatDuration(
          currentTimeTotal.absenceDetails.sickLeave
        ),
        totalTimeSickLeaveChild: formatDuration(
          currentTimeTotal.absenceDetails.sickLeaveChild
        ),
        totalTimeOther: formatDuration(currentTimeTotal.absenceDetails.other),
        totalTimeParentalLeave: formatDuration(
          currentTimeTotal.absenceDetails.parentalLeave
        ),
      });
    }
  }, [currentTimeTotal]);

  useEffect(() => {
    const companyId = companyState.company?.id;
    if (companyId) {
      Api()
        .company.getTotalTimeAndCostsMonthly(companyId, year, month)
        .then((res) => {
          setCurrentTimeTotal(res.data.timeSummary);
          setTotalCostMonthly(res.data.costSummary)
        })
        .catch((err) => {
          console.log("Error fetching time total", err);
        });
    }
  }, [companyState.company?.id, year, month, chosenMonth]);

  return (
    <TotalTimeWrapper>
      <Text color={Color.White}>
        {t("CompanyTimeReport.ScheduledTime")}: {timeData.totalTime}
      </Text>
      <Text color={Color.White} fontSize={FontSize.Small}>
        {t("CompanyTimeReport.TotalTimeApproved")}: {timeData.totalTimeApproved}
      </Text>
      <Text color={Color.White} fontSize={FontSize.Small}>
        {t("CompanyTimeReport.TotalTimeSubmitted")}:{" "}
        {timeData.totalTimeSubmitted}
      </Text>
      <Text color={Color.White} fontSize={FontSize.Small}>
        {t("CompanyTimeReport.TotalTimeLeft")}: {timeData.totalTimeLeft}
      </Text>
      <Text
        color={Color.White}
        fontSize={FontSize.Small}
        fontFamily={FontFamily.MontserratBold}
        onClick={() => setShowAbsenceDetails((prev) => !prev)}
        cursor={true}
      >
        {t("CompanyTimeReport.TotalTimeAbsence")}: {timeData.totalTimeAbsence}
      </Text>
      {showAbsenceDetails && (
        <>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalSickLeave")}:{" "}
            {timeData.totalTimeSickLeave}
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalSickLeaveChild")}:{" "}
            {timeData.totalTimeSickLeaveChild}
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalLeaveOfAbsence")}:{" "}
            {timeData.totalTimeLeaveOfAbsence}
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalVacation")}: {timeData.totalTimeVacation}
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalParentalLeave")}:{" "}
            {timeData.totalTimeParentalLeave}
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalOther")}: {timeData.totalTimeOther}
          </Text>
        </>
      )}
    </TotalTimeWrapper>
  );
};
