import styled from "styled-components";
import { Color } from "config/colors";
export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: 76px repeat(7, 60px);
  width: 100%;
  background-color: ${Color.SeaBlue300};
  grid-gap: 1px;
  border: 1px solid ${Color.SeaBlue300};
`;
export const CalendarHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 76px repeat(7, 60px);
  width: 100%;
  margin-bottom: 12px;
  grid-gap: 1px;
`;

export const GigHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.SeaBlue300};
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;
  p {
    margin: 4px;
    font
  }
`;

export const HeaderContainer = styled.div``;

export const CalenderDot = styled.div`
  background-color: ${Color.MidnightBlue};
  width: 10px;  
  height: 10px;
  border-radius: 50%;  
  padding: 5px;
`;

