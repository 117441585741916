import React from "react";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyGigDto } from "model/Gig";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { RowWrapper, CompanyInfoContainer } from "./gigDetail.styled";
import { GigDetailRatings } from "./gigDetailRatings.component";
import { GigDetailButtons } from "./gigDetailButtons.component";
import { GigDetailShifts } from "./gigDetailShifts.component";
import { GigDetailDescription } from "./gigDetailDescription";
import { GigDetailRolesAndSkills } from "./gigDetailRolesAndSkills.component";
import { GigDetailCosts } from "./gigDetailCosts.component";
import { GigDetailWorkers } from "./gigDetailWorkers.component";
import { GigDetailApplicants } from "./gigDetailApplicants.compontent";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
};

export const GigDetail: React.FC<Props> = ({ gigData, onGigClosed }) => {
  const [companyState] = useCompanyStore();

  return (
    <>
      <RowWrapper>
        <CompanyInfoContainer>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratRegular}
          >
            {companyState.company?.companyName}
          </Text>
          <Text>{companyState.company?.address}</Text>
        </CompanyInfoContainer>
        <GigDetailRolesAndSkills gigData={gigData} />
        <GigDetailCosts gigData={gigData} />
        <GigDetailWorkers gigData={gigData} />
        <GigDetailApplicants gigData={gigData} />
        {gigData.description && <GigDetailDescription gigData={gigData} />}
        <GigDetailShifts gigData={gigData} />
        <GigDetailRatings gigData={gigData} />
        <GigDetailButtons gigData={gigData} onGigClosed={onGigClosed} />
      </RowWrapper>
    </>
  );
};
