import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { TimeReportStatus } from "model/TimeReport";
import { useTranslation } from "react-i18next";
import { Text } from "components/Typography/text.styled";

export const WorkerTimeReportStatus = (status: TimeReportStatus) => {
  const { t } = useTranslation()

  switch (status) {
    case TimeReportStatus.CLOSED: {
      return (
        <>
          <Text>{t("CompanyTimeReport.Closed")}</Text>
          <CustomIcon
            name="checkmark"
            color={Color.BurntSienna}
            padding="4px"
          />
        </>
      );
    }
    case TimeReportStatus.REJECTED: {
      return (
        <>
          <Text>{t("CompanyTimeReport.Rejected")}</Text>
          <CustomIcon name="cross" color={Color.Destructive} padding="4px" />
        </>
      );
    }
    case TimeReportStatus.PROCESSING_PAYMENT: {
      return (
        <>
          <Text>{t("CompanyTimeReport.ProcessingPayment")}</Text>
          <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
        </>
      );
    }
    case TimeReportStatus.INVOICE_NOT_PAID: {
      return (
        <>
          <Text>{t("CompanyTimeReport.InvoiceNotPaid")}</Text>
          <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
        </>
      );
    }
    case TimeReportStatus.NEW: {
      return (
        <>
          <Text>{t("CompanyTimeReport.Unsubmitted")}</Text>
          <CustomIcon name="clock" color={Color.SeaBlue500} padding="4px" />
        </>
      );
    }
    case TimeReportStatus.SUBMITTED: {
      return (
        <>
          <Text>{t("CompanyTimeReport.Submitted")}</Text>
          <CustomIcon
            name="alert"
            color={Color.ChampagnePink}
            padding="4px"
          />
        </>
      );
    }
    case TimeReportStatus.PAID: {
      return (
        <>
          <Text>{t("CompanyTimeReport.Paid")}</Text>
          <CustomIcon name="wallet" color={Color.BurntSienna} padding="4px" />
        </>
      );
    }
    default: {
      return <></>;
    }
  }
};
