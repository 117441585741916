import styled from "styled-components";
import { Color } from "../../../../../config/colors";
import { FontFamily, FontSize } from "../../../../../config/font";

export const GigpayWrapper = styled.div`
  flex-direction: column;
`;

export const FormStyled = styled.form`
  padding: 10px;
  border-radius: 10px;
  max-width: 400px;
`;

export const FormTitle = styled.h3`
  font-family: ${FontFamily.MontserratSemiBold};
  color: ${Color.BurntSienna};
`;

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 5px 0;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  margin: 20px 0;
  display: block;
  width: 500px;
`;

export const LabelStyled = styled.span<{
  color?: Color;
  fontFamily?: FontFamily;
  fontSize?: FontSize;
}>`
  color: ${(props) => (props.color ? props.color : Color.SeaBlue600)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
    Helvetica;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
`;

export const ReadOnlyStyled = styled.span`
  font-family: ${FontFamily.MontserratRegular};
`;

export const TextInput = styled.input<{ fontSize?: FontSize }>`
  border: none;
  border-bottom: 1px solid ${Color.SeaBlue400};
  width: 100%;
  padding: 5px;
  outline: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  color: ${(props) => (props.disabled ? Color.SeaBlue600 : Color.MidnightBlue)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  border-radius: 0px;

  ::placeholder {
    color: ${Color.SeaBlue400};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

export const H5Styled = styled.h5`
  font-family: ${FontFamily.MontserratSemiBold};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TermsLink = styled.p`
  margin-bottom: 0;
  font-size: 12px;
  cursor: pointer;
  color: ${Color.BurntSienna};
  font-family: ${FontFamily.MontserratSemiBold};
`;
export const TermsContainer = styled.div`
  display: 'flex;
`;
export const TermsReadText = styled.p`
  font-size: 14px;
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratSemiBold};
`;
