import { Color } from 'config/colors';
import styled from 'styled-components';

export const CompanyContainer = styled.div`
  font-family: Arial, sans-serif;
`;

export const EntityContainer = styled.div`
  margin-left: 20px;
`;

export const EntityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const UnitContainer = styled.div`
  margin-left: 20px;
`;

export const DividerLine = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid ${Color.SeaBlue200};
`;

export const UnitHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.SeaBlue200};
  align-items: center;
  cursor: pointer;
`;

export const WorkerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
`;

export const TextColumn = styled.div`
  display: flex; 
  flex-direction: column; 
  margin-right: 20px;
`;

export const RowItem = styled.div`
  display: flex;
`;
