import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { CompanyGigDto } from "model/Gig";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  ApplicationCell,
  CellContent,
  DropDownRow,
  GiggerContainer,
} from "./gigDetail.styled";
import {
  IconCircularContainer,
  IconsContainer,
} from "../dashboard/dashboard.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { useAlertStore } from "stores/alertStore/alertStore";
import { WorkerDetail } from "../timeReport/workers/workerDetail.component";
import { CompanyWorkerDto } from "model/Company";
import { fetchWorker } from "web-apps/company/utils/utils";

type Props = {
  gigData: CompanyGigDto;
};

export const GigDetailApplicants: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [applicantsOpen, setApplicantsOpen] = useState(false);
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(false);
  const [gigApplications, setGigApplications] = useState<ApplicationDto[]>([]);
  const [applicationState, applicationActions, applicationDispatch] =
    useApplicationStore();
  const [unhandledApplications, setUnhandledApplications] = useState<
    ApplicationDto[]
  >([]);
  const lastShiftEndTime = gigData.shifts[gigData.shifts.length - 1]?.endTime;
  const isShiftOngoing = new Date(lastShiftEndTime).getTime() > Date.now();
  const [, , alertDispatch] = useAlertStore();
  const [worker, setWorker] = useState<CompanyWorkerDto>();
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [application, setApplication] = useState<ApplicationDto>();

  useEffect(() => {
    if (companyState.company?.id) {
      setIsLoading(true);
      Api()
        .company.application.getGigApplications(
          companyState.company.id,
          gigData.id
        )
        .then((res) => {
          setGigApplications(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Error fetching gig applications", err);
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    let unhandled: ApplicationDto[] = [];
    gigApplications.forEach((application) => {
      if (
        application.status === ApplicationStatus.UNDEFINED ||
        application.status === ApplicationStatus.PENDING
      ) {
        unhandled.push(application);
      }
    });
    setUnhandledApplications(unhandled);
  }, [gigApplications]);

  const showAlert = (
    type: "Offered" | "Rejected" | "Update",
    error?: boolean
  ) => {
    const alertConfig = {
      icon: error ? "alert" : "checkmark",
      title: error
        ? t("Alerts.OhNo")
        : type === "Offered"
        ? t("ApplicationsCompany.Offered")
        : t("ApplicationsCompany.Rejected"),
      message: error
        ? t("ApplicationsCompany.ApplicationCouldNotBeUpdated")
        : type === "Offered"
        ? t("ApplicationsCompany.ApplicantHasBeenOfferedGig")
        : t("ApplicationsCompany.ApplicationHasBeenRejected"),
    };

    alertDispatch({
      type: "SHOW_ALERT",
      payload: alertConfig,
    });
  };

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED,
    applicant: ApplicationDto
  ) => {
    if (companyState.company) {
      const data = {
        ...applicant,
        status,
        ...(status === ApplicationStatus.CLOSED && {
          resolution: ApplicationResolution.REJECTED,
        }),
      };
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicant.id, data)
        .then(() => {
          applicationDispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: applicationState.applicationsCount - 1,
          });
          if (status === ApplicationStatus.OFFERED) {
            showAlert("Offered");
          } else {
            showAlert("Rejected");
          }
          setUnhandledApplications(
            unhandledApplications.filter(
              (application) => application.id !== data.id
            )
          );
        })
        .catch(() => {
          showAlert("Update", true);
        });
    }
  };

  useEffect(() => {
    setWorkerModalOpen(true);
  }, [worker]);

  return (
    <>
      {unhandledApplications.length !== 0 && (
        <>
          <DropDownRow
            onClick={() => {
              setApplicantsOpen(!applicantsOpen);
            }}
          >
            <Text fontFamily={FontFamily.MontserratSemiBold}>
              {`${t("GigsCompany.Applicants")} (${
                unhandledApplications.length
              } st)`}
            </Text>
            <CustomIcon
              name={applicantsOpen ? "upward_arrow" : "downward_arrow"}
              size="24px"
              color={Color.BurntSienna}
              style={{ marginTop: 10 }}
            />
          </DropDownRow>
          {applicantsOpen &&
            unhandledApplications.map((app) => {
              return (
                <>
                  <ApplicationCell>
                    <CellContent
                      onClick={() => {
                        setApplication(app);
                        fetchWorker(app.worker.id, companyState).then(
                          (worker) => {
                            setWorker(worker.data);
                          }
                        );
                      }}
                    >
                      <GiggerContainer>
                        <Text
                          style={{ margin: 0, padding: 0 }}
                          fontSize={FontSize.Small}
                          color={Color.SeaBlue600}
                        >
                          {t("General.Giggers")}
                        </Text>
                        <Text
                          color={Color.MidnightBlue}
                          fontFamily={FontFamily.MontserratRegular}
                        >{`${app.worker.firstName} ${app.worker.lastName}`}</Text>
                      </GiggerContainer>
                    </CellContent>
                    {isShiftOngoing && (
                      <IconsContainer>
                        <IconCircularContainer isAccept>
                          <CustomIcon
                            style={{ zIndex: 1000 }}
                            onClick={() =>
                              updateApplicationStatus(
                                ApplicationStatus.OFFERED,
                                app
                              )
                            }
                            name="checkmark"
                            size="24px"
                            padding="2px"
                            color={Color.White}
                          />
                        </IconCircularContainer>
                        <IconCircularContainer>
                          <CustomIcon
                            style={{ zIndex: 1000 }}
                            onClick={() =>
                              updateApplicationStatus(
                                ApplicationStatus.CLOSED,
                                app
                              )
                            }
                            name="cross"
                            size="24px"
                            padding="2px"
                            color={Color.White}
                          />
                        </IconCircularContainer>
                      </IconsContainer>
                    )}
                  </ApplicationCell>
                </>
              );
            })}
        </>
      )}
      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
          application={application}
        />
      )}
    </>
  );
};
