import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-family: ${FontFamily.MontserratRegular};
  margin-top: 40px;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: left;
  margin-left: 40px;

  button {
    margin-right: auto;
    left: 0;
  }
`;

export const DashBoardColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.MontserratRegular};
  width: 520px;
`;

export const DashBoardCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

export const DashboardCellContent = styled.div`
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;
export const DashboardCellWeekContent = styled.div`
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  margin-top: 30px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: blue;
  grid-column: 5;
  gap: 12px;
  align-items: center;
`;

type TimeReportCellProps = {
  isApplication?: boolean;
};

export const TimeReportCell = styled.div<TimeReportCellProps>`
  background-color: ${Color.White};
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.isApplication
      ? "0.7fr 0.2fr 1fr 1fr 0.2fr"
      : "0.6fr 0.2fr 1fr 0.5fr"};
  column-gap: 0.5em;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${Color.SeaBlue200};

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const RatingCell = styled(TimeReportCell)`
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const CellContent = styled.div`
  display: contents;
  width: 100%;
  grid-column: 1/3;
`;

type ButtonProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  backgroundColor?: Color;
  color?: Color;
  icon?: boolean;
  fontSize?: FontSize;
  lessPadding?: boolean;
};

export const ButtonStyled = styled.button<ButtonProps>`
  display: ${(props) => (props.icon ? "flex" : "block")};
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: none;
  ${(props) => props.fullWidth && "width: 100%"};
  padding: ${(props) => (props.lessPadding ? "5px 10px" : "10px 20px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s;
  background: linear-gradient(90deg, #388e3c 0%, #2e7d32 100%);
  color: ${(props) =>
    props.disabled
      ? Color.SeaBlue500
      : props.color
      ? props.color
      : Color.White};
  outline: none;
  transition: opacity 0.15s;
  cursor: pointer;

  ${(props) =>
    !props.disabled &&
    `
     &:hover {
    background-color: #2E7D32; 
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); 
    transform: translateY(-2px); 
  }

  &:active {
    transform: translateY(0); 
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); 
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5); 
  }`}
`;

export const DividerLine = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: ${Color.SeaBlue200};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${Color.SeaBlue200};
`;

export const TimeAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
`;

export const GigInfoContainer = styled(TimeAndDateContainer)``;

export const GiggerContainer = styled(TimeAndDateContainer)``;

export const TotalHoursWorkedContainer = styled(TimeAndDateContainer)``;

export const RatingContainer = styled(TimeAndDateContainer)``;

export const RatingRow = styled(TimeAndDateContainer)`
  flex-direction: row;
`;

export const ApplicationRoleContainer = styled(TimeAndDateContainer)``;

type IconCircularContainer = {
  isAccept?: boolean;
  isDisabled?: boolean;
  isBig?: boolean;
  backgroundColor?: Color;
  isSmall?: boolean;
};

export const IconCircularContainer = styled.div<IconCircularContainer>`
  background-color: ${(props) =>
    props.isAccept
      ? Color.LighterPositive
      : props.backgroundColor
      ? props.backgroundColor
      : Color.LighterDestructive};
  width: ${(props) => (props.isBig ? "48px" : props.isSmall ? "24px" : "32px")};
  height: ${(props) =>
    props.isBig ? "48px" : props.isSmall ? "24px" : "32px"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s;
  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);

  ${(props) =>
    props.isDisabled &&
    `
    opacity: 0.2;
  `}

  &:hover {
    ${(props) =>
      !props.isDisabled &&
      `
      transform: scale(1.1);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
      opacity: 1;
    `}

    &:active {
  ${(props) =>
    !props.isDisabled &&
    `
    transform: scale(0.95);
    `}
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: pointer;
`;

export const Badge = styled.div`
  background-color: ${Color.DarkerChampagnePink};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.95);
  }
`;
