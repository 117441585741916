export type WorkGroupWorker = {
  active: boolean;
  id: number;
  companyId: number;
  companyName: string;
  firstName: string;
  hourlyPay: number;
  monthlyPay: number;
  inviteId: number;
  lastName: string;
  status: string;
  workGroupId: number;
  workGroupName: string;
  settings: Setting[];
  firebaseId: string;
};

export type Setting = {
  id: string;
  parentId: string;
  key: string;
  value: string;
};

export type WorkGroup = {
  active: boolean;
  id: number;
  name: string;
  referenceCode: string;
  settings: [];
  type: string;
};

export type Entity = {
  name: string;
  units: {
    name: string;
    id: number;
    workers: [];
  }[];
};

export type EntityWithUnit = {
  key: string;
  value: [];
};

export type EntityWithUnitId = {
  key: string;
  value: number;
};

export enum WorkGroupActions {
  GetAllMembersInWorkgroup = "GET_ALL_MEMBERS_IN_WORKGROUP",
  GetAllWorkgroups = "GET_ALL_WORKGROUPS",
  CreateWorkgroup = "CREATE_WORKGROUP",
  GetAllInvitedInWorkgroup = "GET_ALL_INVITED_IN_WORKGROUP",
  GetEntities = "GET_ENTITIES",
  GetAllDeclinedInWorkgroup = "GET_ALL_DECLINED_IN_WORKGROUP",
  AddWorkerToWorkgroup = "ADD_WORKER_TO_WORKGROUP",
  DelteWorkerFromWorkgroup = "DELETE_WORKER_FROM_WORKGROUP",
  DeleteWorkgroup = "DELETE_WORKGROUP",
  UpdateWorkgroup = "UPDATE_WORKGROUP",
  DeleteInvitation = "DELETE_INVITATION",
  UpdateWorkerInWorkgroup = "UPDATE_WORKER_IN_WORKGROUP",
  InviteWorkerToWorkgroup = "INVITE_WORKER_TO_WORKGROUP",
}

export enum WorkGroupDetailsStatus {
  Members = "members",
  Declined = "declined",
  Invited = "invited",
}
