import { useState, useRef } from "react";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ListViewModel } from "components/dropdown/Dropdown";
import { Color } from "config/colors";
import { SaveContent } from "./calendarTemplatePicker.styled";
import { useTranslation } from "react-i18next";
import { CalendarTemplateDto } from "model/GigTemplate";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Modal } from "@material-ui/core";
import { useGetManyGigs } from "hooks/useGetManyGigs";
import { Api } from "services/api/api.service";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CustomIcon } from "components/icon/customIcon.component";

type Props = {
  setIsLoadingApi: React.Dispatch<React.SetStateAction<boolean>>;
  templateToDelete: ListViewModel | null;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<CalendarTemplateDto | undefined>
  >;
  showDeleteModal: boolean;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CalendarDeleteTemplate = ({
  setIsLoadingApi,
  templateToDelete,
  setSelectedTemplate,
  showDeleteModal,
  setShowDeleteModal
}: Props) => {
  const [companyState] = useCompanyStore();
  const [templates, setTemplates] = useState<CalendarTemplateDto[]>([]);
  const { t } = useTranslation();
  const apiState = useRef({ loading: false, completed: false, page: 0 });

  const removeTemplate = (vm: ListViewModel) => {
    setShowDeleteModal(true);

    if (companyState.company?.id && typeof vm.value === "number") {
      apiState.current.loading = true;
      setIsLoadingApi(true);
      Api()
        .company.template.deleteWeeklyTemplate(
          companyState.company?.id,
          vm.value
        )
        .then(() => {
          setTemplates(templates.filter((t) => t.id !== vm.value));
          apiState.current.loading = false;
          setIsLoadingApi(false);
          setShowDeleteModal(false);
        })
        .catch(() => {
          apiState.current.loading = false;
          setIsLoadingApi(false);
        });
    }
    setSelectedTemplate(undefined);
  };

  return (
    <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
      <SaveContent>
        <CustomIcon
          name="cross"
          size="40px"
          onClick={() => setShowDeleteModal(false)}
          style={{ margin: "20px 20px 0 0", alignSelf: "flex-end" }}
          color={Color.LighterDestructive}
        />
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H3}
          color={Color.PitchBlack}
          align="center"
        >
          {t("CalendarCompany.DeleteWeekTemplate")}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratRegular}
          fontSize={FontSize.Standard}
          color={Color.PitchBlack}
          align="center"
        >
          {t("CalendarCompany.DoYouWantToDeleteWeekTemplate", {
            template: templateToDelete?.label || "",
          })}
        </Text>
        <ButtonStyled
          style={{ marginBottom: "10px" }}
          backgroundColor={Color.LighterDestructive}
          onClick={() => {
            templateToDelete && removeTemplate(templateToDelete);
          }}
          isCancel
        >
          {t("General.Remove")}
        </ButtonStyled>
      </SaveContent>
    </Modal>
  );
};
