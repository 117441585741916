import React from "react";
import { LocalDateTime, Instant } from "@js-joda/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  HeadCell,
  TimeReportsGrid,
  TimeReportsGridBody,
  Cell,
  RoleText,
  RoleWrapper,
} from "./monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import {
  addLeadingZero,
  calculateBreakTime,
  formatDuration,
  isWithinXMinutes,
} from "utils/utils";
import { PaymentMethod } from "model/Gig";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { Logo } from "components/nav/logo/logo.styled";
import moment from "moment";
import { shiftWithAbsence } from "web-apps/company/utils/utils";
type Props = {
  monthlyWorkerWithTimeReports: WorkerWithTimeReports;
  setChosenTimeReportWithWorker: React.Dispatch<
    React.SetStateAction<TimeReportDto | null>
  >;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  select?: boolean;
  selectedTimeReports?: any[];
  setSelectedTimeReports?: React.Dispatch<React.SetStateAction<any>>;
  showCheckInCheckOutColumns: boolean;
  month?: number;
  year?: number;
  unhandledView?: boolean;
};

export const WorkerTimeReports: React.FC<Props> = ({
  monthlyWorkerWithTimeReports,
  setChosenTimeReportWithWorker,
  setModalOpen,
  select,
  selectedTimeReports,
  setSelectedTimeReports,
  showCheckInCheckOutColumns,
  unhandledView,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getStatus = (status: TimeReportStatus) => {
    switch (status) {
      case TimeReportStatus.CLOSED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Closed")}</Text>
            <CustomIcon
              name="checkmark"
              color={Color.BurntSienna}
              padding="4px"
            />
          </>
        );
      }
      case TimeReportStatus.REJECTED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Rejected")}</Text>
            <CustomIcon name="cross" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.PROCESSING_PAYMENT: {
        return (
          <>
            <Text>{t("CompanyTimeReport.ProcessingPayment")}</Text>
            <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.INVOICE_NOT_PAID: {
        return (
          <>
            <Text>{t("CompanyTimeReport.InvoiceNotPaid")}</Text>
            <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.NEW: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Unsubmitted")}</Text>
            <CustomIcon name="clock" color={Color.SeaBlue500} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.SUBMITTED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Submitted")}</Text>
            <CustomIcon
              name="alert"
              color={Color.ChampagnePink}
              padding="4px"
            />
          </>
        );
      }
      case TimeReportStatus.PAID: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Paid")}</Text>
            <CustomIcon name="wallet" color={Color.BurntSienna} padding="4px" />
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <TimeReportsGrid showCheckInCheckOutColumns={showCheckInCheckOutColumns}>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Date")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Shift")}
          </Text>
        </HeadCell>
        {showCheckInCheckOutColumns && (
          <HeadCell>
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CompanyTimeReport.CheckInCheckOut")}
            </Text>
          </HeadCell>
        )}
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ marginLeft: "8px" }}
          >
            {t("CompanyTimeReport.Role")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Break")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Time")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.HourlyRate")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Status")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Updated")}
          </Text>
        </HeadCell>
        <HeadCell>
          {!unhandledView && (
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CompanyTimeReport.Resolution")}
            </Text>
          )}
        </HeadCell>
      </TimeReportsGrid>

      {monthlyWorkerWithTimeReports.timeReports.map(
        (timeReport: any, timeReportIndex: any) => {
          const startTime = LocalDateTime.ofInstant(
            Instant.parse(timeReport.startTime)
          );
          const endTime = LocalDateTime.ofInstant(
            Instant.parse(timeReport.endTime)
          );
          const weekDayString = `WeekDays.${startTime.dayOfWeek().name()}SHORT`;

          if (timeReport.resolution !== TimeReportResolution.WITHDRAWN) {
            return (
              <TimeReportsGridBody
                showCheckInCheckOutColumns={showCheckInCheckOutColumns}
                key={timeReportIndex}
                onClick={() => {
                  if (select && selectedTimeReports && setSelectedTimeReports) {
                    if (
                      selectedTimeReports?.find(
                        (selectedTimeReport) =>
                          timeReport.id === selectedTimeReport.id
                      )
                    ) {
                      const newSelected = selectedTimeReports.filter(
                        (selectedTimeReport) =>
                          selectedTimeReport.id !== timeReport.id
                      );
                      setSelectedTimeReports(newSelected);
                    } else {
                      setSelectedTimeReports([
                        ...selectedTimeReports,
                        timeReport,
                      ]);
                    }
                  } else {
                    setChosenTimeReportWithWorker(timeReport);
                    setModalOpen(true);
                  }
                }}
              >
                <Cell>
                  <Text fontSize={FontSize.Large}>{`${t(
                    weekDayString
                  )} ${startTime.dayOfMonth()}/${startTime.monthValue()}`}</Text>
                </Cell>
                <Cell>
                  <Text fontSize={FontSize.Large}>
                    {`${addLeadingZero(startTime.hour())}:${addLeadingZero(
                      startTime.minute()
                    )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
                      endTime.minute()
                    )}`}
                  </Text>
                </Cell>
                {showCheckInCheckOutColumns && (
                  <Cell>
                    <Text
                      fontSize={FontSize.Large}
                      color={
                        timeReport.checkInTime == null ||
                        isWithinXMinutes(
                          new Date(timeReport.startTime),
                          new Date(timeReport.checkInTime),
                          15
                        )
                          ? undefined
                          : Color.Destructive
                      }
                    >
                      {timeReport.checkInTime
                        ? new Date(timeReport.checkInTime).toLocaleTimeString(
                            "sv-SE",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : ""}
                    </Text>
                    <Text>{"-"}</Text>
                    <Text
                      fontSize={FontSize.Large}
                      color={
                        timeReport.checkOutTime == null ||
                        isWithinXMinutes(
                          new Date(timeReport.endTime),
                          new Date(timeReport.checkOutTime),
                          15
                        )
                          ? undefined
                          : Color.Destructive
                      }
                    >
                      {timeReport.checkOutTime
                        ? new Date(timeReport.checkOutTime).toLocaleTimeString(
                            "sv-SE",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : ""}
                    </Text>
                  </Cell>
                )}
                <Cell>
                  <RoleWrapper
                    onClick={(e) => {
                      e.stopPropagation();

                      if (
                        window.location.href.indexOf(
                          timeReport.gigId.toString()
                        ) !== -1
                      ) {
                        history.push(`../gig/${timeReport.gigId}`);
                      } else {
                        history.push(`./gig/${timeReport.gigId}`);
                      }
                    }}
                  >
                    <RoleText fontSize={FontSize.Standard}>
                      {timeReport.gigRole}
                    </RoleText>
                    <CustomIcon
                      name="document"
                      color={Color.White}
                      size="20px"
                    />
                  </RoleWrapper>
                </Cell>
                <Cell>
                  <Text fontSize={FontSize.Large}>
                    {timeReport.breakStartTime && timeReport.breakEndTime
                      ? calculateBreakTime(
                          timeReport.breakStartTime,
                          timeReport.breakEndTime,
                          moment(
                            LocalDateTime.ofInstant(
                              Instant.parse(timeReport.startTime)
                            ).toString()
                          ),
                          moment(
                            LocalDateTime.ofInstant(
                              Instant.parse(timeReport.endTime)
                            ).toString()
                          )
                        )
                      : 0}
                    {t("General.MinutesShortened")}
                  </Text>
                </Cell>
                <Cell>
                  {shiftWithAbsence(timeReport) && (
                    <Text fontSize={FontSize.Large}>{"-"}</Text>
                  )}
                  <Text fontSize={FontSize.Large}>
                    {timeReport.totalTime
                      ? formatDuration(timeReport.totalTime)
                      : "0h 0min"}
                  </Text>
                </Cell>
                <Cell>
                  <Text fontSize={FontSize.Large}>
                    {timeReport.hourlyRate}kr
                  </Text>
                </Cell>
                <Cell>{getStatus(timeReport.status)} </Cell>
                <Cell>
                  <Text fontSize={FontSize.Large}>
                    {`${new Date(timeReport.updatedAt).toLocaleDateString(
                      "sv-SE",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )} ${new Date(timeReport.updatedAt).toLocaleTimeString(
                      "sv-SE",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}`}
                  </Text>
                </Cell>
                <Cell>
                  {timeReport.resolution !== TimeReportResolution.UNDEFINED && (
                    <Text fontSize={FontSize.Large}>
                      {t(`CompanyTimeReport.${timeReport.resolution}`)}
                    </Text>
                  )}
                  {select && selectedTimeReports && setSelectedTimeReports && (
                    <CustomIcon
                      name="checkmark"
                      size="27px"
                      color={Color.White}
                      backgroundColor={
                        selectedTimeReports?.find(
                          (selectedTimeReport) =>
                            timeReport.id === selectedTimeReport.id
                        )
                          ? Color.MidnightBlue
                          : Color.White
                      }
                      padding="2px"
                      onClick={() => {
                        if (
                          selectedTimeReports?.find(
                            (selectedTimeReport) =>
                              timeReport.id === selectedTimeReport.id
                          )
                        ) {
                          const newSelected = selectedTimeReports.filter(
                            (selectedTimeReport) =>
                              selectedTimeReport.id !== timeReport.id
                          );
                          setSelectedTimeReports(newSelected);
                        } else {
                          setSelectedTimeReports([
                            ...selectedTimeReports,
                            timeReport,
                          ]);
                        }
                      }}
                      style={{
                        border: `2px solid ${Color.MidnightBlue}`,
                        flexShrink: 0,
                        marginLeft: "15px",
                      }}
                    />
                  )}
                  {timeReport.paymentType === PaymentMethod.SIMFLEXITY_PAY && (
                    <Logo
                      src={SimflexityPayLogo}
                      style={{
                        height: "18px",
                        width: "auto",
                        marginLeft: "10px",
                      }}
                      alt="SimflexityPay logo"
                    />
                  )}
                </Cell>
              </TimeReportsGridBody>
            );
          }
        }
      )}
    </>
  );
};
