import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import i18n from "services/translation/i18n";

interface TermsWebLinkProps {
  linkName: string
  selectedTerms: string
  color?: Color
  bold?: boolean
}

export const TermsWebLink: React.FC<TermsWebLinkProps> = ({ selectedTerms, linkName: linkName, color = Color.SeaBlue600, bold = false }) => (
  <Text
  fontSize={FontSize.Small}
  fontFamily={bold ? FontFamily.MontserratSemiBold : FontFamily.MontserratRegular}
  color={color}
  cursor={true}
    onClick={() => {
      const lang = i18n.language; 
      window.open(
        `https://www.simflexity.com/${lang.includes("en") ? "en" : lang}/terms-and-condition/?selectedTerms=${selectedTerms}`
      );
    } }
  >
    {linkName}
  </Text>
);
