import { Text } from "components/Typography/text.styled";
import { Color } from 'config/colors';
import { FontFamily, FontSize } from 'config/font';
import React from 'react';
import { PhoneInputStyled } from "./phoneInput.styled";

interface Props {
  name: string,
  value: string,
  disabled: boolean,
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PhoneInput: React.FC<Props> = ({ name, value, disabled, handleInputChange }) => {
  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginBottom: 16}}>
      <PhoneInputStyled
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        fontSize={FontSize.Large}
        placeholder="+46[701234567]"
      />
      <Text
        color={Color.SeaBlue600}
        fontFamily={FontFamily.MontserratRegular}
        fontSize={FontSize.Small}
        style={{ position: 'absolute', top: '100%', left: '0', marginTop: '2px' }}
      >
        Ex: +46701234567
      </Text>
    </div>
  );
};

export default PhoneInput;
