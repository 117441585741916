import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize, FontFamily } from "config/font";

type FontProps = {
  color?: Color;
  fontSize?: FontSize;
  backgroundColor?: Color;
  fontFamily?: FontFamily;
  padding?: boolean;
  align?: "center" | "left" | "right";
  cursor?: boolean;
};

export const H1 = styled.h1<FontProps>`
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.H1)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.LeagueSpartanBold},
    Helvetica;
`;

export const H2 = styled.h2<FontProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.H2)};
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.LeagueSpartanBold},
    Helvetica;
  margin: 30px 0;
`;

export const H3 = styled.h3<FontProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.H3)};
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.LeagueSpartanBold},
    Helvetica;
`;

export const H4 = styled.h4<FontProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.H4)};
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
    Helvetica;
`;

export const H5 = styled.h5``;

export const Text = styled.p<FontProps>`
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratRegular},
    Helvetica;
  text-decoration: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "none"};
  ${(props) => props.padding && "padding: 5px; border-radius: 4px;"}
  ${(props) => props.align && `text-align: ${props.align};`}
  ${(props) => props.cursor && `cursor: pointer;`}
`;

export const CustomLink: React.FC<
  { to?: string; onClick?: () => void; disableEvent?: boolean } & FontProps
> = (props) => {
  const { to, children, ...rest } = props;
  return (
    <CustomLinkContainer {...rest}>
      {to ? <Link to={to}>{children}</Link> : <a>{children}</a>}
    </CustomLinkContainer>
  );
};

const CustomLinkContainer = styled.div<FontProps & { disableEvent?: boolean }>`
  margin: 5px;

  a {
    ${(props) => props.disableEvent && "pointer-events: none;"}
    color: ${(props) => (props.color ? props.color : Color.PitchBlack)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
    font-family: ${(props) =>
        props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
      Helvetica;
    text-decoration: none;
    align: ${(props) =>
      props.align ? props.align : 'center'}

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &:visited {
      color: ${(props) => (props.color ? props.color : Color.PitchBlack)};
    }
  }
`;

export const characterCounter = (
  max: number,
  text: string,
  showCounter?: boolean
) => {
    return (
      <Text
        style={{ marginTop: 0 }}
        fontSize={FontSize.Small}
        fontFamily={FontFamily.MontserratSemiBold}
        color={text.length > max ? Color.Destructive : Color.SeaBlue500}
      >{`${text.length}/${max}`}</Text>
    );
};
