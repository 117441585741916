import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import {
  Text,
  ClockStampCreateAutoGeneratedGigBody,
  GigInfoContainer,
  ButtonContainer,
} from "./clockStamp.styled";
import { FontFamily, FontSize } from "config/font";
import { Modal } from "@material-ui/core";
import moment from "moment-timezone";
import {
  TemplateForAutoGeneratedGig,
  TimeReportForStamping,
} from "model/Company";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowCreateAutoGeneratedGigModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setKeyShouldBeDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  showCreateAutoGeneratedGigModal: boolean;
  templateForAutoGeneratedGig: TemplateForAutoGeneratedGig;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTimeReport: React.Dispatch<
    React.SetStateAction<TimeReportForStamping | undefined>
  >;
  setIsAutoGeneratedGig: React.Dispatch<React.SetStateAction<boolean>>;
  clearInputs: any;
};

export const ClockStampCreateAutoGeneratedGigModal: React.FC<Props> = ({
  setShowCreateAutoGeneratedGigModal,
  setKeyShouldBeDisabled,
  showCreateAutoGeneratedGigModal,
  templateForAutoGeneratedGig,
  setShowModal,
  setTimeReport,
  setIsAutoGeneratedGig,
  clearInputs,
}) => {
  const { t } = useTranslation();
  const [companyState, companyActions] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();

  const handleCloseModal = () => {
    setShowCreateAutoGeneratedGigModal(false);
    setKeyShouldBeDisabled(false);
  };

  const formatTime = (time: string | undefined) =>
    moment.tz(time, "UTC").tz("Europe/Stockholm").format("YYYY-MM-DD HH:mm");

  const autoGenerateGig = () => {
    companyActions
      .createAutoGeneratedGig(
        templateForAutoGeneratedGig.companyId,
        templateForAutoGeneratedGig.workerPhoneNumber,
        templateForAutoGeneratedGig
      )
      .then((res) => {
        setTimeReport(res);
        setIsAutoGeneratedGig(true);
        clearInputs();
        setShowCreateAutoGeneratedGigModal(false);
        setShowModal(true);
      })
      .catch((e: any) => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t("Alerts.SomethingWentWrong"),
          },
        });
        console.error("Error when trying to auto generate shift", e);
      });
  };

  return (
    <>
      <Modal
        open={showCreateAutoGeneratedGigModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClockStampCreateAutoGeneratedGigBody>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.MidnightBlue}
          >
            {t("StampClock.AutoGenerateGig")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
            color={Color.MidnightBlue}
          >
            {t("StampClock.AutoGenerateGigInfo")}
          </Text>
          <GigInfoContainer>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.Role")}: ${templateForAutoGeneratedGig.role}`}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.StartTime")}: ${formatTime(
                templateForAutoGeneratedGig.startTime
              )}`}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.EndTime")}: ${formatTime(
                templateForAutoGeneratedGig.endTime
              )}`}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.BreakTime")}: ${
                templateForAutoGeneratedGig.breakTime
              } min`}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.HourlyRate")}: ${
                templateForAutoGeneratedGig.hourlyRate
              } kr`}
            </Text>
          </GigInfoContainer>
          <ButtonContainer>
            <ButtonStyled
              onClick={() => handleCloseModal()}
              backgroundColor={Color.Destructive}
              color={Color.White}
            >
              {t("StampClock.Close")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => autoGenerateGig()}
              backgroundColor={Color.BurntSienna}
              color={Color.White}
            >
              {t("StampClock.Create")}
            </ButtonStyled>
          </ButtonContainer>
        </ClockStampCreateAutoGeneratedGigBody>
      </Modal>
    </>
  );
};
