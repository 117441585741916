import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

export const EmployeeListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin: 10px 0 50px;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  p {
    margin: 0 0 30px 0;
  }
`;

export const ButtonsContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  align: right;
`;

export const WorkGroupDetailsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 40px;
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  width: fit-content;
  margin: auto;

  button {
    margin: 0 10px;
  }
`;

export const EmployeeRow = styled.li`
  border-bottom: 1px solid ${Color.SeaBlue200};
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px;

  p {
    margin: 10px;
  }

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const EmployeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  padding: 0 20px;

  p {
    margin: 0;
  }
`;

export const EmployeeImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

export const NoEmployeesContainer = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
`;

export const DividerLine = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: ${Color.SeaBlue200};
`;

export const NoApplicantsContainer = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
`;

export const HeaderRow = styled.div`
  display: flex;
`;

export const PhoneInputWrapper = styled.div`
  margin-bottom: 16px;
  .PhoneInputInput {
    font-family: ${FontFamily.MontserratRegular};
    border: none;
    color: ${Color.MidnightBlue};
    border-bottom: 0.5px solid ${Color.SeaBlue400};
    outline: none;
  }
  ,
  .PhoneInputInput::placeholder {
    color: ${Color.SeaBlue400};
  }
  ,
  .PhoneInput + .PhoneInput--focus {
    outline: none;
  }
  ,
  .PhoneInputCountryIcon--border {
    border: none;
    border-radius: 2px;
    color: ${Color.SeaBlue600};
    border-color: white;
  }
`;
export const FavoritesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  width: 100%;
  padding: 4px 4px 4px 4px;
  border-radius: 8px;
  margin-left: 10px;

  p {
    margin: 0;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
export const ReferenceCodeButton = styled.div`
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
export const SettingsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  div {
    margin-top: 16px;
  }
`;

export const SettingsModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
export const SelectRateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  p {
    margin: 0;
    margin-left: 4px;
  }
`;

export const PhoneNumberTextInput = styled.input<{ fontSize?: FontSize }>`
  border: 1px solid ${Color.SeaBlue400};
  border-radius: 8px;
  width: 100%;
  max-width: 180px;
  padding: 12px 16px;
  height: 30px;
  font-size: ${FontSize.Standard};
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  ::placeholder {
    color: ${Color.SeaBlue400};
    font-weight: 300;
  }

  &:focus {
    border-color: ${Color.PitchBlack};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PhoneNumberItem = styled.div`
  border-radius: 15px;
  width: 120px;
  height: 8px;
  padding: 12px;
  background-color: ${Color.MidnightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin: 2px;
`;

export const PhoneNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    margin-top: 30px;
  }
`;

export const PhoneNumbersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DropDownContainer = styled.div`
  border: 1px solid ${Color.SeaBlue400};
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  padding: 12px 16px;
  height: 40px;
  font-size: ${FontSize.Standard};
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: ${Color.PitchBlack};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    outline: none;
  }
`;

export const WorkGroupRowContainer = styled.div`
  background-color: ${Color.White};
  margin: 10px 0;
  padding: 15px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template:
    "workgroup-name divider1 members divider2 invited workGroup-options arrow"
    / 2fr 1px 1fr 1px 2fr 0.5fr 0.2fr;

  border-bottom: 1px solid ${Color.SeaBlue200};

  @media (min-width: 640px) {
    grid-template:
      "workgroup-name divider1 members divider2 invited workGroup-options arrow"
      / 2fr 1px 1fr 1px 2fr 0.5fr 0.2fr;
  }

  @media (min-width: 970px) {
    grid-template:
      "workgroup-name divider1 members divider2 invited workGroup-options arrow"
      / 2fr 1px 2fr 1px 2fr 0.5fr 0.2fr;
  }

  p {
    margin: 0;
  }
`;

export const WorkGroupNameContainer = styled.div`
  grid-area: workgroup-name;
`;

export const MemberContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-area: members;
`;

export const InvitedContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-area: invited;
`;

export const ArrowContainer = styled.div`
  grid-area: arrow;
  text-align: right;
  margin-left: 100px;
  cursor: pointer;
`;

export const WorkGroupDetailsRowContainer = styled.div`
  background-color: ${Color.White};
  margin: 10px 0;
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  grid-template:
    "worker-name divider1 monthly hourly divider2 options"
    / 2fr 1px 1fr 1fr 1px 1fr;

  border-bottom: 1px solid ${Color.SeaBlue200};

  @media (min-width: 640px) {
    grid-template:
      "worker-name divider1 monthly hourly divider2 options"
      / 2fr 1px 1fr 1fr 1px 1fr;
  }

  @media (min-width: 970px) {
    grid-template:
      "worker-name divider1 monthly hourly divider2 options"
      / 2fr 1px 1fr 1fr 1px 1fr;
  }

  p {
    margin: 0;
  }
`;

export const WorkerNameContainer = styled.div`
  grid-area: worker-name;
`;

export const MonthlyPayContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: monthly;
`;

export const HourlyPayContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: hourly;
`;

export const Options = styled.div`
  display: flex;
  align-items: flex-start;
  grid-area: options;

  @media (min-width: 640px) {
    align-items: center;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0 0 10px 0;
  }
`;

export const WorkGroupOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: workGroup-options;
`;

export const WorkGroupDetailsModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  background-color: ${Color.White};
  padding: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
`;
