import { Api } from "services/api/api.service";

export const addEntity = (
  executeApiCall: (apiCall: () => Promise<any>, onError?: (err: any) => void) => void,
  companyId: number,
  currentEntityName: string,
) => {
  executeApiCall(
    () => Api().company.addCompanyStructureEntity(companyId, { name: currentEntityName }),
    (err) => console.log("Error adding entity", err),
  )
};

export const updateEntity = (
  executeApiCall: (apiCall: () => Promise<any>, onError?: (err: any) => void) => void,
  companyId: number,
  entityId: number,
  currentEntityName: string,
) => {
  executeApiCall(
    () => Api().company.updateCompanyStructureEntity(companyId, entityId, { name: currentEntityName }),
    (err) => console.log("Error updating entity name", err),
  );
};

export const deleteEntity = (
  executeApiCall: (apiCall: () => Promise<any>, onError?: (err: any) => void) => void,
  companyId: number,
  entityId: number,
) => {
  executeApiCall(
    () => Api().company.deleteCompanyStructureEntity(companyId, entityId),
    (err) => console.log("Error removing entity", err),
  )
};
