import { useState, useRef } from "react";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import {
  SaveContent,
  SaveButtonModal,
  ColumnCenterDiv,
  TextInput,
} from "./calendarTemplatePicker.styled";
import { useTranslation } from "react-i18next";
import { CalendarTemplateDto } from "model/GigTemplate";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { PaymentMethod } from "model/Gig";
import { Modal } from "@material-ui/core";
import { useGetManyGigs } from "hooks/useGetManyGigs";
import { Api } from "services/api/api.service";
import { CustomIcon } from "components/icon/customIcon.component";

type Props = {
  setIsLoadingApi: React.Dispatch<React.SetStateAction<boolean>>;
  isSaveOpen: boolean;
  setIsSaveOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateCalendarTemplate = ({
  setIsLoadingApi,
  isSaveOpen,
  setIsSaveOpen,
}: Props) => {
  const [companyState] = useCompanyStore();
  const [calendarState] = useCalendarStore();
  const [templates, setTemplates] = useState<CalendarTemplateDto[]>([]);
  const [templateName, setTemplateName] = useState("");
  const [, , , getManyGigs] = useGetManyGigs();
  const { t } = useTranslation();

  const apiState = useRef({ loading: false, completed: false, page: 0 });

  const onSaveCalendarTemplate = () => {
    if (!calendarState.currentWeek.length || apiState.current.loading) {
      return;
    }

    apiState.current.loading = true;
    setIsLoadingApi(true);
    setIsSaveOpen(false);

    getManyGigs(calendarState.currentWeek.map((c) => c.id))
      .then((fetchedGigs) => {
        if (companyState.company?.id) {
          const newGigs = fetchedGigs.map((gig) => {
            let shifts = gig.shifts;
            let weeklyCalendarShifts = calendarState.currentWeek.find(
              (c) => c.id === gig.id
            )?.shifts;
            let filteredShifts = shifts.filter((shift) =>
              weeklyCalendarShifts?.find((matching) => matching.id === shift.id)
            );

            if (filteredShifts.length > 0) {
              return {
                ...gig,
                shifts: filteredShifts,
                id: -1,
                description: gig.description ?? "",
                paymentType: gig.paymentType ?? PaymentMethod.NONE,
              };
            }
          });

          const data: any = {
            companyId: companyState.company?.id,
            name: templateName,
            gigs: newGigs,
          };
          Api()
            .company.template.saveWeekTemplate(companyState.company.id, data)
            .then((res) => {
              setTemplates([...templates, res.data]);
            });
        }
      })
      .finally(() => {
        apiState.current.loading = false;
        setIsLoadingApi(false);
      });
  };

  return (
    <Modal open={isSaveOpen} onClose={() => setIsSaveOpen(false)}>
      <SaveContent>
        <CustomIcon
          name="cross"
          size="40px"
          onClick={() => setIsSaveOpen(false)}
          style={{ margin: "20px 20px 0 0", alignSelf: "flex-end" }}
          color={Color.LighterDestructive}
        />

        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H3}
          color={Color.PitchBlack}
          align="center"
        >
          {t("GigsCompany.CreateCalendarName")}
        </Text>
        <TextInput
          value={templateName}
          onChange={(e) => {
            setTemplateName(e.currentTarget.value);
          }}
        />
        <ColumnCenterDiv>
          <SaveButtonModal
            disabled={!templateName || templateName.trim().length < 3}
            onClick={onSaveCalendarTemplate}
            backgroundColor={Color.ModernGreen}
          >
            {t("GigsCompany.CreateCalendarTemplate")}
          </SaveButtonModal>
        </ColumnCenterDiv>
      </SaveContent>
    </Modal>
  );
};
