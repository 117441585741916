import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import {
  ButtonContainer,
  ButtonStyled,
  DashBoardCell,
  DashboardCellContent,
  DashboardCellWeekContent,
  DashBoardColumn,
  DashboardContainer,
  TitleContainer,
} from "./dashboard.styled";
import moment from "moment";
import { DashboardWeek } from "./week/dashboardWeek.component";
import { Modal } from "@material-ui/core";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { ModalBody } from "../calendar/weeklyCalendar.styled";
import { DashboardApplications } from "./week/dashboardApplications.component";
import { DashboardTimeReports } from "./week/dashboardTimeReports.component";
import { DashboardLatestRatings } from "./week/dashboardLatestRatings.component";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createGigModal, setCreateGigModal] = useState(false);

  const goToWeek = () => {
    localStorage.setItem("CalendarDate", moment().format("YYYY-MM-DD"));
    history.push("./calendar");
  };

  return (
    <div style={{marginBottom: 100}}>
      <ButtonContainer>
        <ButtonStyled
          onClick={() => setCreateGigModal(true)}
          backgroundColor={Color.ModernGreen}
        >
          {t("GigsCompany.CreateGig")}
        </ButtonStyled>
      </ButtonContainer>
      <DashboardContainer>
        <DashBoardColumn>
          <DashboardTimeReports />
          <DashboardApplications />
        </DashBoardColumn>
        <DashBoardColumn>
          <DashBoardCell>
            <CustomLink
              color={Color.MidnightBlue}
              fontSize={FontSize.H3}
              fontFamily={FontFamily.MontserratRegular}
              onClick={() => goToWeek()}
            >
              {t("CalendarCompany.Week")} {moment().week()}
            </CustomLink>
            <DashboardCellWeekContent>
              <DashboardWeek />
            </DashboardCellWeekContent>
          </DashBoardCell>
          <DashboardLatestRatings />
        </DashBoardColumn>
      </DashboardContainer>
      <Modal
        open={createGigModal}
        onClose={() => {
          setCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>{t("CalendarCompany.CreateGig")}</H3>
          <SaveGigForm setShowCreateGigModal={setCreateGigModal} />
        </ModalBody>
      </Modal>
   </div>
  );
};
