import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";

export const PhoneInputStyled = styled.input<{ fontSize?: FontSize, disabled: boolean }>`
  border: 1px solid ${Color.SeaBlue400};
  border-radius: 8px; 
  width: 100%; 
  min-width: 140px;
  max-width: 300px; 
  padding: 12px 16px;
  height: 30px;
  font-size: ${FontSize.Standard};
  color: ${(props) => props.disabled ? Color.SeaBlue400 : Color.MidnightBlue};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); 
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  ::placeholder {
    color: ${Color.SeaBlue400};
    font-weight: 300;
  }

  &:focus {
    border-color: ${Color.PitchBlack};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); 
    outline: none; 
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
