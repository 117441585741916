import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Color } from "config/colors";
import { CircularProgress } from "@material-ui/core";
import { ReactComponent as Image } from "images/icons/svg/image.svg";
import { relative } from "path";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  name: string;
  title?: string;
  size?: string;
  color?: Color;
  backgroundColor?: Color;
  padding?: string;
  disabled?: boolean;
  hover?: boolean;
  square?: boolean;
  width?: string;
  marginTop?: string;
};

export const CustomIcon: React.FC<Props> = ({ name, title, ...rest }) => {
  const ImportedIconRef = useRef<React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const getIcon = async () => {
      try {
        const res = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!images/icons/svg/${name}.svg`
        );
        ImportedIconRef.current = res.default;
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getIcon();
  }, [name]);

  const renderIcon = () => {
    const { current: Icon } = ImportedIconRef;

    if (loading) {
      return <CircularProgress style={{ width: "100%", height: "100%" }} />;
    } else {
      if (Icon) {
        return <Icon title={title}/>;
      } else {
        <Image />;
      }
    }
  };

  return (
    <IconWrapper {...rest} onMouseDown={(e) => e.preventDefault()}>
      {renderIcon()}
    </IconWrapper>
  );
};

const IconWrapper = styled.div<Omit<Props, "name">>`
  width: ${(props) =>
    props.width ? `${props.width}` : props.size ? `${props.size}` : "30px"};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}` : null)};
  height: ${(props) => (props.size ? `${props.size}` : "30px")};
  border-radius: ${(props) => (props.square ? `8px` : "50%")};
  background-color: ${(props) =>
    props.disabled
      ? Color.Disabled
      : props.backgroundColor
      ? props.backgroundColor
      : "none"};
  padding: ${(props) => props.padding && `${props.padding}`};
  transition: opacity 0.15s;
  stroke: ${(props) =>
    props.disabled
      ? Color.SeaBlue600
      : props.color
      ? props.color
      : Color.PitchBlack};

  ${(props) =>
    !props.disabled &&
    (props.onClick || props.hover) &&
    `&:hover {
      cursor: pointer;
      opacity: 0.8;
    }`}

  svg {
    width: 100%;
    height: 100%;
  }

  svg[fill],
  svg *[fill] {
    fill-opacity: initial;
    fill: ${(props) =>
      props.disabled
        ? Color.SeaBlue600
        : props.color
        ? props.color
        : Color.PitchBlack};
  }

  svg[fill="none"],
  svg *[fill="none"] {
    fill-opacity: 0;
    fill: ${(props) =>
      props.disabled
        ? Color.SeaBlue600
        : props.color
        ? props.color
        : Color.PitchBlack};
  }
`;
