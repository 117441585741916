import React from 'react';
import { LocalTime, Instant, LocalDateTime } from '@js-joda/core';
import moment from 'moment';
import { calculateBreakTime } from 'utils/utils';
import { TimeReportDto, TimeReportResolution } from 'model/TimeReport';
import { Cell, RoleText, RoleWrapper, TimeReportsGridBody } from '../monthlyOverview.styled';
import { FontSize } from 'config/font';
import { Text } from "components/Typography/text.styled";
import { CustomIcon } from 'components/icon/customIcon.component';
import { Color } from 'config/colors';
import { WorkerTimeReportStatus } from './workerGigTimeReportStatus.component';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface TimeReportRowProps {
  timeReport: TimeReportDto;
  calculatedDetails: {
    startTime: LocalDateTime;
    endTime: LocalDateTime;
    shiftTimeString: string;
    weekDayString: string;
    shiftTotalMinutes: number;
  };
  setChosenTimeReportWithWorker: (timeReport: TimeReportDto) => void;
  setModalOpen: (open: boolean) => void;
  addLeadingZero: (num: number) => string;
}

const TimeReportRow: React.FC<TimeReportRowProps> = ({
  timeReport,
  calculatedDetails,
  setChosenTimeReportWithWorker,
  setModalOpen,
  addLeadingZero,
}) => {
  const { t } = useTranslation()
  const history = useHistory();
  
  return (
    <TimeReportsGridBody
      onClick={() => {
        setChosenTimeReportWithWorker(timeReport);
        setModalOpen(true);
      }}
    >
      <Cell>
        <Text fontSize={FontSize.Large}>{`${t(
          calculatedDetails.weekDayString
        )} ${calculatedDetails.startTime.dayOfMonth()}/${calculatedDetails.startTime.monthValue()}`}</Text>
      </Cell>
      <Cell>
        <Text fontSize={FontSize.Large}>
          {`${addLeadingZero(calculatedDetails.startTime.hour())}:${addLeadingZero(
            calculatedDetails.startTime.minute()
          )} - ${addLeadingZero(calculatedDetails.endTime.hour())}:${addLeadingZero(
            calculatedDetails.endTime.minute()
          )}`}
        </Text>
      </Cell>
      <Cell>
        <RoleWrapper
          onClick={(e) => {
            e.stopPropagation();

            if (window.location.href.indexOf(timeReport.gigId.toString()) !== -1) {
              history.push(`../gig/${timeReport.gigId}`);
            } else {
              history.push(`./gig/${timeReport.gigId}`);
            }
          }}
        >
          <RoleText fontSize={FontSize.Standard}>{timeReport?.gigRole}</RoleText>
          <CustomIcon name="document" color={Color.White} size="20px" />
        </RoleWrapper>
      </Cell>
      <Cell>
        <Text fontSize={FontSize.Large}>
          {timeReport.breakStartTime && timeReport.breakEndTime
            ? calculateBreakTime(
                LocalTime.ofInstant(Instant.parse(timeReport.breakStartTime))
                  .toString()
                  .slice(0, 5),
                LocalTime.ofInstant(Instant.parse(timeReport.breakEndTime))
                  .toString()
                  .slice(0, 5),
                moment(
                  LocalDateTime.ofInstant(Instant.parse(timeReport.startTime)).toString()
                ),
                moment(
                  LocalDateTime.ofInstant(Instant.parse(timeReport.endTime)).toString()
                )
              )
            : 0}
          {t("General.MinutesShortened")}
        </Text>
      </Cell>
      <Cell>
        <Text fontSize={FontSize.Large}>{calculatedDetails.shiftTimeString}</Text>
      </Cell>
      <Cell>{WorkerTimeReportStatus(timeReport.status)}</Cell>
      <Cell>
        {timeReport.resolution !== TimeReportResolution.UNDEFINED && (
          <Text fontSize={FontSize.Large}>
            {t(`CompanyTimeReport.${timeReport.resolution}`)}
          </Text>
        )}
      </Cell>
    </TimeReportsGridBody>
  );
};

export default TimeReportRow;
