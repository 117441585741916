import { useState } from "react";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { ButtonsContainerRight, HeaderRow } from "./workgroup.styled";
import { WorkGroupAddWorkerModal } from "./workgroupAddWorkerModal.component";
import { WorkGroupInviteWorkerModal } from "./workgroupInviteWorkerModal.component";
import { WorkGroupCreateWorkGroupModal } from "./workgroupCreateWorkGroupModal.component";
import { Color } from "config/colors";
import { WorkGroupRow } from "./workgroupRow.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";

export const Workgroup = () => {
  const [showInviteWorkerModal, setShowInviteWorkerModal] = useState(false);
  const [showAddWorkerModal, setShowAddWorkerModal] = useState(false);
  const [showCreateWorkGroupModal, setShowCreateWorkGroupModal] =
    useState(false);
  const { t } = useTranslation();
  const [state] = useWorkgroupStore();

  return (
    <>
      <HeaderRow style={{ marginBottom: 40 }}>
        <ButtonStyled
          backgroundColor={Color.ModernGreen}
          style={{ marginLeft: 0 }}
          onClick={() => setShowCreateWorkGroupModal(true)}
        >
          {t("WorkGroup.CreateWorkgroup")}
        </ButtonStyled>
        <ButtonsContainerRight>
          <ButtonStyled
            backgroundColor={Color.ModernGreen}
            style={{ marginRight: 10 }}
            onClick={() => setShowInviteWorkerModal(true)}
          >
            {t("WorkGroup.Invite")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.ModernGreen}
            style={{ marginRight: 10 }}
            onClick={() => setShowAddWorkerModal(true)}
          >
            {t("WorkGroup.Add")}
          </ButtonStyled>
        </ButtonsContainerRight>
      </HeaderRow>
      {state.workgroups && (
        <>
          {state.workgroups.map((workgroup) => {
            return <WorkGroupRow workgroup={workgroup} />;
          })}
          {showInviteWorkerModal && (
            <WorkGroupInviteWorkerModal
              showInviteWorkerModal={showInviteWorkerModal}
              setShowInviteWorkerModal={setShowInviteWorkerModal}
              workGroups={state.workgroups}
            />
          )}
          {showAddWorkerModal && (
            <WorkGroupAddWorkerModal
              showAddWorkerModal={showAddWorkerModal}
              setShowAddWorkerModal={setShowAddWorkerModal}
              workGroups={state.workgroups}
            />
          )}
          {showCreateWorkGroupModal && (
            <WorkGroupCreateWorkGroupModal
              showCreateWorkGroupModal={showCreateWorkGroupModal}
              setShowCreateWorkGroupModal={setShowCreateWorkGroupModal}
            />
          )}
        </>
      )}
    </>
  );
};
