import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { H4 } from "components/Typography/text.styled";
import { FontFamily } from "config/font";
import { DividerLine } from "../companyStampClock/companyStampClock.styled";
import { TermsLink } from "web-apps/company/pages/payments/simflexityPay/simflexityPay.styled";
import { SimflexityPayTerms } from "web-apps/company/pages/payments/simflexityPay/simflexityPayTerms";
import { Color } from "config/colors";
import { TermsWebLink } from "./termsWebLink.component";

export const SubscriptionTerms: React.FC = () => {
  const { t } = useTranslation();

  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      <H4 fontFamily={FontFamily.LeagueSpartanBold}>
        {t("ProfileCompany.TermsAndConditions")}
      </H4>

      <TermsWebLink
        selectedTerms={t("General.TermsAndConditionsLink")}
        linkName={t("ProfileCompany.GeneralTerms")}
        color={Color.BurntSienna}
        bold={true}
      />

      <TermsWebLink
        selectedTerms={t("General.PersonalIntegrityLink")}
        linkName={t("ProfileCompany.PrivacyPolicy")}
        color={Color.BurntSienna}
        bold={true}
      />

      <TermsLink onClick={() => setShowTerms(true)}>
        {t("ProfileCompany.TermsSimflexityPay")}
      </TermsLink>

      <SimflexityPayTerms setShowTerms={setShowTerms} showTerms={showTerms} />

      <DividerLine />
    </>
  );
};
