import { AxiosResponse } from "axios";
import { Api } from "services/api/api.service";
import { WorkgroupDispatch } from "./workgroupStore";
import { WorkgroupState } from "./workgroupStore.reducer";
import { WorkGroupActions } from "model/Workgroup";

const deleteWorkerFromWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number,
    workerId: number
  ): Promise<AxiosResponse<any> | void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      const res = await Api().company.workgroup.deleteWorkerFromWorkGroup(
        companyId,
        workGroupId,
        workerId
      );

      dispatch({
        type: WorkGroupActions.DelteWorkerFromWorkgroup,
        payload: { workGroupId, workerId },
      });

      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.resolve(res);
    } catch (e) {
      console.error("Error when deleting worker from workgroup", e);
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.reject(e);
    }
  };

const deleteWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number
  ): Promise<AxiosResponse<any> | void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.deleteWorkGroup(companyId, workGroupId)
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.DeleteWorkgroup,
          payload: workGroupId,
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when deleting workgroup", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const deleteInvitation =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    inviteId: number,
    workGroupId: number
  ): Promise<AxiosResponse<any> | void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.deleteInvitation(companyId, inviteId)
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.DeleteInvitation,
          payload: { workGroupId, inviteId: inviteId },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when deleting workgroup", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const addWorkerToWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number,
    workerId: number,
    hourlyPay: number,
    monthlyPay: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    try {
      const res = await Api().company.workgroup.addWorkerToWorkGroup(
        companyId,
        workGroupId,
        workerId,
        hourlyPay,
        monthlyPay
      );
      dispatch({
        type: WorkGroupActions.AddWorkerToWorkgroup,
        payload: {
          workGroupId,
          worker: res.data,
        },
      });

      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.resolve(res);
    } catch (e: any) {
      console.error("Error when adding new member", e);
      dispatch({ type: "SET_IS_LOADING", payload: false });
      let errorMessage = e.message;

      if (e.response.data.errorMessage) {
        errorMessage = e.response.data.errorMessage;
      }

      dispatch({
        type: "SET_REQUEST_ERROR",
        payload: { message: errorMessage, err: e },
      });
      return Promise.reject(e);
    }
  };

const inviteWorkerToWorkGroup =
  (dispatch: WorkgroupDispatch, state: WorkgroupState) =>
  async (
    companyId: number,
    workGroupId: number,
    phonenumber: string,
    hourlyPay: number,
    monthlyPay: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.inviteWorkerToWorkGroup(
        companyId,
        workGroupId,
        phonenumber,
        hourlyPay,
        monthlyPay
      )
      .then((res: any) => {
        const currentInvited = state.invited[workGroupId] || [];

        const newInvited = [
          ...currentInvited.filter((i) => i.id !== res.data.workerId),
          res.data,
        ];
        dispatch({
          type: WorkGroupActions.InviteWorkerToWorkgroup,
          payload: { workGroupId, invited: newInvited },
        });

        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: any) => {
        dispatch({ type: "SET_IS_LOADING", payload: false });
        console.error("Error inviting new member", e);
        let errorMessage = e.message;

        if (e.response.data.errorMessage) {
          errorMessage = e.response.data.errorMessage;
        }

        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: errorMessage, err: e },
        });
        return Promise.reject(e);
      });

    return res;
  };

const getAllMembersInWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.getWorkGroupMembers(companyId, workGroupId)
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.GetAllMembersInWorkgroup,
          payload: { workGroupId, members: res.data },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when getting workgroup members", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const getAllInvitedInWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.getInvitedToWorkGroup(companyId, workGroupId)
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.GetAllInvitedInWorkgroup,
          payload: { workGroupId, invited: res.data },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when getting invited", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const getAllDeclinedInWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.getInvitedToWorkGroup(companyId, workGroupId)
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.GetAllDeclinedInWorkgroup,
          payload: { workGroupId, declined: res.data },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when getting declined", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const updateWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number,
    setting: string,
    value: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.updateWorkGroup(companyId, workGroupId, setting, value)
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.UpdateWorkgroup,
          payload: res.data,
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating workgroup", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const updateWorkerInWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    workGroupId: number,
    workerId: number,
    setting: string,
    value: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.updateWorkGroupWorker(
        companyId,
        workGroupId,
        workerId,
        setting,
        value
      )
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.UpdateWorkerInWorkgroup,
          payload: { workGroupId, updatedWorker: res.data },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating worker", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const createWorkGroup =
  (dispatch: WorkgroupDispatch) =>
  async (
    companyId: number,
    name: string,
    publicSearch: false,
    hourlyPay: number,
    monthlyPay: number
  ): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workgroup.createWorkGroup(
        companyId,
        name,
        publicSearch,
        hourlyPay,
        monthlyPay
      )
      .then((res: any) => {
        dispatch({
          type: WorkGroupActions.CreateWorkgroup,
          payload: res.data,
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when creating workgroup", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

export const workgroupActions = (
  dispatch: WorkgroupDispatch,
  state: WorkgroupState
) => ({
  deleteWorkerFromWorkGroup: deleteWorkerFromWorkGroup(dispatch),
  deleteWorkGroup: deleteWorkGroup(dispatch),
  addWorkerToWorkGroup: addWorkerToWorkGroup(dispatch),
  inviteWorkerToWorkGroup: inviteWorkerToWorkGroup(dispatch, state),
  getAllMembersInWorkGroup: getAllMembersInWorkGroup(dispatch),
  getAllInvitedInWorkGroup: getAllInvitedInWorkGroup(dispatch),
  getAllDeclinedInWorkGroup: getAllDeclinedInWorkGroup(dispatch),
  updateWorkGroup: updateWorkGroup(dispatch),
  updateWorkerInWorkGroup: updateWorkerInWorkGroup(dispatch),
  createWorkGroup: createWorkGroup(dispatch),
  deleteInvitation: deleteInvitation(dispatch),
});
