import React from "react";
import { CompanyGigDto } from "model/Gig";
import { Cell, DividerLine1, DividerLine2 } from "./gigs.styled";
import { WorkerWithUrl } from "model/Company";
import { GigShiftList } from "./gigShiftList.component";
import { GigInfo } from "./gigInfoContainer.component";
import { GigOptions } from "./gigOptions.component";
import { GigWorkerCount } from "./gigWorkerCount.component";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
  workerWithImageUrl?: WorkerWithUrl[];
};

export const Gig: React.FC<Props> = ({
  gigData,
  onGigClosed,
  workerWithImageUrl,
}) => {
  return (
    <>
      <Cell>
        <GigInfo gigData={gigData} />
        <DividerLine1 />
        <GigShiftList gigData={gigData} />
        <DividerLine2 />
        <GigWorkerCount
          workerWithImageUrl={workerWithImageUrl}
          gigData={gigData}
          isActive
        />
        <GigOptions gigData={gigData} onGigClosed={onGigClosed} />
      </Cell>
    </>
  );
};
