import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { TFunction } from "i18next";
import { Salary } from "model/Salary";

export function getFirstAndLastDatesOfMonth(date: LocalDate): { firstDate: string, lastDate: string } {
  const firstDate = date.withDayOfMonth(1).format(DateTimeFormatter.ofPattern('yyyyMMdd'));
  const lastDate = date.withDayOfMonth(date.lengthOfMonth()).format(DateTimeFormatter.ofPattern('yyyyMMdd'));

  return {
    firstDate,
    lastDate
  };
}

export function getHospitalityBrokenMonthDates(date: LocalDate): { firstDate: string, lastDate: string } {
  const previousMonth = date.minusMonths(1);
  const firstDate = previousMonth.withDayOfMonth(21).format(DateTimeFormatter.ofPattern('yyyyMMdd'));
  const lastDate = date.withDayOfMonth(20).format(DateTimeFormatter.ofPattern('yyyyMMdd'));

  return {
    firstDate,
    lastDate
  };
}

export function getMonthRangeString(useHospitalitySetting: boolean, firstDate: string, lastDate: string, firstBrokenMonthDate: string, lastBrokenMonthDate: string) {
  return useHospitalitySetting === true ? `${formatDateString(firstDate)}-${formatDateString(lastDate)}` : `${formatDateString(firstBrokenMonthDate)}-${formatDateString(lastBrokenMonthDate)}`;
}

function formatDateString(dateString: string): string {
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${day}/${month}`;
}

export const extractCSV = (
  t: TFunction,
  fullName: string,
  data: Salary,
  from: string,
  to: string
) => {
  const csvData = [
    [fullName],
    [t('Salary.PersonalIdentityNumber'), data.personalIdentityNumber],
    [t('Salary.TotalWorkTime'), data.totalWorkTime],
    [t('Salary.TotalInconvenientTime'), data.totalInconvenientTime],
    [t('Salary.TotalAbsenceTime'), data.totalAbsenceTime],
    [t('Salary.TotalGrossSalary'), data.totalGrossSalary],
    [t('Salary.GrossSalary'), data.grossSalary],
    [t('Salary.SocialFee'), data.socialFee],
    [t('Salary.InconvenientTime')],
    [t('Salary.InconvenientTimeSalary'), data.inconvenientTimeSalary],
    ...data.inconvenientTime.flatMap((ot, index) => [
      ...(data.inconvenientTimeSalary !== ot.amount ? [[`${t('Salary.OB')}${index + 1} ${t('Salary.Amount')}`, ot.amount]] : []),
      [`${t('Salary.OB')}${index + 1} ${t('Salary.Time')}`, ot.time],
      [`${t('Salary.OB')}${index + 1} ${t('Salary.Rate')} ${ot.percentage ? "(%)" : ""}`, ot.rate],
    ]),
    [t('Salary.Absence')],
    ...Object.entries(data.absence).flatMap(([key, value]) => [
      [`${t(`Salary.${key}`)}`, value.time],
      ...value.dates.map((date, index) => [`${t(`Salary.${key}`)} ${t('Salary.Dates')} ${index + 1}`, date]),
    ]),
  ];

  const csvContent = csvData.map(e => e.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', `${data.firstName}_${data.lastName}_${from}_${to}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
