import React, { useEffect, useState } from "react";
import { LocalDate, Month } from "@js-joda/core";
import { useTranslation } from "react-i18next";
import {
  TimeReportDto,
  TotalCostMonthly,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { WorkerTimeReports } from "./workerTimeReports.component";
import { TimeReportModal } from "./timeReportModal.component";
import {
  MonthSelectContainer,
  ArrowSign,
  WorkerTimeReportContainer,
  NoTimeReportsWrapper,
  CircularProgressWrapper,
  RowSpaceBetween,
  Row,
  TotalCostWrapper,
  RowSimple,
  RowItem,
} from "./monthlyOverview.styled";
import { Text, H4 } from "components/Typography/text.styled";
import { RatingModal } from "./ratingModal.component";
import { EndorseModal } from "./endorsements/endorseModal.component";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgress } from "@material-ui/core";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { exportAllToCSV, exportToCSV } from "utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { TotalTimeDetails } from "./totalTimeDetails.component";
import { Link } from "react-router-dom";
import { WorkerTimeReportsFooter } from "./workerTimeReportsFooter.component";
import { SalaryModal } from "./salary/salaryModal.component";
import { CompanyWorkerDto } from "model/Company";
import { fetchWorker } from "web-apps/company/utils/utils";
import { WorkerDetail } from "./workers/workerDetail.component";

const emptyTotalCosts = {
  approved: {
    simflexityFeeTotal: 0,
    grossCost: 0,
    inconvenientTimeCost: 0,
    socialFeeCost: 0,
    total: 0,
  },
  planned: {
    simflexityFeeTotal: 0,
    grossCost: 0,
    inconvenientTimeCost: 0,
    socialFeeCost: 0,
    total: 0,
  },
};

export const MonthlyOverview: React.FC = () => {
  const { t } = useTranslation();
  const [chosenMonth, setChosenMonth] = useState<Month>(
    LocalDate.now().month()
  );
  const [currentDate, setCurrentDate] = useState<LocalDate>(LocalDate.now());
  const [chosenTimeReportWithWorker, setChosenTimeReportWithWorker] =
    useState<TimeReportDto | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);
  const [totalCostMonthly, setTotalCostMonthly] =
    useState<TotalCostMonthly | null>(emptyTotalCosts);
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(true);
  const [timeReportState, , timeReportDispatch] = useTimeReportStore();
  const [timeReports, setTimeReports] = useState<WorkerWithTimeReports[]>();
  const companyId = companyState.company?.id;
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedWorkerId, setSelectedWorkerId] = useState(-1);
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [worker, setWorker] = useState<CompanyWorkerDto>();

  useEffect(() => {
    setChosenMonth(currentDate.month());
  }, [currentDate]);

  const getTimeReports = () => {
    setIsLoading(true);

    const period = `${currentDate.year()}${currentDate
      .monthValue()
      .toString()
      .padStart(2, "0")}`;

    if (companyId) {
      Api()
        .company.timeReport.getAllTimeReports(companyId, parseInt(period))
        .then((res) => {
          setIsLoading(false);
          setTimeReports(res.data);
          timeReportDispatch({
            type: "UPDATE_MONTHLY_TIME_REPORTS",
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log("Error fetching timereports", err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getTimeReports();
  }, [currentDate, companyState.company?.id]);

  useEffect(() => {
    setWorkerModalOpen(true);
  }, [worker]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TotalTimeDetails
          month={currentDate.monthValue()}
          chosenMonth={chosenMonth}
          year={currentDate.year()}
          setTotalCostMonthly={setTotalCostMonthly}
        />
        <MonthSelectContainer>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: any) => prevState.minusMonths(1));
            }}
          >
            &#60;
          </ArrowSign>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
          >
            {t(`CompanyTimeReport.${chosenMonth}`) + " " + currentDate.year()}
          </Text>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: any) => prevState.plusMonths(1));
            }}
          >
            &#62;
          </ArrowSign>
        </MonthSelectContainer>
        <TotalCostWrapper>
          <Text color={Color.White}>
            {t("CompanyTimeReport.TotalCostAll")}:{" "}
            {totalCostMonthly?.planned.total} kr
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalCostApproved")}:{" "}
            {totalCostMonthly?.approved.total} kr
          </Text>
        </TotalCostWrapper>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row
          onClick={async () => {
            if (companyState.company?.companyName) {
              setIsLoading(true);
              await exportAllToCSV(
                timeReportState.monthlyTimeReports,
                `${chosenMonth}_Time reports`,
                t,
                companyState.company.id
              );
              setIsLoading(false);
            }
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.MidnightBlue}
            fontSize={FontSize.Small}
          >
            {t("CompanyTimeReport.ExportCSVForAll")}
          </Text>
          <CustomIcon name="download" size="25px" color={Color.MidnightBlue} />
        </Row>
      </div>
      {!isLoading &&
        timeReports &&
        timeReports.map((timeReportData) => {
          return (
            <WorkerTimeReportContainer key={timeReportData.id}>
              <RowSpaceBetween>
                <RowSimple>
                  <RowItem>
                    <H4 onClick={() => {
                          fetchWorker(timeReportData.id, companyState)?.then(
                            (worker) => setWorker(worker.data)
                          );
                        }}>
                      {`${timeReportData.firstName} ${timeReportData.lastName}`}
                      <CustomIcon style={{ paddingBottom: 4 }}
                        name="person"
                        size="24px"
                        color={Color.MidnightBlue}
                      />
                    </H4>
                  </RowItem>
                  <RowItem
                    onClick={async () => {
                      setSelectedWorkerId(timeReportData.id);
                      setShowPaymentModal(true);
                    }}
                  >
                    <H4>
                      <CustomIcon style={{ paddingBottom: 4 }}
                        name="bank"
                        size="24px"
                        color={Color.MidnightBlue}
                      />
                    </H4>
                  </RowItem>
                </RowSimple>

                <Row
                  onClick={async () => {
                    if (companyState.company?.companyName) {
                      setIsLoading(true);
                      await exportToCSV(
                        timeReportData,
                        `${timeReportData.firstName} ${
                          timeReportData.lastName
                        }_${chosenMonth}${currentDate.year()}_${
                          companyState.company.companyName
                        }`,
                        companyState.company.id,
                        t
                      );
                      setIsLoading(false);
                    }
                  }}
                >
                  <Text
                    fontFamily={FontFamily.MontserratSemiBold}
                    color={Color.MidnightBlue}
                    fontSize={FontSize.Small}
                  >
                    {t("CompanyTimeReport.ExportCSV")}
                  </Text>
                  <CustomIcon
                    name="download"
                    size="25px"
                    color={Color.MidnightBlue}
                  />
                </Row>
              </RowSpaceBetween>
              <WorkerTimeReports
                monthlyWorkerWithTimeReports={timeReportData}
                setChosenTimeReportWithWorker={setChosenTimeReportWithWorker}
                setModalOpen={setModalOpen}
                showCheckInCheckOutColumns
                month={currentDate.monthValue()}
                year={currentDate.year()}
              />
              {!isLoading && currentDate.monthValue() && currentDate.year() && (
                <WorkerTimeReportsFooter
                  workerId={timeReportData.id}
                  month={currentDate.monthValue()}
                  year={currentDate.year()}
                />
              )}
            </WorkerTimeReportContainer>
          );
        })}
      {isLoading && (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      )}
      {timeReports && timeReports.length === 0 && !isLoading && (
        <NoTimeReportsWrapper>
          <H4>{t("CompanyTimeReport.NoTimeReports")}</H4>
          <Text>{t("CompanyTimeReport.NoTimeReportsForThisMonth")}</Text>
        </NoTimeReportsWrapper>
      )}
      {chosenTimeReportWithWorker && (
        <TimeReportModal
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRatingModalOpen={setRatingModalOpen}
        />
      )}
      {ratingModalOpen && chosenTimeReportWithWorker && (
        <RatingModal
          ratingModalOpen={ratingModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          setEndorseModalOpen={setEndorseModalOpen}
        />
      )}
      {endorseModalOpen && chosenTimeReportWithWorker && (
        <EndorseModal
          endorseModalOpen={endorseModalOpen}
          setEndorseModalOpen={setEndorseModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
        />
      )}
      {selectedWorkerId >= 0 && showPaymentModal && (
        <SalaryModal
          setShowPaymentModal={setShowPaymentModal}
          showPaymentModal={showPaymentModal}
          workerId={selectedWorkerId}
          currentDate={currentDate}
        />
      )}
      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
        />
      )}
    </>
  );
};
