import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { CompanyGigDto, GigResolution, GigStatus } from "model/Gig";

import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { ApplicationResolution, ApplicationStatus } from "model/Application";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Text } from "components/Typography/text.styled";
import { ModalBody, ModalButtonContainer } from "./gigs.styled";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { Api } from "services/api/api.service";
import { LinkWrapper } from "../timeReport/timeReportModal.styled";
import { CustomIcon } from "components/icon/customIcon.component";

type Props = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
};

export const CloseGigModal: React.FC<Props> = ({
  setModalOpen,
  modalOpen,
  gigData,
  onGigClosed,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [applicationState, applicationActions] = useApplicationStore();
  const [, calendarActions] = useCalendarStore();

  const closeGig = () => {
    if (companyState.company) {
      Api()
        .company.gig.saveGig(
          companyState.company.id,
          {
            ...gigData,
            status: GigStatus.CLOSED,
            resolution: GigResolution.WITHDRAWN,
          },
          gigData.id
        )
        .then(() => {
          rejectGigApplications();
          setModalOpen(false);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              title: t("GigsCompany.Closed"),
              message: t("GigsCompany.GigHasBeenClosed"),
              icon: "checkmark",
            },
          });
          onGigClosed?.(gigData);
          calendarActions.removeGigFromCalendarContext(gigData.id);
          calendarActions.removeGigFromCalendarContextOverview(gigData.id);
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              title: t("GigsCompany.OhNo"),
              message: t("GigsCompany.GigCouldNotBeClosed"),
              icon: "alert",
            },
          });
        });
    }
  };

  const rejectGigApplications = () => {
    applicationState.applications
      .filter((application) => application.gigId === gigData.id)
      .forEach((application) => {
        if (companyState.company) {
          applicationActions.updateApplicationStatus(
            companyState.company.id,
            application.id,
            {
              ...application,
              status: ApplicationStatus.CLOSED,
              resolution: ApplicationResolution.REJECTED,
            }
          );
        }
      });
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setModalOpen(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.AreYouSureCloseGig")}
          </Text>
          <ModalButtonContainer>
            <ButtonStyled
              backgroundColor={Color.LighterDestructive}
              onClick={closeGig}
            >
              {t("General.Close")}
            </ButtonStyled>
          </ModalButtonContainer>
        </ModalBody>
      </Modal>
    </>
  );
};
