import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { fetchWorker, getWorkerImages } from "web-apps/company/utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { SmallImage } from "../gig/gigs.styled";
import { Text } from "components/Typography/text.styled";
import {
  ImageContainer,
  ImageWrapper,
  WorkerContainer,
  DropDownRow,
  Container,
} from "./gigDetail.styled";
import { Modal } from "@material-ui/core";
import { OfferModal } from "./offerModal.component";
import { CompanyWorkerDto } from "model/Company";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { WorkerDetail } from "../timeReport/workers/workerDetail.component";
import { IconCircularContainer } from "../dashboard/dashboard.styled";

type Props = {
  gigData: CompanyGigDto;
};

export const GigDetailWorkers: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<string[]>([]);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerHourlyRate, setOfferHourlyRate] = useState(gigData.maxRate);
  const [offerList, setOfferList] = useState<
    {
      workerId: number;
      rate: number;
      usingOfferRate: boolean;
      type: "favorite" | "workgroup";
    }[]
  >([]);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);
  const [workersOpen, setWorkersOpen] = useState(true);
  const [workerIds, setWorkerIds] = useState<(number | null)[]>([]);
  const [worker, setWorker] = useState<CompanyWorkerDto>();
  const [companyState] = useCompanyStore();
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const lastShiftEndTime = gigData.shifts[gigData.shifts.length - 1]?.endTime;
  const isShiftOngoing = new Date(lastShiftEndTime).getTime() > Date.now();
  const [hoverOffer, setHoverOffer] = useState(false);

  useEffect(() => {
    if (!gigData.workers) return;

    const workerFirebaseIds = gigData.workers.map(
      (worker) => worker.firebaseId
    );
    const workerIds = gigData.workers.map((worker) => worker.id);
    const workerImageUrls = Array(gigData.amountOfWorkers).fill(null);

    getWorkerImages(workerFirebaseIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setWorkerImages(workerImageUrls);
      setWorkerIds(workerIds);
    });
  }, [gigData.workers]);

  useEffect(() => {
    setWorkerModalOpen(true);
  }, [worker]);

  return (
    <>
      <DropDownRow
        onClick={() => {
          setWorkersOpen(!workersOpen);
        }}
      >
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          {`${t("GigsCompany.Worker")} (${gigData.amountOfWorkers} st)`}
        </Text>
        <CustomIcon
          name={workersOpen ? "upward_arrow" : "downward_arrow"}
          size="24px"
          color={Color.BurntSienna}
          style={{ marginTop: 10 }}
        />
      </DropDownRow>
      {workersOpen && (
        <>
          <Container>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("General.Giggers")}
            </Text>
            <IconCircularContainer
              onClick={() => {
                if (isShiftOngoing) {
                  setOfferModalOpen(true);
                }
              }}
              isAccept
              style={{ marginTop: 12, marginLeft: 10 }}
              isSmall
              onMouseEnter={() => setHoverOffer(true)}
              onMouseLeave={() => setHoverOffer(false)}
              isDisabled={!isShiftOngoing}
            >
              <CustomIcon name="addPerson" size="20px" color={Color.White} />
            </IconCircularContainer>
            {hoverOffer && (
              <Text
                fontSize={FontSize.Small}
                color={Color.Black}
                style={{
                  marginLeft: 10,
                  backgroundColor: Color.LightGrey03,
                  padding: 4,
                  borderRadius: 4,
                }}
              >
                {t("GigsCompany.SendOffer")}
              </Text>
            )}
          </Container>
          <WorkerContainer>
            <ImageContainer>
              {workerImages.map((url, index) => {
                return (
                  <ImageWrapper key={index}>
                    {url ? (
                      <SmallImage
                        src={url}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const workerId = workerIds[index];
                          if (typeof workerId === "number") {
                            fetchWorker(workerId, companyState).then(
                              (worker) => {
                                setWorker(worker.data);
                              }
                            );
                          }
                        }}
                      />
                    ) : (
                      <CustomIcon
                        name="person"
                        size="34px"
                        color={Color.SeaBlue500}
                        style={{
                          cursor: isShiftOngoing ? "pointer" : "auto",
                          margin: "0 auto",
                          padding: 4,
                          marginTop: 2,
                        }}
                        onClick={() => {
                          if (isShiftOngoing) {
                            setOfferModalOpen(true);
                          }
                        }}
                      />
                    )}
                  </ImageWrapper>
                );
              })}
            </ImageContainer>
          </WorkerContainer>
        </>
      )}
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={gigData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
        />
      </Modal>
      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
        />
      )}
    </>
  );
};
