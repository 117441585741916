import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Costs, CostTotal, TimeTotal } from "model/TimeReport";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import {
  TimeReportsGridFooter,
  Cell,
  HeadCellFooter,
} from "./monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { formatDuration } from "utils/utils";
import { TimeReportFooterHeader } from "./workerGig/workerGigTimeReportFooterHeader.component";
import TotalCostFooterRow from "./workerGig/workerGigTimeReportFooterTotalCostRow.component";

type Props = {
  workerId: number;
  month: number;
  year: number;
};

const emptyCosts = {
  simflexityFeeTotal: 0,
  grossCost: 0,
  inconvenientTimeCost: 0,
  socialFeeCost: 0,
  total: 0,
};

export const WorkerTimeReportsFooter: React.FC<Props> = ({
  workerId,
  month,
  year,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [showAbsenceDetails, setShowAbsenceDetails] = useState(false);
  const [timeData, setTimeData] = useState({
    totalTime: "0h 0min",
    totalTimeApproved: "0h 0min",
    totalTimeSubmitted: "0h 0min",
    totalTimeLeft: "0h 0min",
    totalTimeAbsence: "0h 0min",
    totalTimeLeaveOfAbsence: "0h 0min",
    totalTimeVacation: "0h 0min",
    totalTimeSickLeave: "0h 0min",
    totalTimeSickLeaveChild: "0h 0min",
    totalTimeOther: "0h 0min",
    totalTimeParentalLeave: "0h 0min",
    totalApprovedOBTime: "0h 0min",
    totalPlannedOBTime: "Oh 0min",
  });
  const [costPlannedData, setPlannedCostData] = useState(emptyCosts);
  const [costApprovedData, setApprovedCostData] = useState(emptyCosts);
  const [currentTimeTotal, setCurrentTimeTotal] = useState<TimeTotal>();
  const [currentCostPlannedTotal, setCurrentCostPlannedTotal] =
    useState<Costs>();
  const [currentCostApprovedTotal, setCurrentCostApprovedTotal] =
    useState<Costs>();

  useEffect(() => {
    if (currentCostPlannedTotal) {
      setPlannedCostData({
        simflexityFeeTotal: currentCostPlannedTotal.simflexityFeeTotal,
        grossCost: currentCostPlannedTotal.grossCost,
        inconvenientTimeCost: currentCostPlannedTotal.inconvenientTimeCost,
        socialFeeCost: currentCostPlannedTotal.socialFeeCost,
        total: currentCostPlannedTotal.total,
      });
    }
    if (currentCostApprovedTotal) {
      setApprovedCostData({
        simflexityFeeTotal: currentCostApprovedTotal.simflexityFeeTotal,
        grossCost: currentCostApprovedTotal.grossCost,
        inconvenientTimeCost: currentCostApprovedTotal.inconvenientTimeCost,
        socialFeeCost: currentCostApprovedTotal.socialFeeCost,
        total: currentCostApprovedTotal.total,
      });
    }
  }, [currentCostPlannedTotal, currentCostApprovedTotal]);

  useEffect(() => {
    if (currentTimeTotal) {
      setTimeData({
        totalTime: formatDuration(currentTimeTotal.plannedTime),
        totalTimeApproved: formatDuration(currentTimeTotal.approvedTime),
        totalTimeSubmitted: formatDuration(currentTimeTotal.submittedTime),
        totalTimeLeft: formatDuration(currentTimeTotal.remainingTime),
        totalTimeAbsence: formatDuration(currentTimeTotal.absenceTime),
        totalTimeLeaveOfAbsence: formatDuration(
          currentTimeTotal.absenceDetails.leaveOfAbsence
        ),
        totalTimeVacation: formatDuration(
          currentTimeTotal.absenceDetails.vacation
        ),
        totalTimeSickLeave: formatDuration(
          currentTimeTotal.absenceDetails.sickLeave
        ),
        totalTimeSickLeaveChild: formatDuration(
          currentTimeTotal.absenceDetails.sickLeaveChild
        ),
        totalTimeOther: formatDuration(currentTimeTotal.absenceDetails.other),
        totalTimeParentalLeave: formatDuration(
          currentTimeTotal.absenceDetails.parentalLeave
        ),
        totalApprovedOBTime: formatDuration(currentTimeTotal.approvedInconvenientTime),
        totalPlannedOBTime: formatDuration(currentTimeTotal.plannedInconvenientTime),
      });
    }
  }, [currentTimeTotal]);

  useEffect(() => {
    const companyId = companyState.company?.id;
    if (companyId) {
      Api()
        .company.getTotalsMonthlyWorker(companyId, workerId, year, month)
        .then((res) => {
          setCurrentTimeTotal(res.data.timeSummary);
          setCurrentCostPlannedTotal(res.data.costSummary.planned);
          setCurrentCostApprovedTotal(res.data.costSummary.approved);
        })
        .catch((err) => {
          console.log("Error fetching time total", err);
        });
    }
  }, [companyState.company?.id]);

  return (
    <TimeReportsGridFooter style={{ backgroundColor: Color.MidnightBlue }}>
      <TimeReportFooterHeader />

      <TotalCostFooterRow rowTitle={`${t("CompanyTimeReport.Scheduled")} ${t("CompanyTimeReport.Time")}`}
        timeTotalString={`${timeData.totalTime} ${timeData.totalPlannedOBTime !== "0h 0min"
          ? `(OB: ${timeData.totalPlannedOBTime})`
          : ""
          }`}
        grossSalary={costPlannedData.grossCost}
        obAmount={costPlannedData.inconvenientTimeCost}
        socialFee={costPlannedData.socialFeeCost}
        simflexityFee={costPlannedData.simflexityFeeTotal}
        totalCost={costPlannedData.total} />

      <TotalCostFooterRow rowTitle={`${t("CompanyTimeReport.Approved")} & ${t("CompanyTimeReport.Closed")}`}
        timeTotalString={`${timeData.totalTimeApproved} ${timeData.totalApprovedOBTime !== "0h 0min"
            ? `(OB: ${timeData.totalApprovedOBTime})`
            : ""
          }`}
        grossSalary={costApprovedData.grossCost}
        obAmount={costApprovedData.inconvenientTimeCost}
        socialFee={costApprovedData.socialFeeCost}
        simflexityFee={costApprovedData.simflexityFeeTotal}
        totalCost={costApprovedData.total} />

      <Cell>
        <Text
          color={Color.White}
          fontSize={FontSize.Small}
          fontFamily={FontFamily.MontserratBold}
          onClick={() => setShowAbsenceDetails(!showAbsenceDetails)}
          cursor={true}
        >
          {t("CompanyTimeReport.TotalAbsence")}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {timeData.totalTimeAbsence}
        </Text>
      </Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      {showAbsenceDetails && (
        <>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.SickLeave")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeSickLeave}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.SickLeaveChild")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeSickLeaveChild}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.Vacation")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeVacation}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.LeaveOfAbsence")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeLeaveOfAbsence}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.ParentalLeave")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeParentalLeave}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.Other")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeOther}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
        </>
      )}
    </TimeReportsGridFooter>
  );
};
