import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize } from "config/font";

type ProfileGridProps = {
  view?: string;
};

export const ProfileGrid = styled.div<ProfileGridProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 270px 2px 1fr;
    grid-template-rows: 250px auto;
  }

  button > img {
    margin-left: 10px;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1/2;
  grid-row: 1/3;
  padding: 20px 0;
  align-self: center;
  text-align: center;
  width: 270px;
  margin: 0 auto;

  @media (min-width: 768px) {
    align-self: start;
  }

  p {
    margin: 5px 0;
  }
`;

export const DividerLine = styled.div`
  background-color: ${Color.BurntSienna};

  width: 30px;
  height: 5px;
  align-self: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    grid-row: 1/3;
    background-color: ${Color.SeaBlue200};
  }
`;

export const WorkerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
`;

export const VideoContainer = styled.div`
  border-radius: 10px;
  text-align: left;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  max-height: unset;
  padding-top: 10px;
  grid-column: 1;
  grid-row: 3;
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin-top: 5px;
`;

export const VideoPresentation = styled.video`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 10px;
`;

export const Cell = styled.div`
  grid-column: 3;
  padding: 10px;
  background-color: ${Color.SeaBlue200};
  border-radius: 10px;
  width: 100%;
  text-align: left;
  overflow-y: auto;

  p {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 190px;
  margin-bottom: 30px;

  .icon {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
`;

export const WorkerDetailsModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 900px;
  height: 80vh;
  background-color: ${Color.White};
  padding: 20px;
  outline: none;
  border-radius: 8px;
  overflow-y: auto;
  text-align: center;
  align-content: center;

  h4 {
    margin: 0;
  }
`;


export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 0 10px;
  }
`;

export const SkillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  margin: 5px;
  p {
    margin: 0 5px;
  }
`;

export const ProfileDetailsContainer = styled.div`
  padding: 10px 0;
  border-radius: 10px;
  text-align: left;
  width: 100%;
`;

export const SkillsCell = styled(ProfileDetailsContainer)`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
`;

export const SkillCell = styled.div`
  width: 270px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  margin: 4px;
  justify-content: space-between;
  p {
    margin: 0;
  }
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 16px 0 16px 0;
`;
