export enum Color {
  PitchBlack = "#000000",
  Black = "#333333",
  White = "#FFFFFF",
  MidnightBlue = "#032A41",
  BurntSienna = "#2D8A5B",
  ChampagnePink = "#FEDC97",
  LightPear = "#B5B682",
  Positive = "#C7E2CF",
  Destructive = "#DA5552",
  SeaBlue600 = "#7990A0",
  SeaBlue500 = "#B1BFC8",
  SeaBlue400 = "#BCC7CF",
  SeaBlue300 = "#D8DFE4",
  SeaBlue200 = "#EFF2F4",
  Disabled = "#D8DFE4",
  Warning = "#ffc6c4",
  LightWarning = "#FCEBEB",
  LightGrey = "#FAFAFA",
  LightGrey02 = "#F5F5F5",
  LightGrey03= "#F0F0F0",
  LighterPositive = "#4CAF50",
  LighterDestructive = "#F44336",
  DarkerChampagnePink = "#F5D56C",
  ModernGreen = "#388E3C",
  LighterYellow = "#FFE499",
  Charcoal = "#3E2723",
  ActionColor = "#E1F4FF",
  DarkGrey = "#525557"
}
