import { Logo } from "components/nav/logo/logo.styled";
import React from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { useTranslation } from "react-i18next";

import { ButtonStyled } from "components/buttons/buttons.styled";

import { Color } from "config/colors";
import { H4 } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";

type PropsTypes = {
  setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
  showTerms: boolean;
};

export const SimflexityPayTerms = (props: PropsTypes) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.showTerms}
      onClose={() => {
        props.setShowTerms(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ overflow: "auto" }}
    >
      <ModalBody>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Logo
            src={SimflexityPayLogo}
            style={{ height: "30px", width: "auto" }}
            alt="logo"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            marginBottom: 10,
          }}
        >
          <H4 fontSize={FontSize.H4} style={{ margin: 0, marginBottom: 10 }}>
            Allmänna villkor
          </H4>
        </div>
        <div>
          <SmallTitle>Förhållandet mellan Företaget och Simflexity</SmallTitle>

          <TermsText>
            Simflexitys ansvar som arbetsgivare är begränsat till oaktsamhet
            avseende administrativa uppgifter gällande gig och frånsäger sig
            allt ansvar för direkt eller indirekt skada av vilket slag det vara
            må, som uppstår till följd av och/eller har samband med Giggarens
            uppdrag åt Uppdragsgivaren.
          </TermsText>
          <SmallTitle>
            Simflexitys ansvar i förhållande till Företaget är följande:
          </SmallTitle>
          <TermsText>
            <ul>
              <li>
                Via Simflexitys plattform förmedla uppdrag mellan företag och
                Giggare.
              </li>
              <li>
                Upprätta digitala avtal med starttid och sluttid för respektive
                uppdrag.
              </li>
              <li>
                Betala ut nettolönen efter att arbetspass och tidrapport har
                godkänts anpassat till Arbetstagarens valda
                utbetalningsintervall av lön.
              </li>
              <li>
                Inbetalning av preliminärskatt vid respektive löneutbetalning.
              </li>
              <li>
                Att Arbetstagaren erhåller lagstadgade pensions- och
                försäkringsförmåner i och med att Simflexity erlägger vid var
                tid sociala avgifter.
              </li>
              <li>
                Arbetstagaren har under sina uppdrag en olycksfallsförsäkring
                som gäller enbart för arbete som utförs inom ramen för
                uppdraget.
              </li>
            </ul>
          </TermsText>

          <SmallTitle>Företagets ansvar</SmallTitle>
          <TermsText>
            <ul>
              <li>
                Betala en rimlig ersättning jämförbar med kollektivavtal för
                rollen där laglig semesterersättning ska ingå, 12% påslag på
                timlönen.
              </li>
              <li>
                Endast anlita Arbetstagaren för uppdrag och ej under former som
                kan liknas vid en fast tillsvidareanställning, detta ska dock
                inte begränsa företagens möjligheter att erbjuda extrapass.
              </li>
              <li>
                Som Uppdragsgivare ansvarar du ändå för att tillgodose att
                försäkringsskyddet är tillräckligt för din specifika verksamhet.
              </li>
              <li>
                Tillgodose en god arbetsmiljö i enlighet med Arbetsmiljölagen.
              </li>
              <li>
                Säkerställa att Arbetstagaren inte utför arbetsuppgifter som kan
                utgöra en fara för liv och hälsa.
              </li>
              <li>
                Utvärdera kompetens och duglighet hos Arbetstagaren som ska
                utföra arbetsuppgifter.
              </li>
              <li>
                Vid behov instruera och agera arbetsledare för Arbetstagaren.
              </li>
              <li>
                Arbetstagaren måste själv vara fri att acceptera eller avstå
                från utannonserade uppdrag.
              </li>
              <li>
                Acceptera våra fullständiga användarvillkor:
                <a
                  href={`https://www.simflexity.com/sv/terms-and-condition/?selectedTerms=${t(
                    "General.TermsAndConditionsLink"
                  )}`}
                >
                  https://www.simflexity.com/sv/terms-and-condition
                </a>
              </li>
              <li>
                Godkänna vår integritetspolicy:
                <a
                  href={`https://www.simflexity.com/sv/terms-and-condition/?selectedTerms=${t(
                    "General.PersonalIntegrityLink"
                  )}`}
                >
                  https://www.simflexity.com/sv/terms-and-condition
                </a>
              </li>
            </ul>
          </TermsText>

          <SmallTitle>Uppdrag</SmallTitle>
          <TermsText>
            För varje uppdrag upprättar Simflexity och företaget ett
            uppdragsavtal.
          </TermsText>
          <TermsText>
            Uppdragsavtalet är unikt för varje uppdrag och innehåller:
          </TermsText>
          <TermsText>
            <ul>
              <DashLi>Uppgifter om den Anställde</DashLi>
              <DashLi>Uppgifter om Beställaren till uppdraget</DashLi>
              <DashLi>Uppgifter om det arbete som skall utföras</DashLi>
              <DashLi>Arbetstider och plats för Anställningen</DashLi>
              <DashLi>Specifika villkor för Uppdraget</DashLi>
              <DashLi>Bruttolön per timme</DashLi>
            </ul>
          </TermsText>
          <SmallTitle>Ändringar i och upphörande av uppdraget</SmallTitle>
          <TermsText>
            Uppdragsgivaren har rätt att avbryta uppdraget på grund av
            arbetsbrist, sakliga eller personliga skäl. Simflexity tillämpar
            även omedelbar uppsägning.
          </TermsText>
          <TermsText>
            Arbetstagare kan komma att avskedas, om Arbetstagaren på något sätt
            lämnar oriktiga uppgifter eller inte har kunskap eller kapacitet att
            utföra det Uppdrag han eller hon anställts för att utföra.
          </TermsText>
          <TermsText>
            Om anställningen upphör i förtid skall någon lön till Arbetstagaren
            inte utgå utöver det arbete som har utförts.
          </TermsText>
          <SmallTitle>Personuppgifter</SmallTitle>
          <TermsText>
            Genom att registrera sig på Plattformen godkänner Uppdragsgivaren
            att Simflexity samlar in och behandlar personuppgifter i enlighet
            med GDPR. Sådana uppgifter inkluderar information som
            Uppdragsgivaren lämnar till Simflexity vid registrering på
            plattformen. Detta kan inkludera namn, personnummer, adress, e-post,
            telefonnummer och bankuppgifter. Uppgifterna behandlas för
            bokföringssyften, marknadsföring, utvärdering och förbättring av
            tekniska lösningar och processer, förande av statistik,
            identifikation samt av liknande anledningar. Informationen kan också
            komma att användas för att Plattformen bättre ska kunna
            tillhandahålla erbjudanden och tjänster som är av intresse för
            företag och Arbetstagare.
          </TermsText>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <ButtonStyled
            style={{ margin: 0 }}
            onClick={() => {
              props.setShowTerms(false);
            }}
            backgroundColor={Color.BurntSienna}
            color={Color.White}
          >
            {t("General.Close")}
          </ButtonStyled>
        </div>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled.div`
  maxHeight: '100%',
  top: '50%',
  left: '0',
  transform: 'translate(0, -50%)',
  overflowY: 'auto'
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  padding: 20px 20px 30px;
  border-radius: 8px;
  background-color: ${Color.White};
  @media screen and (max-width: 480px){
    width: 100%;
  }
  @media screen and (min-width: 481px){
    width: 50%;
    top: 10%;
    left: 25%;
    position: absolute;
  }
  p {
    margin-bottom: 30px;
  }
  li {
    list-style-type: decimal;
    margin-bottom: 8px;
  }
  
`;

const DashLi = styled.li`
  list-style-type: disc !important;
  margin-bottom: 8px;
`;

const TermsText = styled.span`
  font-family: ${FontFamily.MontserratRegular};
  line-height: 1.3rem;
  font-size 0.75rem;
  display: block;
  margin-bottom: 12px;
`;
const SmallTitle = styled.span`
  font-family: ${FontFamily.MontserratSemiBold};
  font-size 0.75rem;
  display: block;
  margin-bottom: 12px;
`;
