import { Modal } from "@material-ui/core";
import { StructureModalBody } from "./companyStructureEditEntity.styled";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ButtonContainer } from "./editUnit/companyStructureEditUnit.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Color } from "config/colors";

interface RemoveModalProps {
  message: string;
  onCloseRemoveModal: () => void;
  onClick: () => void;
  t: (key: string) => string;
}

export const RemoveModal: React.FC<RemoveModalProps> = ({
  message,
  onCloseRemoveModal,
  onClick,
  t,
}) => {
  return (
    <Modal
      open={true}
      onClose={() => {
        onCloseRemoveModal()
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <StructureModalBody>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {message}
        </Text>
        <ButtonContainer>
          <ButtonStyled
            backgroundColor={Color.ModernGreen}
            onClick={() => {
              onCloseRemoveModal()
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            isCancel
            backgroundColor={Color.LighterDestructive}
            onClick={() => {
              onClick()
              onCloseRemoveModal()
            }}
          >
            {t("CompanyFavorite.Remove")}
          </ButtonStyled>
        </ButtonContainer>
      </StructureModalBody>
    </Modal>
  );
};
