import { CompanyStructureDto, NewWorker, Worker, Entity, Unit } from "model/CompanyStructure";
import { ChooseWorkerConfig } from "./editUnit/companyStructureEditUnitChooseExistingTable.component";

export const findUnitById = (company: CompanyStructureDto, unitId: number): Unit | undefined => {
  for (const entity of company.entities) {
    const unit = entity.units.find((unit) => unit.id === unitId);

    if (unit) { return unit; }
  }

  return undefined;
};

export const findAllWorkers = (structure: CompanyStructureDto): Worker[] => {
  const workers: Worker[] = [];

  structure.entities.forEach(entity => {
    entity.units.forEach(unit => {
      workers.push(...unit.workers);
    });
  });

  return workers;
};

export const extractUniqueWorkers = (entities: Entity[]): ChooseWorkerConfig[] => {
  const seenWorkers = new Map<number, ChooseWorkerConfig>();

  entities.forEach((entity) => {
    entity.units.forEach((unit) => {
      unit.workers.forEach((worker) => {
        if (!seenWorkers.has(worker.id)) {
          seenWorkers.set(worker.id, {
            id: worker.id,
            firstName: worker.firstName,
            lastName: worker.lastName,
            personalIdentityNumber: worker.personalIdentityNumber,
            email: worker.email,
            phoneNumber: worker.phoneNumber,
            entityUnit: [[entity.id, unit.id]], // Initialize with the first pair
          });
        } else {
          const existingWorker = seenWorkers.get(worker.id);
          if (existingWorker) {
            existingWorker.entityUnit.push([entity.id, unit.id]); // Add new pair to the list
          }
        }
      });
    });
  });

  return Array.from(seenWorkers.values());
};

export const excludeWorkersByUnitEntity = (workers: ChooseWorkerConfig[], unitId: number, entityId: number): ChooseWorkerConfig[] => {
  return workers
    .filter(worker =>
      !worker.entityUnit.some(([eId, uId]) => eId === entityId && uId === unitId)
    )
    .sort((a, b) => a.firstName.localeCompare(b.firstName));
};

export const isNewWorkerValid = (newWorker: NewWorker): boolean => {
  const phoneOkRegex = /^([+]46)(7[02369])(\d{7})$/;
  const nameRegex = /^[A-Za-z]+$/;

  // Ok to be empty
  const emailRegex = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const personalIdentityNumberRegex = /^$|^\d{8}[-]?\d{4}$/; // Swedish Personal Identity Number format: YYYYMMDD-XXXX or YYYYMMDDXXXX

  return Boolean(newWorker.firstName) &&
    Boolean(newWorker.lastName) &&
    nameRegex.test(newWorker.firstName) &&
    nameRegex.test(newWorker.lastName) &&
    phoneOkRegex.test(newWorker.phoneNumber) &&
    emailRegex.test(newWorker.email) &&
    personalIdentityNumberRegex.test(newWorker.personalIdentityNumber);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber.startsWith('+')) {
    return `+${phoneNumber}`;
  }
  return phoneNumber;
};
