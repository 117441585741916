import { useEffect, useRef } from "react";
import { H3, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import {
  StyledProgress,
  PreviewBody,
  PreviewContent,
  LoadingWrapper,
} from "./calendarTemplatePicker.styled";
import { useTranslation } from "react-i18next";
import { CalendarTemplateDto } from "model/GigTemplate";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { adjustShiftsToWeek } from "web-apps/company/utils/utils";
import { CompanyCalendarGigDto } from "model/Calendar";
import {
  CompanyGigRequestDto,
} from "model/Gig";
import { Modal } from "@material-ui/core";
import { PreviewCalendar } from "./previewCalendar.component";
import { useSaveManyGigs } from "hooks/useSaveManyGigs";
import moment, { Moment } from "moment";
import { Api } from "services/api/api.service";
import { BannerComponent } from "components/banners/banner.component";
import { CustomIcon } from "components/icon/customIcon.component";

type Props = {
  startDate: Date;
  refetchCalendarGigs: () => void;
  currentDates: { date: Moment; weekDay: string }[];
  setTemplates: React.Dispatch<React.SetStateAction<CalendarTemplateDto[] | []>>;
  calendarData: CompanyCalendarGigDto[] | [];
  setIsLoadingApi: React.Dispatch<React.SetStateAction<boolean>>;
  passedShifts: boolean;
  selectedTemplate: CalendarTemplateDto | undefined;
  isPreviewOpen: boolean;
  setIsPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PreviewCalendarModal = ({
  startDate,
  refetchCalendarGigs,
  currentDates,
  setTemplates,
  calendarData,
  setIsLoadingApi,
  passedShifts,
  selectedTemplate,
  isPreviewOpen,
  setIsPreviewOpen
}: Props) => {
  const [companyState] = useCompanyStore();
  const [gigs, isLoadingCreateGigs, , saveManyGigs] = useSaveManyGigs();
  const { t } = useTranslation();

  const apiState = useRef({ loading: false, completed: false, page: 0 });

  const fetchCalendarTemplates = () => {
    if (
      !companyState.company ||
      apiState.current.loading ||
      apiState.current.completed
    )
      return;

    apiState.current.loading = true;
    setIsLoadingApi(true);

    Api()
      .company.template.getWeekTemplates(companyState.company.id)
      .then((res) => {
        setTemplates(res.data);
        setIsLoadingApi(false);
        apiState.current.loading = false;
      })
      .catch((err) => {
        setIsLoadingApi(false);
        apiState.current.loading = false;
      });
  };

  useEffect(() => {
    fetchCalendarTemplates();
  }, []);

  useEffect(() => {
    if (gigs) {
      refetchCalendarGigs();
      setIsPreviewOpen(false);
    }
  }, [gigs]);


  const onApplyTemplate = () => {
    if (selectedTemplate) {
      const request = selectedTemplate.gigs.map<CompanyGigRequestDto>((t) => ({
        ...t,
        location: companyState.company?.location || "59.3293, 18.0686",
        shifts: adjustShiftsToWeek(t, startDate),
      }));
      saveManyGigs(request);
    } else {
      setIsPreviewOpen(false);
    }
  };

  return (
    <Modal
      open={isPreviewOpen}
      onClose={() => {
        setIsPreviewOpen(false);
      }}
    >
      <PreviewBody>
        <PreviewContent>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomIcon
              name="cross"
              size="40px"
              onClick={() => setIsPreviewOpen(false)}
              color={Color.LighterDestructive}
            />
          </div>
          <H3
            style={{ marginTop: "40px", marginBottom: "20px" }}
            fontFamily={FontFamily.MontserratRegular}
          >
            {t("GigsCompany.CalendarPreview") +
              ` (${selectedTemplate?.name || ""})`}
          </H3>

          {passedShifts && (
            <BannerComponent
              backgroundColor={Color.LightWarning}
              fill={Color.LighterDestructive}
            >
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.LighterDestructive}
              >
                {t("CalendarCompany.TemplateHasBeenAdjusted")}
              </Text>
              <Text color={Color.MidnightBlue}>
                {t("CalendarCompany.PastTemplateInfo")}
              </Text>
            </BannerComponent>
          )}
          <PreviewCalendar
            calendarData={calendarData}
            currentWeek={moment(startDate).week()}
            loading={isLoadingCreateGigs}
            onApply={onApplyTemplate}
            onCancel={() => {
              setIsPreviewOpen(false);
            }}
            currentDates={currentDates}
          />
        </PreviewContent>
        {isLoadingCreateGigs && (
          <LoadingWrapper>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
              color={Color.SeaBlue200}
            >
              {t("GigsCompany.CalendarCreating")}
            </Text>
            <StyledProgress />
          </LoadingWrapper>
        )}
      </PreviewBody>
    </Modal>
  );
};
