import { Color } from 'config/colors';
import React from 'react';
import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from './checkbox.styled';

const Checkbox: React.FC<{ checked: boolean; onChange: () => void; color?: Color | undefined }> = ({
  checked,
  onChange,
  color = Color.ModernGreen,
}) => (
  <CheckboxContainer onClick={onChange}>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked} color={color}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
