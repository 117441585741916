import { CustomIcon } from 'components/icon/customIcon.component';
import { Text } from "components/Typography/text.styled";
import { Color } from 'config/colors';
import { FontFamily, FontSize } from 'config/font';
import { Entity } from 'model/CompanyStructure';
import React from 'react';
import { IconCircularContainer } from '../../dashboard/dashboard.styled';
import { EntityHeader, RowItem } from './companyStructure.styled';

interface EntityComponentProps {
  entity: Entity;
  expandedEntities: number[];
  toggleEntityExpand: (entityId: number) => void;
  setEntityToEdit: (entity: { id: number; name: string }) => void;
  setShowEditEntity: (show: boolean) => void;
}

const EntityComponent: React.FC<EntityComponentProps> = ({
  entity,
  expandedEntities,
  toggleEntityExpand,
  setEntityToEdit,
  setShowEditEntity,
}) => {
  return (
    <EntityHeader onClick={() => toggleEntityExpand(entity.id)}>
      <Text fontFamily={FontFamily.MontserratSemiBold} fontSize={FontSize.Large}>
        {entity.name}
      </Text>
      <RowItem>
        <IconCircularContainer
          style={{ zIndex: 1, marginLeft: 20, marginTop: 10, marginBottom: 10, marginRight: 20 }}
          isAccept
          onClick={(e) => {
            e.stopPropagation();

            setEntityToEdit({
              id: entity.id,
              name: entity.name,
            });
            setShowEditEntity(true);
          }}
        >
          <CustomIcon
            name="pen"
            size="24px"
            padding="2px"
            color={Color.White}
          />
        </IconCircularContainer>

        <CustomIcon
          name={expandedEntities.includes(entity.id) ? "upward_arrow" : "downward_arrow"}
          size="24px"
          color={Color.BurntSienna}
          style={{ marginTop: 10 }}
        />
      </RowItem>
    </EntityHeader>
  );
};

export default EntityComponent;
