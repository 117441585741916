import { Salary } from 'model/Salary';
import React from 'react';

import { H4, Text } from 'components/Typography/text.styled';
import { CustomIcon } from 'components/icon/customIcon.component';
import { Color } from 'config/colors';
import { FontFamily, FontSize } from 'config/font';
import { useTranslation } from 'react-i18next';
import { Row, RowSpaceBetween } from '../monthlyOverview.styled';
import { SalaryField, SalaryFieldHeading, SalaryValue } from './salaryModal.styled';
import { extractCSV } from './salaryUtils';

type TableRowProps = {
  label: string;
  value: string | number | boolean;
};

const TableRow: React.FC<TableRowProps> = ({ label, value }) => {
  return (
    <tr>
      <SalaryField>{label}</SalaryField>
      <td><SalaryValue>{value}</SalaryValue></td>
    </tr>
  );
};

type Props = {
  from: string;
  to: string;
  data: Salary;
};

const SalaryTable: React.FC<Props> = ({ from, to, data }) => {
  const { t } = useTranslation();

  const fullName = data.firstName + " " + data.lastName;

  const inconvenientTimeIndex = (index: number) => data.inconvenientTime.length > 1 ? index + 1 : "";

  return (
    <div>
      <table>
        <tbody>
          <RowSpaceBetween>
            <H4>{fullName}: {from} - {to}</H4>
          </RowSpaceBetween>

          <Row onClick={() => extractCSV(t, fullName, data, from, to)}>

            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.MidnightBlue}
              fontSize={FontSize.Small}
            >
              {t("Salary.ExportToCSV")}
            </Text>
            <CustomIcon
              name="download"
              size="25px"
              color={Color.MidnightBlue}
            />
          </Row>
          <br />

          <TableRow label={t('Salary.PersonalIdentityNumber')} value={data.personalIdentityNumber} />
          <TableRow label={t('Salary.TotalWorkTime')} value={data.totalWorkTime} />
          <TableRow label={t('Salary.TotalInconvenientTime')} value={data.totalInconvenientTime} />
          <TableRow label={t('Salary.TotalAbsenceTime')} value={data.totalAbsenceTime} />
          <TableRow label={t('Salary.TotalGrossSalary')} value={data.totalGrossSalary} />
          <TableRow label={t('Salary.GrossSalary')} value={data.grossSalary} />
          <TableRow label={t('Salary.SocialFee')} value={data.socialFee} />

          <SalaryFieldHeading>{t('Salary.InconvenientTime')}</SalaryFieldHeading>
          <TableRow label={t('Salary.InconvenientTimeSalary')} value={data.inconvenientTimeSalary} />
          {data.inconvenientTime.map((ot, index) => (
            <React.Fragment key={index}>
              {data.inconvenientTimeSalary != ot.amount && (<TableRow label={`${t('Salary.OB')}${inconvenientTimeIndex(index)} ${t('Salary.Amount')}`} value={ot.amount} />)}
              <TableRow label={`${inconvenientTimeIndex(index) != "" ? "-" : ""} ${t('Salary.OB')}${inconvenientTimeIndex(index)} ${t('Salary.Time')}`} value={ot.time} />
              <TableRow label={`${inconvenientTimeIndex(index) != "" ? "-" : ""} ${t('Salary.OB')}${inconvenientTimeIndex(index)} ${t('Salary.Rate')} ${ot.percentage ? "(%)" : ""}`} value={ot.rate} />
            </React.Fragment>
          ))}

          <SalaryFieldHeading>{t('Salary.Absence')}</SalaryFieldHeading>
          {Object.entries(data.absence).map(([key, value]) => (
            <React.Fragment key={key}>
              <TableRow label={`${t(`Salary.${key}`)}`} value={value.time} />
              {value.dates.map((date, index) => (
                <TableRow key={index} label={`- ${t(`Salary.${key}`)} ${t('Salary.Dates')} ${index + 1}`} value={date} />
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

    </div>
  );
};

export default SalaryTable;
