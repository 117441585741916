import styled from "styled-components";
import { Color } from "config/colors";

type BannerProps = {
  backgroundColor?: Color;
};

export const Banner = styled.div<BannerProps>`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  height: auto;
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  color: ${Color.Charcoal};
  margin-bottom: 40px;
`;

export const InfoCircleBanner = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Color.Destructive};
  border-radius: 40px;
  width: 30px;
  height: 27px;
  align-items: center;
  margin: 20px 10px;
`;