import {
  StyledButton,
  IconHolder,
  StyledIcon,
  IconContainer,
  Divider,
} from "./DropdownItem.styled";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ListViewModel } from "./Dropdown";

interface DropdownItemProps {
  model: ListViewModel;
  isSelected: boolean;
  onClickItem?: (vm: ListViewModel) => void;
  onRemoveItem?: (vm: ListViewModel) => void;
  onClickDetails?: (vm: ListViewModel) => void;
  smallerFont?: boolean;
  disabled?: boolean;
}

export const DropdownItem = ({
  model,
  isSelected,
  onClickItem,
  onClickDetails,
  onRemoveItem,
  smallerFont,
  disabled,
}: DropdownItemProps) => {
  const onClick = () => {
    onClickItem?.(model);
  };

  const onRemoveClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onRemoveItem?.(model);
  };

  const onDetailsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClickDetails?.(model);
  };

  return (
    <StyledButton type={"button"} onClick={!disabled ? onClick : () => {}}>
      <Text
        fontFamily={FontFamily.MontserratSemiBold}
        fontSize={smallerFont ? FontSize.Standard : FontSize.Large}
        color={disabled ? Color.SeaBlue300 : Color.MidnightBlue}
        style={{ marginTop: 20 }}
      >
        {model.label}
      </Text>
      <Divider />
      <IconHolder>
        {isSelected && (
          <StyledIcon name="checkmark" size="28px" color={Color.BurntSienna} />
        )}
        {onClickDetails && (
          <StyledIcon
            name="pen"
            size="28px"
            backgroundColor={Color.BurntSienna}
            color={Color.White}
            padding="4px"
            onClick={onDetailsClick}
          />
        )}
        {onRemoveItem && (
          <IconContainer>
            <StyledIcon
              name="trashcan"
              size="28px"
              color={Color.White}
              padding="4px"
              onClick={onRemoveClick}
            />
          </IconContainer>
        )}
      </IconHolder>
    </StyledButton>
  );
};
