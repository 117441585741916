import { CircularProgress, Modal } from '@material-ui/core';
import { TimeReportStatus } from 'model/TimeReport';
import React from 'react';
import { ButtonContainer, ModalBody } from "../applicantDetails/applicantDetails.styled";

import { ButtonStyled } from 'components/buttons/buttons.styled';
import { Text } from "components/Typography/text.styled";
import { Color } from 'config/colors';
import { FontFamily, FontSize } from 'config/font';

export type CommonMultipleSelectionModalProps = {
  t: (key: string, options?: any) => string;
  selectedTimeReports: any[];
  handleSelectedTimeReports: (status: TimeReportStatus.APPROVED | TimeReportStatus.REJECTED) => Promise<void>;
};

const renderModalBody = (
  status: TimeReportStatus,
  modalProps: CommonMultipleSelectionModalProps,
  onClose: () => void
) => {
  const { t, selectedTimeReports, handleSelectedTimeReports } = modalProps;

  return (
    <>
      <Text
        align="center"
        fontSize={FontSize.H4}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {status === TimeReportStatus.APPROVED
          ? getActionString(t, selectedTimeReports, "CompanyTimeReport.ApproveAndPayTimeReport", "CompanyTimeReport.ApproveAndPayTimeReports")
          : getActionString(t, selectedTimeReports, "CompanyTimeReport.RejectTimeReport", "CompanyTimeReport.RejectTimeReports")
        }
      </Text>
      {status === TimeReportStatus.APPROVED && (
        <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
          {t("CompanyTimeReport.TimeReportsForSimflexityPayGigs")}
        </Text>
      )}
      <ButtonContainer>
        <ButtonStyled
          backgroundColor={Color.Disabled}
          color={Color.SeaBlue500}
          onClick={() => {
            onClose()
          }}
        >
          {t("General.Cancel")}
        </ButtonStyled>
        <ButtonStyled
          onClick={() => {
            if (status == TimeReportStatus.APPROVED) {
              handleSelectedTimeReports(TimeReportStatus.APPROVED);
            } else {
              handleSelectedTimeReports(TimeReportStatus.REJECTED);
            }
          }}
          backgroundColor={
            status === TimeReportStatus.REJECTED
              ? Color.Destructive
              : Color.BurntSienna
          }
        >
          {status === TimeReportStatus.REJECTED
            ? t("CompanyTimeReport.Reject")
            : t("CompanyTimeReport.Approve") +
            " " +
            t("CompanyTimeReport.AndPay")}
        </ButtonStyled>
      </ButtonContainer>
    </>
  );
};

function getActionString(t: (key: string, options?: any) => string, selectedTimeReports: any[], singular: string, plural: string) {
  const count = selectedTimeReports.length;
  const resource = count === 1 ? singular : plural;
  
  return t(resource, {
    timeReports: count.toString(),
  });
}

type MultipleSelectionPopupModalProps = {
  onClose: () => void;
  isLoading: boolean;
  status: TimeReportStatus;
  modalProps: CommonMultipleSelectionModalProps;
};

const MultipleSelectionPopupModal: React.FC<MultipleSelectionPopupModalProps> = ({
  onClose,
  isLoading,
  status,
  modalProps
}) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          renderModalBody(status, modalProps, onClose)
        )}
      </ModalBody>
    </Modal>
  );
};

export default MultipleSelectionPopupModal;
