import React, { useEffect, useState } from "react";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { TextInput } from "components/form/inputs/inputs.styled";
import {
  Option,
  OptionWrapper,
  SelectBox,
  Form,
} from "components/form/select/select.styled";

type Props = {
  inputValue: string;
  onChange: (value: string) => void,
  selections: string[];
  submitValue?: (value: string) => void;
  submittedValues?: string[];
};

export const AutoCompleteInput: React.FC<Props> = ({
  inputValue,
  onChange,
  submitValue,
  submittedValues,
  selections,
}) => {
  const [matches, setMatches] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);

  const findMatches = () => {
    if (inputValue) {
      const lowerCaseValue = inputValue.toLowerCase();

      const filtered = selections.filter((selection) => {
        const lowerCaseRole = selection.toLowerCase();

        for (let i = 0; i < lowerCaseValue.length; i++) {
          if (lowerCaseValue[i] !== lowerCaseRole[i]) return false;
        }

        return true;
      });

      setMatches(filtered);
    } else {
      setMatches([]);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (submitValue) {
      submitValue(inputValue);
    }
  };

  useEffect(() => {
    findMatches();
  }, [inputValue]);

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        value={inputValue}
        maxLength={24}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        onFocus={() => {
          setVisible(true)
        }}
        onBlur={() => {
          setVisible(false);
        }}
      />
      <SelectBox open={visible}>
        {matches.map((match, index) => (
          <OptionWrapper
            key={index}
            onMouseDown={() => {
              setVisible(false);

              if (submitValue) {
                submitValue(match);
                onChange("");
              } else {
                onChange(match);
              }
            }}
          >
            <Option>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Large}
                color={Color.MidnightBlue}
              >
                {match}
              </Text>
            </Option>
            {submittedValues?.find((value) => value === match) && (
              <CustomIcon
                name="checkmark"
                size="28px"
                color={Color.BurntSienna}
              />
            )}
          </OptionWrapper>
        ))}
      </SelectBox>
      <button type="submit" hidden={true} />
    </Form>
  );
};
