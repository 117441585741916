import React from "react";
import { FontFamily } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { LinkWrapper, ModalBody } from "./workgroup.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowDeleteWorkGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteWorkGroupModal: boolean;
  workGroupId: number;
};

export const DeleteWorkGroupModal: React.FC<Props> = ({
  setShowDeleteWorkGroupModal,
  showDeleteWorkGroupModal,
  workGroupId,
}) => {
  const { t } = useTranslation();
  const [companyState, ,] = useCompanyStore();
  const [, workgroupActions] = useWorkgroupStore();
  const [, , alertDispatch] = useAlertStore();

  const handleDeleteWorkGroup = () => {
    if (companyState.company && workGroupId) {
      workgroupActions
        .deleteWorkGroup(companyState.company.id, workGroupId)
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("WorkGroup.Deleted"),
              message: t("WorkGroup.WorkGroupHasBeenDeleted"),
            },
          });

          setShowDeleteWorkGroupModal(false);
        })
        .catch(() => {
          console.log("Error trying to delete workgroup");
        });
    }
  };

  return (
    <>
      <Modal
        open={showDeleteWorkGroupModal}
        onClose={() => {
          setShowDeleteWorkGroupModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowDeleteWorkGroupModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ margin: "0 auto", marginTop: 10 }}
          >
            {t("WorkGroup.DoYouWantToDeleteWorkGroup")}
          </Text>
          <ButtonStyled
            style={{ marginTop: 40 }}
            isCancel
            backgroundColor={Color.LighterDestructive}
            onClick={() => {
              handleDeleteWorkGroup();
            }}
          >
            {t("WorkGroup.Delete")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
