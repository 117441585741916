import { GigResolution, GigStatus, GigType } from "./Gig";
import { WorkerDocument } from "./WorkerDocument";

export enum ApplicationStatus {
  UNDEFINED = "Undefined",
  PENDING = "Pending",
  OFFERED = "Offered",
  CLOSED = "Closed",
}

export enum ApplicationResolution {
  UNDEFINED = "Undefined",
  ACCEPTED = "Accepted",
  WITHDRAWN = "Withdrawn",
  REJECTED = "Rejected",
  DECLINED = "Declined",
}

export type UserApplicationDto = {
  firstName: string;
  lastName: string;
  description: string | null;
  id: number;
  firebaseId: string;
  skills: ApplicationUserSkillDto[];
  dateOfBirth?: string;
  email?: string;
  phoneNumber: string;
  stats?: any[];
  documents: WorkerDocument[];
};

export type ApplicationDto = {
  id: number;
  gigId: number;
  motivationalLetter: string | null;
  status: ApplicationStatus;
  resolution: ApplicationResolution;
  requestedRate: number;
  worker: UserApplicationDto;
  gig: ApplicationGig;
  declineReason: string;
  parentGigId: number | null;
};
export type ApplicationWorkerEndorsementDto = {
  id: number;
  skill: string;
  endorsedBy: string;
  companyId: number;
  workerId: number;
};

export type ApplicationGig = {
  amountOfWorkers: number;
  hiredWorkerCount: number;
  id: number;
  resolution: GigResolution;
  role: string;
  startTime: string;
  endTime: string;
  status: GigStatus;
  type: GigType;
};

export type ApplicationUserSkillDto = {
  skill: string;
  experience: number;
};

export type ApplicationRequestDto = Omit<ApplicationDto, "id">;
