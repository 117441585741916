import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyGigDto, GigStatus } from "model/Gig";
import { Color } from "config/colors";
import { Api } from "services/api/api.service";
import { TimeReportStatus } from "model/TimeReport";
import { useAlertStore } from "stores/alertStore/alertStore";
import { CloseGigModal } from "../gig/closeGigModal.component";
import Modal from "@material-ui/core/Modal";
import { H3 } from "components/Typography/text.styled";
import { ModalBody } from "./gigDetail.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { ButtonStyled } from "components/buttons/buttons.styled";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
};

export const GigDetailButtons: React.FC<Props> = ({ gigData, onGigClosed }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [cloneGig, setCloneGig] = useState(false);

  return (
    <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'center', width: '80%', margin: '0 auto'}}>
      <ButtonStyled
        style={{ marginTop: 40 }}
        backgroundColor={Color.ModernGreen}
        onClick={() => {
          setCloneGig(false);
          setShowCreateGigModal(true);
        }}
      >
        {t("General.Edit")}
      </ButtonStyled>
      <ButtonStyled
        style={{ marginTop: 40 }}
        backgroundColor={Color.MidnightBlue}
        isAction
        onClick={() => {
          setCloneGig(true);
          setShowCreateGigModal(true);
        }}
      >
        {t("GigsCompany.CloneGig")}
      </ButtonStyled>
      <ButtonStyled
        style={{ marginTop: 40 }}
        disabled={gigData.status === GigStatus.CLOSED}
        backgroundColor={Color.LighterDestructive}
        isCancel
        onClick={() => {
          if (companyState.company?.id) {
            Api()
              .company.timeReport.getGigTimeReports(
                companyState.company?.id,
                gigData.id
              )
              .then((res) => {
                if (
                  res.data.find(
                    (timeReport) => timeReport.status !== TimeReportStatus.NEW
                  )
                ) {
                  alertDispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      icon: "alert",
                      title: "",
                      message: t("GigsCompany.ThisGigHasConnectedTimeReports"),
                    },
                  });
                } else {
                  setDeleteModalOpen(true);
                }
              });
          }
        }}
      >
        {t("General.Delete")}
      </ButtonStyled>
      <CloseGigModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        gigData={gigData}
        onGigClosed={onGigClosed}
      />
      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>
            {cloneGig ? t("GigsCompany.CloneGig") : t("GigsCompany.EditGig")}
          </H3>
          <SaveGigForm
            setShowCreateGigModal={setShowCreateGigModal}
            gigData={gigData}
            isClone={cloneGig}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
