export interface Worker {
  id: number;
  firstName: string;
  lastName: string;
  personalIdentityNumber: string;
  email: string;
  phoneNumber: string;
  firebaseId: string;
  status: WorkerStatus;
}

export enum WorkerStatus {
  Created = "Created",
  Pending = "Pending",
  Accepted = "Accepted",
}

export interface NewWorker {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  personalIdentityNumber: string;
  email: string;
}

export interface Unit {
  id: number;
  name: string;
  companyEntityId: number;
  workers: Worker[];
}

export interface Entity {
  id: number;
  name: string;
  companyId: number;
  units: Unit[];
}

export interface CompanyStructureDto {
  id: number;
  name: string;
  entities: Entity[];
}
 
export type EntityName = {
  "name": string
}

export type UnitName = {
  "name": string
}
