import React, { useEffect, useState } from "react";
import {
  InvitedContainer,
  MemberContainer,
  WorkGroupOptions,
  WorkGroupRowContainer,
} from "./workgroup.styled";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { DividerLine1, DividerLine2 } from "../gig/gigs.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { WorkGroupDetails } from "./workgroupDetails.component";
import {
  IconCircularContainer,
  IconsContainer,
} from "../dashboard/dashboard.styled";
import { DeleteWorkGroupModal } from "./deleteWorkGroupModal.component";
import { EditWorkGroupModal } from "./editWorkgroupModal.component";
import { WorkGroup, WorkGroupWorker } from "model/Workgroup";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";

type Props = {
  workgroup: WorkGroup;
};

export const WorkGroupRow: React.FC<Props> = ({ workgroup }) => {
  const [members, setMembers] = useState<WorkGroupWorker[]>([]);
  const [invited, setInvited] = useState<WorkGroupWorker[]>([]);
  const [declined, setDeclined] = useState<WorkGroupWorker[]>([]);
  const [companyState, , companyDispatch] = useCompanyStore();
  const [showWorkGroupDetails, setShowWorkGroupDetails] = useState(false);
  const [showEditWorkGroupModal, setShowEditWorkGroupModal] = useState(false);
  const [showDeleteWorkGroupModal, setShowDeleteWorkGroupModal] =
    useState(false);
  const [, workgroupActions] = useWorkgroupStore();
  const [state] = useWorkgroupStore();
  const [noDetails, setNoDetails] = useState(false);

  useEffect(() => {
    if (companyState.company && workgroup.id) {
      Promise.all([
        workgroupActions.getAllMembersInWorkGroup(
          companyState.company.id,
          workgroup.id
        ),
        workgroupActions.getAllInvitedInWorkGroup(
          companyState.company.id,
          workgroup.id
        ),
        workgroupActions.getAllDeclinedInWorkGroup(
          companyState.company.id,
          workgroup.id
        ),
      ])
        .then(() => {})
        .catch(() => {
          console.log(
            "error when fetching members, declined and invited to workgroup"
          );
        });
    }
  }, []);

  useEffect(() => {
    setMembers(state.members[workgroup.id] || []);
    setInvited(state.invited[workgroup.id] || []);
    setDeclined(state.declined[workgroup.id] || []);
  }, [state.members, state.invited, state.declined, workgroup.id]);

  useEffect(() => {
    if (members.length == 0 && declined.length == 0 && invited.length == 0) {
      setNoDetails(true);
    } else {
      setNoDetails(false);
    }
  }, [members, invited, declined]);

  const handleClickOnRow = () => {
    if (members.length == 0 && declined.length == 0 && invited.length == 0)
      return;
    setShowWorkGroupDetails(!showWorkGroupDetails);
  };

  return (
    <>
      <WorkGroupRowContainer>
        <Text
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
          color={noDetails ? Color.Disabled : Color.PitchBlack}
          style={{
            margin: 0,
            cursor: noDetails ? "auto" : "pointer",
          }}
          onClick={() => {
            handleClickOnRow();
          }}
        >
          {workgroup.name}
        </Text>
        <DividerLine1 />
        <MemberContainer
          style={{
            margin: 0,
            cursor: noDetails ? "auto" : "pointer",
          }}
          onClick={() => {
            handleClickOnRow();
          }}
        >
          <CustomIcon
            name="group"
            size="28px"
            color={Color.Disabled}
            style={{ marginRight: 10 }}
          />
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
          >
            {members.length}
          </Text>
        </MemberContainer>
        {workgroup.type !== "Favorite" && (
          <>
            <DividerLine2 />
            <InvitedContainer
              style={{
                margin: 0,
                cursor: noDetails ? "auto" : "pointer",
              }}
              onClick={() => {
                handleClickOnRow();
              }}
            >
              <CustomIcon
                name="letter"
                size="28px"
                color={Color.Disabled}
                style={{ marginRight: 10 }}
              />
              <Text
                fontSize={FontSize.H4}
                fontFamily={FontFamily.MontserratSemiBold}
                style={{ margin: 0 }}
              >
                {invited.length}
              </Text>
            </InvitedContainer>
          </>
        )}
        <WorkGroupOptions>
          <IconsContainer>
            <IconCircularContainer
              isAccept
              onClick={() => {
                setShowEditWorkGroupModal(true);
              }}
            >
              <CustomIcon
                className="icon"
                name="settings"
                size="20px"
                padding="2px"
                color={Color.White}
              />
            </IconCircularContainer>
            {workgroup.name !== "Favorites" && (
              <IconCircularContainer
                onClick={() => {
                  setShowDeleteWorkGroupModal(true);
                }}
              >
                <CustomIcon
                  className="icon"
                  name="trashcan"
                  size="20px"
                  padding="2px"
                  color={Color.White}
                />
              </IconCircularContainer>
            )}
          </IconsContainer>
        </WorkGroupOptions>
      </WorkGroupRowContainer>

      {showWorkGroupDetails && (
        <WorkGroupDetails
          members={members}
          declined={declined}
          invited={invited}
          showWorkGroupDetails={showWorkGroupDetails}
          setShowWorkGroupDetails={setShowWorkGroupDetails}
          workgroup={workgroup}
        />
      )}
      {showDeleteWorkGroupModal && workgroup && (
        <DeleteWorkGroupModal
          workGroupId={workgroup.id}
          setShowDeleteWorkGroupModal={setShowDeleteWorkGroupModal}
          showDeleteWorkGroupModal={showDeleteWorkGroupModal}
        />
      )}
      {showEditWorkGroupModal && workgroup && (
        <EditWorkGroupModal
          workGroup={workgroup}
          setShowEditWorkGroupModal={setShowEditWorkGroupModal}
          showEditWorkGroupModal={showEditWorkGroupModal}
        />
      )}
    </>
  );
};
