import { Color } from "config/colors";
import { FontSize } from "config/font";
import styled from "styled-components";

export const FormStyled = styled.form`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  button {
    font-size: ${FontSize.Small};
  }
  h4 {
    margin: 10px 0;
  }
  p {
    margin: 0 0 40px 0;
  }
`;
