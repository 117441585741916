import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";

export const SalaryModalBody = styled.div`
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 840px;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  background-color: ${Color.White};
  outline: none;
  padding: 20px;
  padding-bottom: 40px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

export const SalaryField = styled.div`
  background-color: ${Color.LightGrey02};
  color: black;
  font-family: ${FontFamily.MontserratRegular};
  font-size: ${FontSize.Standard};
  padding: 5px;
  border-radius: 3px;
`;

export const SalaryFieldHeading = styled.div`
  background-color: ${Color.White};
  color: black;
  font-family: ${FontFamily.MontserratSemiBold};
  font-size: ${FontSize.Standard};
  padding: 5px;
  border-radius: 3px;
`;

export const SalaryValue = styled.div`
  background-color: ${Color.White};
  color: black;
  font-family: ${FontFamily.MontserratRegular};
  font-size: ${FontSize.Standard};
  padding: 5px;
  border-radius: 3px;
`;
