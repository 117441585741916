import styled, { css } from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

export const NoGigsWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Cell = styled.div`
  background-color: ${Color.White};
  margin: 10px 0;
  padding: 15px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  grid-template:
    "gig-info dltbutton" auto
    "workers ." auto
    / 1fr auto;

  border-bottom: 1px solid ${Color.SeaBlue200};

  @media (min-width: 640px) {
    grid-template:
      "gig-info divider1 workers dltbutton" auto
      / 2fr 1px 1fr auto;
  }

  @media (min-width: 970px) {
    grid-template:
      "gig-info divider1 shifts divider2 workers dltbutton" auto
      / 4fr 1px 3fr 1px 2fr auto;
  }

  p {
    margin: 0;
  }
`;

export const CheckboxText = styled.p`
  font-size: 14px;
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratSemiBold};
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  p {
    &:hover {
      cursor: pointer;
      color: ${Color.SeaBlue400};
    }
  }
  checkbox .checked {
    color: ${Color.SeaBlue400};
  }
`;

export const SimpleCell = styled(Cell)`
  grid-template:
    "title-status" auto
    "date-hours" auto
    "workers" auto
    / 1fr;

  @media (min-width: 640px) {
    grid-template:
      "title-status divider1 workers" auto
      "date-hours divider1 workers" auto
      / 2fr 1px 1fr 20px;
    grid-row-gap: 0;
  }

  @media (min-width: 970px) {
    grid-template:
      "title-status divider1 date-hours divider2 workers ." auto
      / 4fr 1px 3fr 1px 2fr 20px;
    grid-row-gap: 0;
  }
`;

export const GigInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: gig-info;
  row-gap: 10px;

  @media (min-width: 640px) {
    row-gap: 0;
  }
`;

export const TitleStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 440px) {
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SimpleTitleStatus = styled(TitleStatus)`
  grid-area: title-status;

  @media (min-width: 440px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: 970px) {
    align-items: center;
  }
`;

export const DateHours = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
`;

export const SimpleDateHours = styled(DateHours)`
  grid-area: date-hours;

  @media (min-width: 970px) {
    justify-content: space-between;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ShiftList = styled.ul`
  display: none;
  grid-area: shifts;
  margin: 0;
  padding-left: 10px;

  @media (min-width: 970px) {
    display: unset;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: flex-start;
  grid-area: dltbutton;

  @media (min-width: 640px) {
    align-items: center;
  }
`;

export const WorkerCountWrapper = styled.div`
  grid-area: workers;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

export const ImageWrapper = styled.div`
  width: 34px;
  height: 34px;
  margin: 0 0 0 -12px;
  border: 2px solid ${Color.SeaBlue200};
  border-radius: 50%;
  background-color: #d8dfe4;
`;

export const SmallImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const DividerLine1 = styled.div`
  display: none;
  background-color: ${Color.SeaBlue300};
  grid-area: divider1;

  @media (min-width: 640px) {
    display: unset;
  }
`;

export const DividerLine2 = styled.div`
  display: none;
  background-color: ${Color.SeaBlue300};
  grid-area: divider2;

  @media (min-width: 970px) {
    display: unset;
  }
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);

  button {
    font-size: ${FontSize.Small};
  }

  p {
    margin: 0 0 30px 0;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin-left: 8px;
  }
`;

export const GigsWrapper = styled.div.attrs(
  (props: { loading: boolean }) => props
)`
  opacity: ${(props) => (props.loading ? "0.5" : "1.0")};
`;

export const TextInput = styled.input<{ fontSize?: FontSize }>`
  border: 1px solid ${Color.SeaBlue400};
  border-radius: 8px; 
  width: 100%; 
  max-width: 300px; 
  padding: 12px 16px;
  height: 30px;
  font-size: ${FontSize.Standard};
  color: ${Color.MidnightBlue};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); 
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  ::placeholder {
    color: ${Color.SeaBlue400};
    font-weight: 300;
  }

  &:focus {
    border-color: ${Color.PitchBlack};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); 
    outline: none; 
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;


export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px; 
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
  gap: 8px; 
  padding: 0 16px; 
  margin-bottom: 15px;
`;
