import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormRow,
  FormTitle,
  GigpayWrapper,
  LabelStyled,
  ReadOnlyStyled,
  TermsLink,
  TermsReadText,
} from "./simflexityPay.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgress, Checkbox } from "@material-ui/core";
import { H3, H4, Text } from "components/Typography/text.styled";
import { FontFamily } from "config/font";
import { useAuthStore } from "stores/authStore/authStore";
import { TermsContainer } from "web-apps/company/components/companySettings/companySettingsMobileView/companySettingsMobileView.styled";
import { Api } from "services/api/api.service";

import { CompanyDto } from "model/Company";
import { SimflexityPayTerms } from "./simflexityPayTerms";

type PropsTypes = {
  companyId: string;
};

export const SimflexityPayCompany = (props: PropsTypes) => {
  const [companyState, , companyDispatch] = useCompanyStore();
  const [authState] = useAuthStore();
  const [showTerms, setShowTerms] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [connected, setConnected] = useState(false);
  const [company, setCompany] = useState<CompanyDto | undefined>(undefined);
  const [simflexityPayInfoSent, setSimflexityPayInfoSent] = useState(false);
  const [simflexityPayConnected, setSimmflexityPayConnected] = useState(false);
  const { t } = useTranslation();

  let companyId = props.companyId;

  useEffect(() => {
    if (companyState.company && companyState.company.companySettings) {
      const found = companyState.company.companySettings.find(
        (setting) =>
          setting.key === "EnableSimflexityPay" &&
          setting.value?.toLowerCase() === "true"
      );
      console.log(found);
      if (found && found !== undefined) {
        setSimflexityPayInfoSent(true);
      } else {
        setSimflexityPayInfoSent(false);
      }
    }
    if (companyState.company && companyState.company.companySettings) {
      const found = companyState.company.companySettings.find(
        (setting) =>
          setting.key === "SimflexityPay" &&
          setting.value?.toLowerCase() === "true"
      );
      if (found && found !== undefined) {
        setSimmflexityPayConnected(true);
      } else {
        setSimmflexityPayConnected(false);
      }
    }
  }, [companyState.company]);

  useEffect(() => {
    const existingCompany = companyState.companies.find(
      (c) => c.id === +companyId
    );
    if (existingCompany) {
      setCompany(existingCompany);
    }
  }, [companyState]);

  const userIsCompanyUserForCompany = (companyId: string) => {
    const existingCompany = companyState.companies.find(
      (c) => c.id === +companyId
    );
    return !!existingCompany;
  };

  const setupSimflexityPay = () => {
    setErrorText("");
    if (company) {
      setButtonLoading(true);
      Api()
        .company.setupSimflexityPayForCompany(company.id)
        .then((res) => {
          if (res.data.error) {
            setErrorText(res.data.error.message);
          } else {
            setConnected(true);
            if (companyState.company?.companySettings) {
              companyDispatch({
                type: "UPDATE_COMPANY_INFORMATION",
                payload: {
                  ...companyState.company,
                  companySettings: [
                    ...companyState.company?.companySettings,
                    {
                      companyId: companyState.company?.id,
                      key: "SimflexityPay",
                      value: "true",
                    },
                  ],
                },
              });
            } else if (companyState.company) {
              companyDispatch({
                type: "UPDATE_COMPANY_INFORMATION",
                payload: {
                  ...companyState.company,
                  companySettings: [
                    {
                      companyId: companyState.company?.id,
                      key: "SimflexityPay",
                      value: "true",
                    },
                  ],
                },
              });
            }
          }
          setButtonLoading(false);
        });
    }
  };

  const renderOops = () => {
    setTimeout(() => {
      return (
        <>
          <H3>{t("General.Oops")}</H3>
          <H4>{t("General.WrongPlace")}</H4>
        </>
      );
    }, 10);
  };

  const renderNotConnected = () => {
    return (
      <div>
        <Text fontFamily={FontFamily.MontserratSemiBold}>{t("SimflexityPay.ThisCompanyIsNotConnected")}</Text>
        <Text fontFamily={FontFamily.MontserratRegular}>{t("SimflexityPay.ContactSimflexityForMore")}</Text>
      </div>
    );
  };

  const renderConnectForm = () => {
    return (
      <>
        <FormTitle>{t("ProfileCompany.CompanyDetails")}</FormTitle>
        <FormRow>
          <LabelStyled>{t("RegisterCompany.LegalName")}</LabelStyled>
          <ReadOnlyStyled>{company?.legalName}</ReadOnlyStyled>
        </FormRow>
        <FormRow>
          <LabelStyled>{t("RegisterCompany.RegistrationNumber")}</LabelStyled>
          <ReadOnlyStyled>{company?.registrationNumber}</ReadOnlyStyled>
        </FormRow>
        <FormRow>
          <LabelStyled>{t("LoginCompany.Email")}</LabelStyled>
          <ReadOnlyStyled>{company?.email}</ReadOnlyStyled>
        </FormRow>
        <FormRow>
          <LabelStyled>{t("RegisterCompany.ContactPerson")}</LabelStyled>
          <ReadOnlyStyled>{company?.contactPerson}</ReadOnlyStyled>
        </FormRow>
        <FormRow>
          <LabelStyled>{t("RegisterCompany.CompanyAddress")}</LabelStyled>
          <ReadOnlyStyled>{company?.address}</ReadOnlyStyled>
        </FormRow>
        <FormRow>
          <LabelStyled>{t("RegisterCompany.PostalCode")}</LabelStyled>
          <ReadOnlyStyled>{company?.postalCode}</ReadOnlyStyled>
        </FormRow>
        <FormRow>
          <LabelStyled>{t("RegisterCompany.City")}</LabelStyled>
          <ReadOnlyStyled>{company?.city}</ReadOnlyStyled>
        </FormRow>
        {!simflexityPayConnected && (
          <>
            <TermsLink onClick={() => setShowTerms(true)}>
              {t("SimflexityPay.Terms")}
            </TermsLink>
            <TermsContainer style={{ display: "flex" }}>
              <Checkbox onChange={(e) => setReadTerms(e.target.checked)} />
              <TermsReadText>{t("SimflexityPay.ReadConditions")}</TermsReadText>
            </TermsContainer>
            <ButtonStyled
              disabled={!readTerms || buttonLoading}
              onClick={setupSimflexityPay}
            >
              {buttonLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                <p>{t("SimflexityPay.ConnectToSimflexity")}</p>
              )}
            </ButtonStyled>
            <p
              style={{
                fontFamily: FontFamily.MontserratRegular,
                color: "red",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              {errorText}
            </p>
          </>
        )}
      </>
    );
  };

  const renderConnected = () => {
    return (
      <>
      <Text fontFamily={FontFamily.MontserratSemiBold}>
        {t("SimflexityPay.Connected")} ✔️
      </Text>
      <Text fontFamily={FontFamily.MontserratRegular}>
          {company?.companyName} {t("SimflexityPay.IsConnectedTo")}
        </Text>
      </>
    );
  };

  return (
    <>
      <H4 fontFamily={FontFamily.LeagueSpartanBold}>
        {t("CompanyUsers.Payments")}
      </H4>

      <GigpayWrapper>
        {!connected &&
          !simflexityPayConnected &&
          userIsCompanyUserForCompany(companyId) &&
          simflexityPayInfoSent &&
          renderConnectForm()}
        {connected ||
          (simflexityPayConnected &&
            userIsCompanyUserForCompany(companyId) &&
            renderConnected())}

        {authState.signedIn && !userIsCompanyUserForCompany(companyId)
          ? renderOops()
          : null}

        {!connected &&
          !simflexityPayConnected &&
          !simflexityPayInfoSent &&
          renderNotConnected()}
      </GigpayWrapper>

      <SimflexityPayTerms setShowTerms={setShowTerms} showTerms={showTerms} />
    </>
  );
};
