import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomLink, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import moment from "moment";
import { TimeReportDto, WorkerWithTimeReports } from "model/TimeReport";
import { CircularProgress } from "@material-ui/core";
import { getWorkHours } from "utils/utils";
import {
  DashBoardCell,
  DashboardCellContent,
  GiggerContainer,
  TimeReportCell,
  TitleContainer,
  TotalHoursWorkedContainer,
  VerticalDivider,
  Badge,
  TimeAndDateContainer,
} from "../dashboard.styled";
import { TimeReportModal } from "../../timeReport/timeReportModal.component";
import { RatingModal } from "../../timeReport/ratingModal.component";
import { EndorseModal } from "../../timeReport/endorsements/endorseModal.component";

export const DashboardTimeReports: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyState] = useCompanyStore();
  const [timeReportState, timeReportActions, timeReportDispatch] =
    useTimeReportStore();
  const [timeReportModalOpen, setTimeReportModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);
  const [currentTimeReport, setCurrentTimeReport] = useState<TimeReportDto>();
  const [unhandledTimeReports, setUnhandledTimeReports] = useState<
    TimeReportDto[] | null
  >(null);

  const getTimeReports = () => {
    timeReportDispatch({
      type: "SET_IS_LOADING",
      payload: true,
    });
    const companyId = companyState.company?.id;

    if (companyId) {
      Api()
        .company.timeReport.getUnhandledTimeReports(companyId)
        .then((res) => {
          timeReportDispatch({
            type: "SET_IS_LOADING",
            payload: false,
          });
          timeReportDispatch({
            type: "UPDATE_UNHANDLED_TIME_REPORTS",
            payload: res.data,
          });
          timeReportActions.getUnhandledTimeReportCount(companyId);
        })
        .catch((err) => {
          console.log("Error fetching unhandled timereports", err);
          timeReportDispatch({
            type: "SET_IS_LOADING",
            payload: false,
          });
        });
    }
  };

  useEffect(() => {
    createTimeReportList(timeReportState.unhandledTimeReports);
  }, [JSON.stringify(timeReportState.unhandledTimeReports)]);

  const createTimeReportList = (unhandled: WorkerWithTimeReports[]) => {
    const tr: TimeReportDto[] = [];

    unhandled.forEach((unhandledWorker: WorkerWithTimeReports) => {
      unhandledWorker.timeReports.forEach((workerTimeReport) => {
        tr.push(workerTimeReport);
      });
    });
    tr.splice(4);
    setUnhandledTimeReports(tr);
  };

  useEffect(() => {
    if (unhandledTimeReports) {
      timeReportDispatch({
        type: "SET_IS_LOADING",
        payload: false,
      });
    }
  }, [unhandledTimeReports]);

  useEffect(() => {
    getTimeReports();
  }, [companyState.company?.id]);

  return (
    <>
      <DashBoardCell>
        <TitleContainer>
          <CustomLink
            color={Color.MidnightBlue}
            onClick={() => history.push("./time-reports")}
            fontSize={FontSize.H3}
            fontFamily={FontFamily.MontserratRegular}
          >
            {t("CompanyTimeReport.TimeReports")}
          </CustomLink>
          {unhandledTimeReports && unhandledTimeReports.length > 4 && (
            <Badge>
              <CustomLink
                onClick={() => history.push("./time-reports")}
                color={Color.White}
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Small}
              >
                +{unhandledTimeReports.length - 4}
              </CustomLink>
            </Badge>
          )}
        </TitleContainer>
        <DashboardCellContent>
          {!timeReportState.isLoading &&
            unhandledTimeReports &&
            unhandledTimeReports.map((timeReport) => {
                return (
                  <TimeReportCell
                    onClick={() => {
                      setCurrentTimeReport(timeReport);
                      setTimeReportModalOpen(true);
                    }}
                  >
                    <TimeAndDateContainer>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratSemiBold}
                        fontSize={FontSize.H4}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {`${moment(timeReport.startTime).format("DD/MM")}`}
                      </Text>
                      <Text color={Color.SeaBlue600}>
                        {`${moment(timeReport.startTime).format(
                          "HH:mm"
                        )} - ${moment(timeReport.endTime).format(" HH:mm")}`}
                      </Text>
                    </TimeAndDateContainer>
                    <VerticalDivider />
                    <GiggerContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("General.Giggers")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >{`${timeReport.firstName} ${timeReport.lastName}`}</Text>
                    </GiggerContainer>
                    <TotalHoursWorkedContainer>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >{`${t("General.TotalTime")}`}</Text>
                      <Text>{`${getWorkHours(t, ...[timeReport])}`}</Text>
                    </TotalHoursWorkedContainer>
                  </TimeReportCell>
                );
            })}
          {!timeReportState.isLoading &&
            unhandledTimeReports !== null &&
            timeReportState.unhandledTimeReports.length === 0 && (
              <Text color={Color.SeaBlue400}>
                {t("CompanyTimeReport.NoUnhandledTimeReports")}
              </Text>
            )}
          {(timeReportState.isLoading || unhandledTimeReports == null) && (
            <CircularProgress />
          )}
        </DashboardCellContent>
      </DashBoardCell>


      {currentTimeReport && (
        <>
          <TimeReportModal
            chosenTimeReportWithWorker={currentTimeReport}
            modalOpen={timeReportModalOpen}
            setModalOpen={setTimeReportModalOpen}
            setRatingModalOpen={setRatingModalOpen}
          />
          {ratingModalOpen && (
            <RatingModal
              ratingModalOpen={ratingModalOpen}
              setRatingModalOpen={setRatingModalOpen}
              chosenTimeReportWithWorker={currentTimeReport}
              setEndorseModalOpen={setEndorseModalOpen}
            />
          )}
          {endorseModalOpen && (
            <EndorseModal
              endorseModalOpen={endorseModalOpen}
              setEndorseModalOpen={setEndorseModalOpen}
              chosenTimeReportWithWorker={currentTimeReport}
            />
          )}
        </>
      )}
    </>
  );
};
